<template>
    <div>
        <v-overlay :model-value="loading"
                   class="align-center justify-center">
            <v-progress-circular color="primary"
                                 indeterminate
                                 size="64"></v-progress-circular>
        </v-overlay>
        <div class="smart-controls d-none d-sm-flex">
            <div class="date-controls">
                <label><v-icon icon="mdi-gamepad-square"></v-icon></label>
                <v-text-field label="Start Date"
                              type="date"
                              density="compact"
                              @change="saveDates"
                              :hide-details="true"
                              v-model="startDate"></v-text-field>
                <v-text-field label="End Date"
                              type="date"
                              density="compact"
                              @change="saveDates"
                              :hide-details="true"
                              v-model="endDate"></v-text-field>
                <v-btn color="primary" v-if="yesterdayShowing" variant="outlined" class="refresh-btn" @click="hideYesterday">
                    Hide Old
                </v-btn>
            </div>
            <div class="job-search">
                <v-text-field label="Search cards..."
                              prepend-icon="mdi-magnify"
                              v-model="searchTerm"
                              density="compact"
                              :hide-details="true"
                              clearable></v-text-field>
            </div>
            <v-btn-toggle variant="outlined"
                          divided>
                <v-btn variant="outlined"
                       prepend-icon="mdi-printer"
                       class="refresh-btn"
                       to="/dashboard/dayprogram"
                       target="_blank">
                    Program
                </v-btn>
                <v-btn variant="outlined"
                       prepend-icon="mdi-printer"
                       class="refresh-btn"
                       to="/dashboard/powderreport"
                       target="_blank">
                    Powder
                </v-btn>
            </v-btn-toggle>
        </div>
        <div class="smart-cont">
            <div class="smart-area">
                <div v-for="column in filteredColumns" :key="column.name" class="smart-column" :class="{'expand-column' : column.expand, 'shrink-column' : !column.expand }">
                    <div class="column-header">
                        {{ column.name }}  <v-badge :color="column.jobs.length > 0 ? 'info' : 'secondary'" :content="column.jobs.length" inline></v-badge>
                        <v-tooltip :text="column.expand ? 'Hide Column' :'Show Column'">
                            <template v-slot:activator="{ props }">
                                <v-btn v-bind="props"
                                       class="expand-btn"
                                       variant="plain"
                                       @click="updateColumnExpand(column)"
                                       :icon="column.expand ? 'mdi-arrow-expand-up' : 'mdi-arrow-expand-left'"
                                       size="x-small"></v-btn>
                            </template>
                        </v-tooltip>
                    </div>
                    <div :class="{'d-none':!column.expand, 'd-inline-block':column.expand}" class="w-100 h-100">
                        <div class="totals-block">
                            <div class="text-right">
                                <span class="total-label">TOTAL</span> ${{ numberWithCommas(columnTotal(column), 2) }}
                            </div>
                        </div>
                        <draggable class="card-list"
                                   :list="column.jobs"
                                   group="jobs"
                                   draggable=".moveable-card"
                                   @change="updateCard(column, $event)"
                                   itemKey="id">
                            <template #item="{ element, index }">
                                <v-card class="job-card" :id="'card-'+index" :class="{'moveable-card':!element.archived, 'locked-job-card':element.archived}">
                                    <div v-if="element.archived" class="locked-card">
                                        <v-icon icon="mdi-lock" class="mr-1" size="x-small"></v-icon> LOCKED
                                    </div>
                                    <v-card-title>
                                        <span v-html="formatSearchName(element.jobName, searchTerm)"></span>
                                    </v-card-title>
                                    <v-card-subtitle>
                                        <contact-link :contact="element.contact" :search-term="searchTerm"></contact-link>
                                    </v-card-subtitle>
                                    <v-card-text>
                                        <div v-if="element.customerNumber">
                                            <small class="text-grey-darken-2"><v-icon icon="mdi-pound-box-outline"></v-icon></small> <span v-html="formatSearchName(element.customerNumber, searchTerm)"></span>
                                        </div>
                                        <div v-if="element.dateTargetCompletion">
                                            <small class="text-grey-darken-2"><v-icon icon="mdi-bullseye"></v-icon></small> {{ $dayjs(element.dateTargetCompletion).format("DD/MM") }}
                                        </div>
                                        <div v-if="element.colours">
                                            <small class="text-grey-darken-2"><v-icon icon="mdi-palette"></v-icon></small> <span v-html="formatSearchName(element.colours, searchTerm)"></span>
                                        </div>
                                        <div class="d-flex justify-space-between">
                                            <div>
                                                <div><small class="text-grey-darken-2"><v-icon icon="mdi-invoice-text-outline"></v-icon></small> ${{ numberWithCommas(element.taskSubTotal, 2) }}</div>
                                            </div>
                                            <v-tooltip text="View Job" class="float-right">
                                                <template v-slot:activator="{ props }">
                                                    <v-btn v-bind="props" icon="mdi-briefcase-eye" variant="plain" size="x-small" @click="showJobModal(element)"></v-btn>
                                                </template>
                                            </v-tooltip>
                                        </div>
                                    </v-card-text>
                                    <div v-if="element.datePretreatment && element.jobStatus < jobConstants.JOBSTAT_INVOICE" class="pretreated-card">
                                        <v-icon icon="mdi-progress-check" size="x-small"></v-icon> PRETREATED
                                    </div>
                                    <div v-if="element.jobTag == jobConstants.TAG_REWORK" class="rework-card">
                                        <v-icon icon="mdi-recycle" size="x-small"></v-icon> REWORK
                                    </div>
                                    <div v-if="element.jobTag == jobConstants.TAG_BATCH" class="batch-card">
                                        <v-icon icon="mdi-toaster-oven" size="x-small"></v-icon> BATCH OVEN
                                    </div>
                                    <div v-if="element.jobTag == jobConstants.TAG_HIGH && element.jobStatus < jobConstants.JOBSTAT_INVOICE" class="priority-card">
                                        <v-icon icon="mdi-flag" size="x-small"></v-icon> HIGH PRIORITY
                                    </div>
                                    <div v-if="element.jobStatus == jobConstants.JOBSTAT_COMP" class="invoice-card">
                                        <v-icon icon="mdi-progress-check" size="x-small"></v-icon> INVOICED
                                    </div>
                                    <div v-if="element.jobStatus == jobConstants.JOBSTAT_INVOICE" class="completed-card">
                                        <v-icon icon="mdi-progress-check" size="x-small"></v-icon> COMPLETED
                                    </div>
                                </v-card>
                            </template>
                        </draggable>
                    </div>
                </div>
            </div>
        </div>
        <v-dialog v-model="showJob"
                  scrollable
                  transition="dialog-bottom-transition"
                  width="50%"
                  height="60%">
            <v-card v-if="job">
                <v-toolbar theme="dark">
                    <v-toolbar-title>{{ job.jobName }} <span v-if="job.customerNumber"> - {{ job.customerNumber }}</span></v-toolbar-title>
                    <v-btn icon="mdi-close"
                           @click="showJob = false"></v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <table class="detail-table w-100">
                                <tr v-if="job.contact">
                                    <td>
                                        <contact-link :contact="job.contact"></contact-link>
                                    </td>
                                </tr>
                                <tr v-if="job.customerNumber">
                                    <td>
                                        <small class="text-grey-darken-2"><v-icon icon="mdi-pound-box-outline"></v-icon> Customer#</small><br />{{ job.customerNumber }}
                                    </td>
                                </tr>
                                <tr v-if="job.dateReceived">
                                    <td>
                                        <small class="text-grey-darken-2"><v-icon icon="mdi-calendar-range"></v-icon> Receive Date</small><br /><date-ago-display :date="job.dateReceived" :days-ago="job.daysSinceReceived" :countdown="false"></date-ago-display>
                                    </td>
                                </tr>
                            </table>
                            <table v-if="job.jobColours" class="w-100 detail-table text-caption">
                                <tr>
                                    <th class="text-left">Colour</th>
                                    <th class="text-right">Coating</th>
                                    <th class="text-right">Powder</th>
                                </tr>
                                <tr v-for="colour in job.jobColours" :key="colour.colour">
                                    <td class="text-left">{{ colour.name }}</td>
                                    <td class="text-right">{{ numberWithCommas(colour.estimateCoatingTime, 0) }} mins</td>
                                    <td class="text-right">{{ numberWithCommas(colour.powderRequired, 0) }} Kgs</td>
                                </tr>
                            </table>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Target Completion Date"
                                          type="date"
                                          density="compact"
                                          :hide-details="true"
                                          @change="updateJob('dateTargetCompletion', job.targetDate)"
                                          v-model="job.targetDate"></v-text-field>
                            <v-checkbox :model-value="job.datePretreatment != null"
                                        label="Pretreatment Complete?"
                                        :hide-details="true"
                                        @change="updatePretreated"
                                        density="compact"></v-checkbox>
                            <v-select v-model="job.jobTag"
                                      label="Job Tag"
                                      density="compact"
                                      :clearable="true"
                                      @update:modelValue="updateJob('jobTag', job.jobTag)"
                                      :items="jobTags">
                            </v-select>
                            <table class="detail-table w-100">
                                <tr>
                                    <td>
                                        <type-chip :type="job.jobStatus" entity="Job" :object="job"></type-chip>
                                    </td>
                                </tr>
                                <tr v-if="job.outstandingSubtotal != job.jobSubTotal && job.outstandingSubtotal != 0">
                                    <td>
                                        <small class="text-grey-darken-2"><v-icon icon="mdi-filter-variant"></v-icon> Oustanding</small><br />
                                        ${{ numberWithCommas(job.outstandingSubtotal, 2) }}
                                        <v-chip v-if="remainingPercentage"
                                                :prepend-icon="remainingPercentage > 0 ? 'mdi-list-status' : 'mdi-check-circle'"
                                                size="small"
                                                :color="remainingPercentage > 0 ? 'warning' : 'success'">{{ numberWithCommas(remainingPercentage, 0) }}%</v-chip>
                                    </td>
                                </tr>
                            </table>
                            <table v-if="job.archivedJobTasks && job.archivedJobTasks.length > 0" class="w-100 detail-table text-caption">
                                <tr>
                                    <th class="w-30 text-left">Task</th>
                                    <th class="w-40 text-right">Done</th>
                                    <th class="w-30 text-right">Done$</th>
                                </tr>
                                <tr v-for="archivedTask in job.archivedJobTasks" :key="archivedTask.id">
                                    <td>{{ archivedTask.name }}</td>
                                    <td class="text-right"><span v-if="archivedTask.targetStartDate">{{ $dayjs(archivedTask.targetStartDate).format('DD-MM-YYYY') }}</span></td>
                                    <td class="text-right"><span v-if="archivedTask.estimatedSubtotalCompleted">${{ numberWithCommas(archivedTask.estimatedSubtotalCompleted, 2) }}</span></td>
                                </tr>
                            </table>
                            <table class="detail-table w-100">
                                <tr>
                                    <td class="text-right">
                                        <h2 v-if="job.jobSubTotal != job.taskSubTotal" class="job-total-block">
                                            <small class="text-grey-darken-2 float-left">Completed</small>
                                            ${{ numberWithCommas(job.taskSubTotal, 2) }}
                                        </h2>
                                        <h2>
                                            <small class="text-grey-darken-2 float-left">Job Total</small>
                                            ${{ numberWithCommas(job.jobSubTotal, 2) }}
                                        </h2>
                                    </td>
                                </tr>
                            </table>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn :to="'/'+$entities.Job.ROUTE+job.jobId"
                           @click.stop
                           prepend-icon="mdi-arrow-bottom-right-thin-circle-outline"
                           variant="flat"
                           color="primary">
                        Go to Job
                    </v-btn>
                    <div>
                        <v-btn @click="openCreateRework"
                               class="ml-1"
                               prepend-icon="mdi-recycle"
                               variant="flat"
                               color="red-darken-1">Create Rework</v-btn>
                        <v-btn @click="openMarkComplete"
                               class="ml-1"
                               v-if="job.jobStatus < jobConstants.JOBSTAT_INVOICE && !job.archived"
                               prepend-icon="mdi-check-circle"
                               variant="flat"
                               color="success">Mark Complete</v-btn>
                        <v-btn v-if="job.jobStatus >= jobConstants.JOBSTAT_INVOICE"
                               prepend-icon="mdi-check-circle"
                               class="ml-1"
                               color="secondary"
                               disabled>Job Completed</v-btn>
                        <v-btn v-if="job.jobStatus == jobConstants.JOBSTAT_INVOICE"
                               prepend-icon="mdi-invoice-text-outline"
                               class="ml-1"
                               variant="flat"
                               :loading="savingCompleted"
                               color="info"
                               @click="updateJobStatus(job, jobConstants.JOBSTAT_COMP)">Mark Invoiced</v-btn>
                        <v-btn v-if="job.jobStatus == jobConstants.JOBSTAT_COMP"
                               prepend-icon="mdi-check-circle"
                               class="ml-1"
                               color="secondary"
                               disabled>Job Invoiced</v-btn>
                        <v-btn @click="showJob = false" variant="tonal">Close</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="confirmComplete"
                  width="40%"
                  height="80%">
            <v-card>
                <v-card-text>
                    Are you sure this job has been completed?
                    <v-checkbox v-model="partiallyCompleted"
                                label="Job was only partially completed today"
                                :hide-details="true"
                                density="compact"></v-checkbox>
                    <div v-if="partiallyCompleted">
                        <hr class="my-2" />
                        <p class="mb-2">If job has only been partially completed please indicate below the percentage of the job that has been completed and the date you will be rescheduling the remaining for.</p>
                        <v-text-field label="Reschedule Date"
                                      type="date"
                                      :hide-details="true"
                                      v-model="rescheduleDate"></v-text-field>
                        <v-text-field label="Percentage of job completed"
                                      v-model.number="percentageCompleted"
                                      type="number"
                                      suffix="%"></v-text-field>
                        <div>
                            Job currently has an outstanding amount of ${{ numberWithCommas(job.taskSubTotal, 2) }}.
                        </div>
                        <div v-if="percentageCompleted">
                            <h2><small>Estimated completed amount </small> ${{ numberWithCommas(estimatedCompleteAmount, 2) }}</h2>
                        </div>
                    </div>
                    <v-alert icon="mdi-alert-decagram-outline"
                             class="my-2"
                             v-if="completeErrors.length > 0"
                             type="warning">
                        <ul class="small-text">
                            <li :key="error" v-for="error in completeErrors">{{ error }}</li>
                        </ul>
                    </v-alert>
                </v-card-text>
                <v-card-actions>
                    <v-btn dark @click="cancelMarkCompleted" :disabled="savingCompleted">
                        CANCEL
                    </v-btn>
                    <v-btn @click="saveCompleted"
                           :loading="savingCompleted"
                           :disabled="completeErrors.length > 0"
                           prepend-icon="mdi-check-circle"
                           variant="flat"
                           color="success">
                        SAVE COMPLETED
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="confirmRework"
                  width="40%"
                  height="80%">
            <v-card>
                <v-card-text>
                    Select the items below that required rework.
                    <hr class="my-2" />
                    <p>What is the next step for resolution?</p>
                    <v-radio-group v-model="reworkPrework">
                        <v-radio label="Reworking Today" value=""></v-radio>
                        <v-radio label="Reschedule" value="Reschedule"></v-radio>
                        <v-radio label="Stripping" value="Stripping"></v-radio>
                        <v-radio label="Blasting" value="Blasting"></v-radio>
                    </v-radio-group>
                    <div v-if="reworkPrework == 'Reschedule'">
                        <v-text-field label="Reschedule Date"
                                      type="date"
                                      :hide-details="true"
                                      v-model="rescheduleDate"></v-text-field>
                    </div>
                    <p>What is the reason for the rework?</p>
                    <v-chip-group selected-class="text-primary"
                                  v-model="selectedReasons"
                                  multiple>
                        <v-chip v-for="tag in reasonChips"
                                size="x-small"
                                :prepend-icon="tag.icon"
                                :key="tag.reason">
                            {{ tag.reason }}
                        </v-chip>
                    </v-chip-group>
                    <v-textarea label="Additional Information" v-model="reworkAddReason"></v-textarea>
                    <v-alert icon="mdi-alert-decagram-outline"
                             class="my-2"
                             v-if="reworkErrors.length > 0"
                             type="warning">
                        <ul class="small-text">
                            <li :key="error" v-for="error in reworkErrors">{{ error }}</li>
                        </ul>
                    </v-alert>
                </v-card-text>
                <v-card-actions>
                    <v-btn dark @click="cancelCreateRework" :disabled="savingRework">
                        CANCEL
                    </v-btn>
                    <v-btn @click="saveRework"
                           :loading="savingRework"
                           :disabled="reworkErrors.length > 0"
                           prepend-icon="mdi-check-circle"
                           variant="flat"
                           color="success">
                        SAVE REWORK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="refreshRequired"
                    color="blue-darken-3"
                    :timeout="-1"
                    location="bottom"
                    elevation="24"
                    vertical>
            <div class="text-subtitle-1 pb-2">Changes have been detected</div>

            <p>Please refresh the data to view changes.</p>

            <template v-slot:actions>
                <v-btn color="blue-lighten-5"
                       variant="tonal"
                       prepend-icon="mdi-refresh"
                       @click="refreshData">
                    Refresh Jobs
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
<script>
    /*
    Blue - Pretreatment Completed
    Red - Rework
    */
    import { mapActions, mapState } from "vuex";
    import { numberWithCommas } from "../../mixins/numberMixins";
    import { getTagClass } from "../../mixins/jobMixins";
    import { formatSearchName } from "../../mixins/searchMixins";
    import jobs from "../../constants/jobs";
    import draggable from 'vuedraggable'
    import Cookies from 'js-cookie'
    const signalR = require("@microsoft/signalr");
    function initialState() {
        return {
            loading: false,
            searchTerm: '',
            startDate : null,
            endDate: null,
            processStartDate: null,
            processEndDate: null,
            columns: [],
            showJob: false,
            job: null,
            jobLoading: false,
            confirmComplete: false,
            partiallyCompleted: false,
            rescheduleDate: null,
            percentageCompleted: null,
            savingCompleted:false,
            dateTargetCompletion: null,
            refreshRequired: false,
            confirmRework: false,
            savingRework: false,
            reworkPrework: '',
            selectedReasons: [],
            reworkAddReason: '',
            reasonChips: [
                { icon: 'mdi-hanger', reason: 'Fell in oven' },
                { icon: 'mdi-hanger', reason: 'Not hung correctly for faces to coat' },
                { icon: 'mdi-hanger', reason: 'Touched in oven' },
                { icon: 'mdi-hanger', reason: 'Product dirty' },
                { icon: 'mdi-spray', reason: 'Too much powder' },
                { icon: 'mdi-spray', reason: 'Not enough powder' },
                { icon: 'mdi-spray', reason: 'Wrong colour' },
                { icon: 'mdi-spray', reason: 'Powder colour cross contamination' },
                { icon: 'mdi-archive-outline', reason: 'Damaged during packing' }
            ]
        };
    }
    export default {
        name: "SmartDashboard",
        components: {
            draggable,
        },
        data: function () {
            return initialState();
        },
        mounted: function () {
            var cookieStart = Cookies.get('smart-start-date');
            var cookieEnd = Cookies.get('smart-end-date');
            if (cookieStart && cookieEnd) {
                this.startDate = cookieStart;
                this.endDate = cookieEnd;
            } else {
                let today = this.$dayjs();
                this.startDate = (today.day() === 1
                    ? this.$dayjs().subtract(3, 'day')  // Subtract 3 days for Monday -> Friday
                    : this.$dayjs().subtract(1, 'day')).format('YYYY-MM-DD'); // Subtract 1 day for other days
                const nextSunday = today.day(7); // day(0) also works, but day(7) ensures it's always Sunday
                // Add 7 days to move to the Sunday of the following week
                this.endDate = nextSunday.add(7, 'day').format('YYYY-MM-DD');
            }
            this.refreshData();
            this.connectRefresh();
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack",
            ]),
            numberWithCommas,
            getTagClass,
            formatSearchName,
            connectRefresh() {
                if (this.user) {
                    const options = {
                        accessTokenFactory: () => {
                            return `${this.user.token}`;
                        },
                    };
                    let connection = new signalR.HubConnectionBuilder()
                        .withUrl(process.env.VUE_APP_API_URL + "hubs/smart", options)
                        .build();
                    connection.start().catch(error => { });
                    connection.on("RefreshRequired", data => {
                        this.refreshRequired = this.user.email != data;
                    });
                    connection.on('finished', (update) => {
                        connection.stop();
                    });
                }
            },
            saveCompleted() {
                this.savingCompleted = true;
                let formData = new FormData();
                formData.append('id', this.job.jobId);
                formData.append('taskId', this.job.jobTaskId);
                formData.append('partiallyCompleted', this.partiallyCompleted)
                if (this.partiallyCompleted) {
                    formData.append('rescheduleDate', this.rescheduleDate);
                    formData.append('estimatedCompleteAmount', this.estimatedCompleteAmount);
                }
                formData.append('startDate', this.startDate);
                formData.append('endDate', this.endDate);
                this.$api.post(this.$entities.Job.ROUTE + 'completejob', formData)
                    .then(res => {
                        this.loadColumnData(res.data);
                        this.confirmComplete = false;
                        this.showJob = false;
                    })
                    .catch(error => {
                        this.showErrorSnack('There was an issue completing the job');
                    });
            },
            openCreateRework() {
                this.cancelCreateRework();
                 let currentDate = this.$dayjs().add(1, 'day');
                 this.rescheduleDate = (currentDate.day() === 6
                     ? currentDate.add(2, 'day')  // Subtract 3 days for Monday -> Friday
                     : currentDate.day() === 0 ? currentDate.add(1, 'day') : currentDate).format('YYYY-MM-DD');
                this.confirmRework = true;
            },
            cancelCreateRework() {
                this.reworkPrework = '';
                this.rescheduleDate = null;
                this.selectedReasons = [];
                this.confirmRework = false;
                this.reworkAddReason = '';
            },
            openMarkComplete() {
                this.cancelMarkCompleted();
                let currentDate = this.$dayjs(this.job.taskDate).add(1, 'day');
                this.rescheduleDate = (currentDate.day() === 6
                    ? currentDate.add(2, 'day')  // Subtract 3 days for Monday -> Friday
                    : currentDate.day() === 0 ? currentDate.add(1, 'day') : currentDate).format('YYYY-MM-DD');
                this.confirmComplete = true;
            },
            cancelMarkCompleted() {
                this.partiallyCompleted = false;
                this.confirmComplete = false;
                this.rescheduleDate = null;
                this.percentageCompleted = null;
            },
            hideYesterday() {
                this.startDate = this.$dayjs().format('YYYY-MM-DD');
                this.saveDates();
            },
            updatePretreated() {
                this.updateJob('datePretreatment', !this.job.datePretreatment ? this.$dayjs().format('YYYY-MM-DD') : null);
            },
            updateJob(path, value) {
                this.$api.patch(this.$entities.Job.ROUTE + this.job.jobId, [{
                    op: "replace",
                    path: "/" + path,
                    value: value
                }]).then(res => {
                    this.job[path] = value;
                    this.showSuccessSnack("Job updated!");
                    return;
                }).catch(error => {
                    this.showErrorSnack("We ran into an issue...");
                });
            },
            updateJobStatus(job, status, taskId, order, date) {
                let formData = new FormData();
                this.savingCompleted = true;
                formData.append('id', job.jobId);
                formData.append('status', status);
                if (taskId) {
                    formData.append('taskId', taskId);
                    formData.append('order', order);
                    if (date !== null) {
                        formData.append('date', date);
                    }       
                }
                formData.append('startDate', this.startDate);
                formData.append('endDate', this.endDate);
                this.$api.post(this.$entities.Job.ROUTE + 'updatestatus', formData)
                    .then(res => {
                        this.savingCompleted = false;
                        this.loadColumnData(res.data);
                        this.confirmComplete = false;
                        this.showJob = false;
                    })
                    .catch(error => {
                        this.savingCompleted = false;
                        this.showErrorSnack('There was an issue updating the status');
                    });
            },
            saveDates() {
                if (this.startDate) {
                    Cookies.set('smart-start-date', this.startDate, { expires: 0.4 });
                }
                if (this.endDate) {
                    Cookies.set('smart-end-date', this.endDate, { expires: 0.4 });
                }
                this.refreshData();
            },
            showJobModal(job) {
                this.showJob = true;
                this.job = job;
            },
            loadColumnData(columns) {
                this.columns = columns;
                this.loadColumnCookies();
                this.processStartDate = this.startDate;
                this.processEndDate = this.endDate;
                this.loading = false;
                this.savingCompleted = false;
            },
            refreshData() {
                this.loading = true;
                this.$api.get('/dashboard/smartjobs?startDate='+this.startDate+"&endDate="+this.endDate)
                    .then(res => {
                        this.refreshRequired = false;
                        this.loadColumnData(res.data);
                    }).catch(error => {
                        this.loading = false;
                        this.showErrorSnack("There was an issue getting the jobs.");
                    });
            },
            loadColumnCookies() {
                this.columns.forEach(column => {
                    let cookieExpand = Cookies.get('smart-column-expand-'+column.status);
                    if (cookieExpand){
                        column.expand = cookieExpand == 'true';
                    }
                });
            },
            updateColumnExpand(column){
                column.expand = !column.expand;
                if (!column.statusDate) {
                    Cookies.set('smart-column-expand-'+column.status, column.expand, { expires: 7 });
                }
            },
            columnTotal(column) {
                if (column.jobs.length > 0){
                    return column.jobs.reduce((acc, obj) => acc + obj.taskSubTotal, 0);
                }
                return 0;
            },
            updateCard(column, event) {
                let newIndex = null; 
                let job = null;
                if (event.moved && event.moved.element) {
                    newIndex = event.moved.newIndex;
                    job = event.moved.element;
                }
                if (event.added && event.added.element) {
                    newIndex = event.added.newIndex;
                    job = event.added.element;
                }
                if (newIndex != null && job != null) {
                    this.updateJobStatus(job, column.status, job.jobTaskId, newIndex, column.statusDate);
                }
            }
        },
        computed: {
            ...mapState({
                user: (state) => state.auth.user
            }),
            estimatedCompleteAmount() {
                if (this.job && this.percentageCompleted && !isNaN(parseFloat(this.percentageCompleted))) {
                    return this.job.taskSubTotal * (parseFloat(this.percentageCompleted) / 100)
                }
                return null;
            },
            completeErrors() {
                let errors = [];
                if (this.partiallyCompleted) {
                    // Must have percentage and reschedule date
                    if (!this.rescheduleDate || this.rescheduleDate.length == 0) {
                        errors.push('Must have reschedule date');
                    }
                    if (!this.percentageCompleted || this.percentageCompleted.length == 0) {
                        errors.push('Must have a percentage completed to continue');
                    }
                    if (this.estimatedCompleteAmount && this.job && this.estimatedCompleteAmount >= this.job.taskSubTotal) {
                        errors.push('Untick partially completed if you have completed the whole job')
                    }
                }
                return errors;
            },
            reworkErrors() {
                let errors = [];
                return errors;
            },
            dirtyDates() {
                return this.startDate != this.processStartDate || this.endDate !=  this.processEndDate;
            },
            filteredColumns() {
                if (this.searchTerm) {
                    // Filter the columns
                    let upSearchTerm = this.searchTerm.toUpperCase();
                    return this.columns.map(column => ({
                        ...column, // Keep the column object unchanged
                        jobs: column.jobs.filter(job =>
                            job.jobName.toUpperCase().includes(upSearchTerm) ||
                            (job.customerNumberFull && job.customerNumberFull.toUpperCase().includes(upSearchTerm)) || 
                            (job.colours && job.colours.toUpperCase().includes(upSearchTerm)) || 
                            (job.contact && job.contact.name.toUpperCase().includes(upSearchTerm)))
                    }));
                } else {
                    return this.columns;
                }
            },
            yesterdayShowing() {
                const today = this.$dayjs();
                return this.$dayjs(this.startDate).date() == today.subtract(1, 'day').date();
            },
            jobConstants() {
                return jobs;
            },
            jobTags() {
                return this.jobConstants.tags.map(item => item.name);
            },
            remainingPercentage() {
                if (this.job) {
                    return (this.job.outstandingSubtotal / this.job.jobSubTotal) * 100;
                }
                return null;
            }
        },
        watch: {
        }
    };
</script>
<style scoped>
    .job-total-block {
        border-bottom:2px solid black;
        margin-bottom:5px;
        padding-bottom:5px;
    }
    .detail-table {
        border-collapse: collapse;
    }
    .detail-table > tr > td,th {
        border: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
        padding:5px;
    }
    .card-list {
        width: 100%;
        min-height: 85%;
        padding: 5px;
    }

    .job-card {
        margin-bottom: 10px;
    }
    .moveable-card {
        cursor: grab;
    }
    .locked-job-card {
        background-color: #EEEEEE;
        cursor: not-allowed;
    }
    .locked-card {
        background-color: grey;
        color: white;
        text-align: center;
        font-size: 0.8rem;
    }

    .priority-card {
        background-color: #FF7043;
        color: white;
        text-align: center;
        font-size: 0.8rem;
    }
    .batch-card {
        background-color: #FB8C00;
        color: white;
        text-align: center;
        font-size: 0.8rem;
    }
    .rework-card {
        background-color: #E53935;
        color: white;
        text-align: center;
        font-size: 0.8rem;
    }
    .invoice-card {
        background-color: #26A69A;
        color: white;
        text-align: center;
        font-size: 0.8rem;
    }
    .completed-card {
        background-color: #66BB6A;
        color: white;
        text-align: center;
        font-size: 0.8rem;
    }
    .pretreated-card {
        background-color: cornflowerblue;
        color: white;
        text-align: center;
        font-size: 0.8rem;
    }

    .job-card .v-card-title {
        padding-bottom: 0px;
        line-height: 1rem;
        font-size: 1rem;
        font-weight: bold;
    }

    .job-card .v-card-text {
        padding-top: 5px;
    }

    .refresh-btn {
        margin-top: 9px;
        margin-left: 5px;
    }

    .column-area {
        display: inline-flex;
    }

    .totals-block {
        padding: 5px;
        border-top: 1px solid #dee2e6;
        background-color: #323232;
        color: white;
        position: sticky;
        top: 53px;
        z-index: 2;
    }

    .total-label {
        color: lightgrey;
        font-style: italic;
        font-size: 80%;
    }

    .smart-controls {
        background-color: white;
        margin-top: 5px;
        width: 100%;
        padding: 5px;
        color: #3b3b3b;
        border-bottom: 1px solid #dee2e6;
        justify-content: space-between !important;
    }

    .smart-column {
        display: table-cell;
        vertical-align: top;
        box-sizing: border-box;
        border-left: 1px solid #dee2e6;
        border-right: 1px solid #dee2e6;
    }

    .smart-column:hover .expand-btn {
        display: inline;
    }

    .expand-column {
        max-width: 220px;
        width: 220px;
    }

    .shrink-column {
        max-width: 50px;
        width: 50px;
        position: relative;
    }

    .shrink-column .expand-btn {
        display: inline-block;
    }

    .column-header {
        padding: 10px;
        font-weight: bold;
        position: sticky;
        top: 0;
    }

    .shrink-column .column-header {
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    .expand-column .column-header {
        background-color: #f5f6f8;
        z-index: 2;
        border-bottom: 1px solid #dee2e6;
    }

    .date-controls {
        width: 40%;
        display: flex;
    }

    .date-controls .v-input {
        margin-left: 5px;
    }

    .job-search {
        width: 25%;
    }

    .date-controls label {
        font-size: 15px;
        text-align: center;
        padding-top: 19px;
        color: dimgrey;
    }

    .smart-area {
        background-color: #f5f7fa;
        width: 100%;
        white-space: nowrap;
        display: table;
        height: max-content;
        min-height: 100%;
    }

    .smart-cont {
        overflow-x: auto;
        overflow-y: auto;
        height: 85vh;
        background-color: #f5f7fa;
    }
</style>