export function numberWithCommas(string, decimalPlaces = null) {
    if (string != null && (string || Number(string) === 0)) {
        if (decimalPlaces > 0) {
            string = Number(string).toFixed(decimalPlaces);
        } else {
            string = Math.round(string).toString();
        }
        return string.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}
export function roundAway2dp(value) {
    // Multiply by 100 to move the decimal point two places to the right
    value *= 100;

    // If the value is positive, use Math.floor for AwayFromZero rounding
    // If the value is negative, use Math.ceil for AwayFromZero rounding
    let roundedValue = value >= 0 ? Math.floor(value + 0.5) : Math.ceil(value - 0.5);

    // Divide by 100 to move the decimal point back to the original position
    return roundedValue / 100;
}
