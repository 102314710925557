export function formatSearchName(displayName, searchTerm) {
    if (searchTerm && searchTerm.length > 0 && displayName && displayName.length > 0) {
        var indexOf = displayName.toLowerCase().indexOf(searchTerm.toLowerCase());
        // Found
        if (indexOf !== -1) {
            var searchText = displayName.substring(indexOf, indexOf + searchTerm.length);
            return displayName.replace(searchText, "<span class='highlight-text'>" + searchText + "</span>");
        }
    }
    return displayName;
}