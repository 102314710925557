<template>
    <div class="pt-2">
        <div class="v-progress-linear v-progress-linear--active v-theme--light v-locale--is-ltr" role="progressbar" aria-hidden="false" aria-valuemin="0" aria-valuemax="100" aria-valuenow="20" style="top: 0px; height: 25px; --v-progress-linear-height: 25px; left: 50%; transform: translateX(-50%);">
            <div class="v-progress-linear__background" :class="[ bgClass ]" style="width: 100%;"></div>
            <div class="v-progress-linear__determinate" :class="[ bgClass ]" :style="{ 'width' : value+'%' }"></div>
            <div v-if="target && target > value" class="v-progress-linear__determinate v-progress-target" :class="[ bgClass ]" :style="{ 'width' : target+'%' }"></div>
            <div class="v-progress-linear__content"><strong>{{ Math.ceil(value) }}%</strong></div>
        </div>
    </div>
</template>
<style scoped>
    .v-progress-target {
        border-right: 2px solid #F0F4C3!important;
        background-color: rgba(0, 0, 0, 0) !important;
    }
</style>
<script>
    function initialState() {
        return {
            loading: false
        };
    }
    export default {
        name: "ProgressBar",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            value: Number,
            target: Number,
            color: String
        },
        methods: {
        },
        computed: {
            bgClass() {
                return 'bg-' + this.color;
            }
        }
    };
</script>