<template>
    <v-table v-if="data" density="compact" class="small-table">
        <thead>
            <tr class="bg-blue-grey-lighten-5">
                <th class="text-left">
                    Contact
                </th>
                <th class="text-right">
                    $
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="contact in data" :key="contact.id">
                <td><contact-link :contact="contact"></contact-link></td>
                <td class="text-right">${{ numberWithCommas(contact.total, 2) }}</td>
            </tr>
        </tbody>
    </v-table>
</template>
<style scoped>

</style>
<script>
    import { numberWithCommas } from "../../mixins/numberMixins";
    function initialState() {
        return {
            loading: false
        };
    }
    export default {
        name: "TopCustomersTable",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            data: Array
        },
        methods: {
            numberWithCommas
        },
        computed: {
        }
    };
</script>