<template>
    <div>
        <v-overlay :model-value="loading"
                   class="align-center justify-center">
            <v-progress-circular color="primary"
                                 indeterminate
                                 size="64"></v-progress-circular>
        </v-overlay>
        <v-card variant="flat">
            <v-card-text>
                <div>
                    <v-text-field label="Search..."
                                  prepend-icon="mdi-magnify"
                                  v-model="searchTerm"
                                  density="compact"
                                  clearable></v-text-field>
                </div>
                <v-table v-if="jobs.length > 0">
                    <thead>
                        <tr>
                            <th class="w-20">#</th>
                            <th class="w-15">Status</th>
                            <th class="w-10">Target</th>
                            <th class="w-20">Customer</th>
                            <th class="w-20">Cust#</th>
                            <th class="w-20"></th>
                        </tr>
                    </thead>
                    <tbody v-for="job in filteredJobsList" :key="job.id">
                        <tr class="job-row" :class="{'rework-job-row':job.jobTag == jobConstants.TAG_REWORK}">
                            <td>
                                <job-link :job="job" :search-term="searchTerm"></job-link>
                            </td>
                            <td>
                                <type-chip :type="job.status" entity="Job" :object="job"></type-chip>
                            </td>
                            <td><span v-if="job.dateTargetCompletion">{{ $dayjs(job.dateTargetCompletion).format("DD/MM/YYYY") }}</span></td>
                            <td><contact-link :contact="job.contact" :search-term="searchTerm"></contact-link></td>
                            <td><span v-html="formatSearchName(job.customerNumberFull, searchTerm)"></span></td>
                            <td class="text-right">
                                <v-btn v-if="!job.pretreated"
                                       prepend-icon="mdi-check-circle"
                                       @click="confirmPretreated(job)"
                                       color="info">Mark Pretreated</v-btn>
                            </td>
                        </tr>
                    </tbody>
                </v-table>
            </v-card-text>
        </v-card>
        <v-dialog v-model="confirmUpdate"
                  width="40%"
                  height="60%">
            <v-card>
                <v-card-text>
                    Are you sure you want to mark this job as pretreated?
                </v-card-text>
                <v-card-actions>
                    <v-btn dark @click="confirmUpdate = false" :disabled="saving">
                        CANCEL
                    </v-btn>
                    <v-btn @click="updatePretreated"
                           :loading="saving"
                           prepend-icon="mdi-check-circle"
                           variant="flat"
                           color="success">
                        MARK PRETREATED
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import { mapActions } from "vuex";
    import { numberWithCommas } from "../../mixins/numberMixins";
    import { formatSearchName } from "../../mixins/searchMixins";
    import jobs from "../../constants/jobs";
    function initialState() {
        return {
            jobs: [],
            searchTerm: '',
            loading: false,
            confirmUpdate: false,
            confirmJob: null,
            saving: false
        };
    }
    export default {
        name: "PretreatmentTable",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.loadData();
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack",
            ]),
            numberWithCommas,
            formatSearchName,
            loadData() {
                this.loading = true;
                this.$api.get('dashboard/pretreatment')
                    .then(res => {
                        this.jobs = res.data;
                        this.loading = false;
                    })
                    .catch(error => {
                        this.loading = false;
                        this.showErrorSnack('There was an issue getting the jobs');
                    });
            },
            confirmPretreated(job) {
                this.confirmJob = job;
                this.confirmUpdate = true;
            },
            updatePretreated() {
                this.saving = true;
                this.$api.patch(this.$entities.Job.ROUTE + this.confirmJob.id, [{
                    op: "replace",
                    path: "/datePretreatment",
                    value: this.$dayjs().format('YYYY-MM-DD')
                }]).then(res => {
                    this.saving = false;
                    this.confirmJob = null;
                    this.confirmUpdate = false;
                    this.loadData();
                    this.showSuccessSnack("Job updated!");
                    return;
                }).catch(error => {
                    this.saving = false;
                    this.showErrorSnack("We ran into an issue...");
                });
            },
        },
        computed: {
            filteredJobsList() {
                if (this.jobs) {
                    if (this.searchTerm && this.searchTerm.length > 0) {
                        let upperSearch = this.searchTerm.toUpperCase();
                        return this.jobs.filter(item => {
                            return item.customerNumberFull.toUpperCase().includes(upperSearch) || item.name.toUpperCase().includes(upperSearch) || item.contact.name.toUpperCase().includes(upperSearch);
                        });
                    }
                    return this.jobs;
                }
                return [];
            },
            jobConstants() {
                return jobs;
            },
        },
        watch: {
        }
    };
</script>
<style scoped>
    .rework-job-row td {
        background-color: #FFCDD2;
    }
    .job-row td, th{
        border-top: 1px solid #dee2e6;
    }
</style>