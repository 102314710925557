import entities from '../../constants/entities';
import api from '../../helpers/api';
export const products = {
    state: {
        contactProductsLoading: false,
        contactProducts: []
    },
    mutations: {
        setContactProductsLoading(state, loading) {
            state.contactProductsLoading = loading;
        },
        setContactProducts(state, contactProducts) {
            state.contactProducts = contactProducts;
        }
    },
    actions: {
        loadContactProducts({ commit, dispatch }, contactId) {
            commit("setContactProductsLoading", true);
            api.get(entities.Product.ROUTE + "contactvalidproducts?contactid=" + contactId).then(res => {
                commit("setContactProductsLoading", false);
                commit("setContactProducts", res.data);
            })
            .catch(error => {
                commit("setContactProductsLoading", false);
                dispatch("showErrorSnack", 'Cannot load colour groups');
            });
        },
    },
    getters: {
    }
}