import store from '../store/store'
import axios from 'axios'
const token = {
    async refreshToken() {

        try {
            store.commit("setLoggingIn", true);
            const res = await axios.post(process.env.VUE_APP_API_URL + 'api/token/refresh', {
                Token: store.state.auth.user.token,
                RefreshToken: store.state.auth.user.refreshToken
            });
            store.commit("setLoggingIn", false);
            let user = res.data;
            store.commit("setUser", user);
            // store user details and jwt in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
            store.dispatch('showSuccessSnack', "Logged in");
        } catch (error) {
            store.commit("setLoggingIn", false);
            store.dispatch('logout');
        }
    }, 
}
export default token;