<template>
    <div id="notes-container">
        <note-input></note-input>
        <note-display></note-display>
    </div>
</template>
<style scoped>
    #notes-container {
        height: 100%;
    }
</style>
<script>
    import { mapActions, mapState } from "vuex";
    function initialState() {
        return {
            loading: false,
        };
    }
    export default {
        name: "NoteBar",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
        },
        methods: {
        },
        computed: {
            ...mapState({
                entityRecord: (state) => state.entities.entityRecord,
                notesLoading: (state) => state.notes.notesLoading
            }),
        },
        watch: {
        }
    };
</script>