<template>
    <span>
        <span v-if="line.description">{{ line.description }}</span><span v-if="!line.description && line.product">{{ line.product.name  }}</span> <v-chip v-if="line.line" size="x-small" variant="flat" class="ml-1">{{ line.line }}</v-chip> <v-chip v-if="lineDiscount" size="x-small" variant="flat" class="ml-1" color="primary">{{ lineDiscount }}% Discount</v-chip>
    </span>
</template>
<style scoped>
</style>
<script>
    import { mapActions, mapState } from "vuex";
    function initialState() {
        return {
            saving: false,
            loading: false,
        };
    }
    export default {
        name: "JobItemDescription",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            line: Object,
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
        },
        computed: {
            lineDiscount() {
                let result = 0;
                this.line.jobItemCharges.forEach(charge => {
                    if (charge.discount) {
                        result = charge.discount * 100;
                    }
                });
                if (result != 0) {
                    return result;
                }
                return null;
            }
        },
        watch: {
        }
    };
</script>