<template>
    <div>
        <v-overlay :model-value="loading"
                   class="align-center justify-center">
            <v-progress-circular color="primary"
                                 indeterminate
                                 size="64"></v-progress-circular>
        </v-overlay>
        <v-card variant="flat" class="pa-4 timetable">
            <h2 v-if="today" class="v-sm-and-down hidden-md-and-up">{{ today.format('MMMM D YYYY') }} <v-chip v-if="$dayjs().isSame(today, 'day')" class="today-chip" size="large">Today</v-chip></h2>
            <div class="d-flex justify-space-between mb-4">
                <h2 v-if="today" class="hidden-sm-and-down">{{ today.format('MMMM D YYYY') }} <v-chip v-if="$dayjs().isSame(today, 'day')" class="today-chip" size="large">Today</v-chip></h2>
                <v-btn-toggle variant="flat"
                              divided>
                    <v-btn @click="movePrevious" icon="mdi-chevron-left-circle-outline"></v-btn>
                    <v-btn @click="moveToday">Today</v-btn>
                    <v-btn @click="moveNext" icon="mdi-chevron-right-circle-outline"></v-btn>
                </v-btn-toggle>
            </div>
            <v-row>
                <v-col :md="taskDetails.showColours ? 4 : 6">
                    <v-card color="blue-grey-darken-1" variant="flat" class="text-center">
                        <template v-slot:subtitle>
                            # JOBS
                        </template>
                        <template v-slot:text>
                            <v-skeleton-loader v-if="loading" type="avatar"></v-skeleton-loader>
                            <h2 v-if="!loading">{{ scheduledJobs.length }}</h2>
                            <v-chip size="small" prepend-icon="mdi-alert-decagram" color="orange-lighten-2" v-if="overdueJobs && overdueJobs.length > 0" class="mt-2">OD {{ overdueJobs.length }}</v-chip>
                        </template>
                    </v-card>
                </v-col>
                <v-col :md="taskDetails.showColours ? 4 : 6">
                    <v-card color="cyan-darken-1" variant="flat" class="text-center">
                        <template v-slot:subtitle>
                            JOBS TOTAL
                        </template>
                        <template v-slot:text>
                            <v-skeleton-loader v-if="loading" type="avatar"></v-skeleton-loader>
                            <h2 v-if="!loading">${{ jobsSubtotal(scheduledJobs) }}</h2>
                            <v-chip size="small" prepend-icon="mdi-alert-decagram" color="orange-lighten-2" v-if="overdueJobs && overdueJobs.length > 0" class="mt-2">OD ${{ jobsSubtotal(overdueJobs) }}</v-chip>
                        </template>
                    </v-card>
                </v-col>
                <v-col v-if="taskDetails.showColours" md="4">
                    <v-card color="indigo-accent-2" variant="flat" class="text-center">
                        <template v-slot:subtitle>
                            # OF COLOURS
                        </template>
                        <template v-slot:text>
                            <v-skeleton-loader v-if="loading" type="avatar"></v-skeleton-loader>
                            <h2 v-if="!loading">{{ jobColourTotal(scheduledJobs).length }}</h2>
                            <v-chip size="small" prepend-icon="mdi-alert-decagram" color="orange-lighten-2" v-if="overdueJobs && overdueJobs.length > 0" class="mt-2">OD {{ jobColourTotal(overdueJobs).length }}</v-chip>
                        </template>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col md="12" v-if="timetable.length == 0 && !loading" class="text-center">
                    <h3>No jobs scheduled for today</h3>
                </v-col>
                <v-col md="12" v-if="timetable.length > 0">
                    <div class="pa-2">
                        <v-text-field label="Search..."
                                      prepend-icon="mdi-magnify"
                                      v-model="searchTerm"
                                      density="compact"
                                      clearable></v-text-field>
                    </div>
                    <v-card class="mx-auto mb-2"
                            v-if="overdueJobs.length > 0"
                            color="orange-lighten-2"
                            prepend-icon="mdi-alert-decagram"
                            subtitle="These jobs were not completed on previous days">
                        <template v-slot:title>
                            <span class="font-weight-black">Overdue Jobs</span>
                        </template>
                        <v-card-text class="bg-surface-light pt-4">
                            <task-timetable-table :table-jobs="overdueJobs" :task-details="taskDetails" :search-term="searchTerm"></task-timetable-table>
                        </v-card-text>
                    </v-card>
                    <task-timetable-table :table-jobs="scheduledJobs" :task-details="taskDetails" :search-term="searchTerm"></task-timetable-table>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>
<script>
    import { mapActions } from "vuex";
    import { numberWithCommas } from "../../mixins/numberMixins";
    function initialState() {
        return {
            loading: false,
            today: null,
            timetable: [],
            searchTerm: ''
        };
    }
    export default {
        name: "TaskTimetable",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.loading = true;
            this.moveToday();
        },
        props: {
            taskDetails: Object
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack",
            ]),
            numberWithCommas,
            moveToday() {
                this.today = this.$dayjs();
                this.loadTimetable();
            },
            movePrevious() {
                this.today = this.today.add(-1, 'day');
                this.loadTimetable();
            },
            moveNext() {
                this.today = this.today.add(1, 'day');
                this.loadTimetable();
            },
            loadTimetable() {
                this.loading = true;
                this.$api.get('/dashboard/timetable?date=' + this.today.format('YYYY-MM-DD') + "&task=" + this.taskDetails.id)
                    .then(res => {
                        this.timetable = res.data;
                        this.loading = false;
                    }).catch(error => {
                        this.loading = false;
                        this.showErrorSnack("There was an issue getting the timetable.");
                    });
            },
            getCardColour(job){
                if (job.currentTask.running){
                    return "blue-lighten-2";
                } else if (job.status < this.taskDetails.id){
                    return "amber-lighten-2";
                }
                return "";
            },
            expandJob(job) {
                if (job.expand) {
                    job.expand = false;
                } else {
                    job.expand = true;
                }
                this.timetable.forEach(otherJob => {
                    if (otherJob.id != job.id) {
                        otherJob.expand = false;
                    }
                });
            },
            jobsSubtotal(jobList) {
                if (!jobList || jobList.length == 0) {
                    return 0.00;
                }
                return this.numberWithCommas(jobList.reduce((acc, obj) => acc + obj.jobSubTotal, 0), 2);
            },
            jobColourTotal(jobList) {
                if (!jobList || jobList.length == 0) {
                    return 0;
                }
                let result = [];
                jobList.forEach(item => {
                    item.jobColours.forEach(jobColour => {
                        let index = result.findIndex(obj => obj.name === jobColour.name);
                        if (index < 0) {
                            result.push({ colour: jobColour.name, powder: jobColour.powderRequired })
                        } else {
                            result[index].powder += jobColour.powderRequired;
                        }
                    });
                });
                return result.sort((a, b) => b.powder - a.powder);
            }
        },
        computed: {
            scheduledJobs() {
                if (this.timetable){
                    return this.timetable.filter(item => {
                        return !item.currentTask.overdue;
                    });
                }
                return [];
            },
            overdueJobs() {
                if (this.timetable){
                    return this.timetable.filter(item => {
                        return item.currentTask.overdue;
                    });
                }
                return [];
            },
            jobRunning() {
                if (this.timetable){
                    let runningTask = this.timetable.find(item => item.currentTask.running && item.status == this.taskDetails.id);
                    return runningTask != null;
                }
                return false;
            },
        },
        watch: {
        }
    };
</script>
<style scoped>
    .today-chip {
        background-color: #f5c6cb;
        color: #dc3545;
    }
    .timetable {
        min-height:600px;
    }
</style>