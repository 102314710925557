import api from '../../helpers/api';
export const entities = {
    state: {
        entity: null,
        entityRecord: null, 
        entityRecordLoading: false,
        entities: [],
        entitiesLoading: false,
        page: 1,
        itemsPerPage: 10,
        totalItems: 0,
        editEntity: null,
        showEntityForm: false,
        sortBy: null
    },
    mutations: {
        setEntity(state, entity) {
            state.entity = entity;
        },
        setEntityRecord(state, entityRecord) {
            state.entityRecord = entityRecord;
        },
        setEntityRecordLoading(state, loading) {
            state.entityRecordLoading = loading;
        },
        setEntities(state, { items, totalItems }) {
            state.entities = items;
            if (totalItems) {
                state.totalItems = totalItems;
            }
        },
        setEntitiesLoading(state, loading) {
            state.entitiesLoading = loading;
        },
        setEntitiesPage(state, page) {
            state.page = page;
        },
        setEntitiesItemsPerPage(state, itemsPerPage) {
            state.itemsPerPage = itemsPerPage;
        },
        setEditEntity(state, entity) {
            state.editEntity = entity;
        },
        setShowEntityForm(state, show) {
            state.showEntityForm = show;
        },
        setEntitiesSortBy(state, sortBy) {
            state.sortBy = sortBy;
        }
    },
    actions: {
        loadEntity({ dispatch, commit, state }, entity) {
            if (state.entity != entity) {
                commit('setEntity', entity);
                commit('setEntities', []);
                commit('setEntitiesPage', 1);
                commit('setEntitiesItemsPerPage', 10);
                if (!entity.CUSTOMOVERVIEW) {
                    dispatch("loadDataTable");
                }
            }
        },
        reloadEntities({ dispatch, commit, state }) {
            if (state.entity) {
                dispatch('loadDataTable');
                if (state.entityRecord && state.entity.HASDETAIL) {
                    dispatch('loadEntityRecord', { entityId: state.entityRecord.id, entity: state.entity });
                }
            }
        },
        loadDataTable({ dispatch, commit, state }) {
            commit('setEntitiesLoading', true);
            let route = state.entity.ROUTE + "?page=" + state.page + "&itemsPerPage=" + state.itemsPerPage;
            if (state.sortBy && state.sortBy.length > 0) {
                route += "&sortBy=" + state.sortBy[0].key + "&sortOrder=" + state.sortBy[0].order;
            }
            api.get(route)
                .then(res => {
                    commit('setEntitiesLoading', false);
                    commit('setEntities', {
                        items: res.data.items,
                        totalItems: res.data.totalItems
                    });
                })
                .catch(error => {
                    commit("setEntitiesLoading", false);
                    dispatch("showErrorSnack", 'There was an error loading the table data.');
                });
        },
        updateDataTable({ dispatch, commit, state }, { page, itemsPerPage, sortBy }) {
            commit('setEntitiesPage', page);
            commit('setEntitiesItemsPerPage', itemsPerPage);
            commit('setEntitiesSortBy', sortBy);
            dispatch('loadDataTable');
        },
        editEntity({ commit }, entity) {
            commit('setEditEntity', entity);
            commit('setShowEntityForm', true);
        },
        addEntity({ commit }) {
            commit('setEditEntity', null);
            commit('setShowEntityForm', true);
        },
        patchEntity({ dispatch, commit, state }, { path, value }) {
            if (state.entity && state.entity.ROUTE) {
                api.patch(state.entity.ROUTE + state.entityRecord.id, [{
                    op: "replace",
                    path: "/" + path,
                    value: value
                }]).then(res => {
                    dispatch("showSuccessSnack", state.entity.SINGULAR + " updated!");
                    commit('setEntityRecord', res.data);
                    return;
                }).catch(error => {
                    dispatch("showErrorSnack", "We ran into an issue...");
                });
            }
        },
        loadEntityRecord({ dispatch, commit, state }, { entityId, entity }) {
            commit('setEntity', entity);
            commit('setEntityRecordLoading', true);
            dispatch('initNotes', { classId: entityId, className: entity.TYPE });
            dispatch('initHistory', { classId: entityId, className: entity.TYPE })
            api.get(entity.ROUTE + entityId)
                .then(res => {
                    commit('setEntityRecord', res.data);
                    commit('setEntityRecordLoading', false);
                })
                .catch(error => {
                    commit("setEntityRecordLoading", false);
                    dispatch("showErrorSnack", 'There was an error loading the entity.');
                });
        }
    },
    getters: {
        dataTableEntities(state) {
            if (state.entities) {
                return state.entities.filter(entity => entity.entityType == state.entity.TYPE);
            } 
            return [];
        },
    }
}