import { shallowRef, ref, computed } from 'vue'
import UserForm from '../../forms/UserForm.vue'

export default {
    ICON: 'mdi-account-group',
    TYPE: 'ApplicationUser',
    SINGULAR: 'User',
    PLURAL: 'Users',
    ROUTE: 'auth/',
    DELETE: 'auth/',
    CREATE: 'auth/',
    UPDATE: 'auth/',
    FORM: shallowRef(UserForm),
    HASDETAIL: false,
    TABLEHEADERS: [
        { title: '', key: 'avatar' },
        { title: 'Name', key: 'name' },
        { title: 'Email', key: 'email' },
        { title: 'Role', key: 'type' },
        { title: 'Extension', key: 'phoneExtension' }, 
        { title: '', key: 'controls' },
    ]
}