export function getColourFromInt(int) {
    let colours = [
        'red',
        'pink',
        'purple',
        'deep-purple',
        'indigo',
        'blue',
        'light-blue',
        'cyan',
        'green',
        'teal',
        'light-green',
        'lime',
        'yellow',
        'amber',
        'orange',
        'deep-orange',
        'brown',
        'blue-grey'
    ];
    if (int > 18) {
        return "primary";
    }
    return colours[int];
}

export function getRandomHexColor() {
    // Generate a random hexadecimal color code
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}