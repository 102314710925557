<template>
    <v-rating v-model="complexityRating"
              empty-icon="mdi-circle-outline"
              length="3"
              readonly
              size="x-small"
              class="overflow-hidden"
              full-icon="mdi-circle"></v-rating>
</template>
<style scoped>
</style>
<script>
    function initialState() {
        return {
            complexityRating: null
        };
    }
    export default {
        name: "ComplexityDisplay",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.complexityRating = this.complexity;
        },
        props: {
            complexity: Number
        },
        methods: {
        },
        computed: {
        }
    };
</script>