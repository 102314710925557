<template>
    <span v-if="date">{{ $dayjs(date).format('DD-MM-YYYY') }} 
        <v-chip variant="flat" size="x-small" v-if="daysAgo != null" :color="countdown ? (daysLeft > 0 ? 'warning' : 'red-darken-1') : 'primary'" class="ml-1">
            <v-icon v-if="countdown" start icon="mdi-timer-alert"></v-icon>
            <span v-if="daysLeft > 0">{{ daysLeft }} days <span v-if="!countdown"> ago</span></span>
            <span v-if="daysLeft == 0 && countdown">OVERDUE</span>
        </v-chip>
    </span>
</template>
<style scoped>
</style>
<script>
    import { mapActions } from "vuex";
    import { numberWithCommas } from "../mixins/numberMixins";
    function initialState() {
        return {
            loading: false,
        };
    }
    export default {
        name: "DateAgoDisplay",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            date: String,
            daysAgo: Number,
            color: String,
            countdown: Boolean
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack",
            ]),
            numberWithCommas
        },
        computed: {
            daysLeft() {
                return Math.ceil(this.daysAgo);
            }
        }
    };
</script>