<template>
    <v-btn prepend-icon="mdi-printer" 
           @click="printJobSheet(job)" 
           size="small" 
           block
           variant="flat" 
           :loading="loading"
           color="primary">
        Print Job Sheet
    </v-btn>
</template>
<style scoped>

</style>
<script>
    function initialState() {
        return {
            loading: false
        };
    }
    import { mapActions } from "vuex";
    export default {
        name: "JobSheetButton",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            job: Object
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack"
            ]),
            printJobSheet() {
                this.loading = true;
                this.$api.get('/jobs/jobsheet/?id=' + this.job.id)
                    .then(res => {
                        this.loading = false;
                        window.open(res.data.fileLink, '_blank').focus();
                    }).catch(error => {
                        this.showErrorSnack('We ran into an issue');
                        this.loading = false;
                    });
            }
        },
        computed: {
        }
    };
</script>