<template>
    <span v-if="job">
        <router-link :to="'/'+$entities.Job.ROUTE+job.id" @click.stop v-html="formatSearchName(job.name, searchTerm)"></router-link>
        <entity-tag-chip :object="job" class="ml-1"></entity-tag-chip>
        <v-chip size="x-small" color="red-darken-3" class="ml-1" variant="flat" v-if="job.deleted">Deleted</v-chip>
    </span>
</template>
<style scoped>
</style>
<script>
    import { formatSearchName } from "../mixins/searchMixins";
    import { mapActions } from "vuex";
    function initialState() {
        return {
            saving: false,
            loading: false
        };
    }
    export default {
        name: "JobLink",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            job: Object,
            searchTerm:String
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            formatSearchName
        },
        computed: {
        }
    };
</script>