<template>
    <div>
        <v-btn color="warning" size="x-small" prepend-icon="mdi-file-import-outline" block @click="showFileImport">IMPORT JOB FILE</v-btn>
        <v-dialog v-model="showModal"
                  persistent
                  fullscreen
                  :scrim="false">
            <v-card width="800">
                <v-toolbar dark
                           color="primary">
                    <v-toolbar-title><v-icon icon="mdi-file-import-outline"></v-icon> Import Job File</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon
                           dark
                           @click="showModal = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <div v-if="xeroInvoice">
                        <v-alert color="success"
                                 theme="dark"
                                 tilte="Invoice created in Xero"
                                 border>
                            <p>Invoice has been successfully created in Xero. Follow the link below and please review.</p>
                            <v-btn flat
                                   @click="viewXeroInvoice"
                                   prepend-icon="mdi-invoice-import-outline">VIEW XERO INVOICE</v-btn>
                            <v-btn dark @click="resetForm" flat class="ml-1">
                                Add another?
                            </v-btn>
                        </v-alert>
                    </div>
                    <div v-if="!jobFileData && !xeroInvoice">
                        <p>Drop the customer purchase order below.</p>
                        <div class="my-2">
                            <div id="dropping-zone-job-file">DROP FILES HERE</div>
                            <v-file-input label="Purchase Order File" v-model="file"></v-file-input>
                        </div>
                    </div>
                    <div v-if="jobFileData && !xeroInvoice">
                        <v-row>
                            <v-col cols="4">
                                <v-text-field v-model="jobFileData.toValue"
                                              :rules="[$rules.required]"
                                              label="To"></v-text-field>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field label="Date"
                                              type="date"
                                              :rules="[$rules.required]"
                                              v-model="jobFileData.dateValue"></v-text-field>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field label="Due"
                                              type="date"
                                              :rules="[$rules.required]"
                                              v-model="jobFileData.dueDateValue"></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field v-model="jobFileData.referenceValue"
                                              :rules="[$rules.required]"
                                              label="Reference"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-alert color="info"
                                 icon="mdi-palette"
                                 theme="dark"
                                 tilte="Colour Not Found"
                                 class="my-3"
                                 v-if="jobFileData.newColourCode || jobFileData.newColourName"
                                 border>
                            <h3>Colour not found!</h3>
                            <p>The following colour cannot be found in the system - <b>{{ newColour }}.</b></p>
                            <p>Add below</p>
                            <v-btn flat
                                   class="ml-2"
                                   @click="AddNewColour"
                                   :loading="loadingNewColour"
                                   prepend-icon="mdi-plus">ADD COLOUR</v-btn>
                            <hr class="my-2" />
                            <p>or assign an existing colour if you think it's already in the system.</p>
                            <v-combobox v-model="existingColour"
                                        :loading="coloursSelectLoading"
                                        placeholder="Select an existing colour..."
                                        :items="coloursSelect"
                                        @update:modelValue="assignExistingColour"
                                        item-title="selectName"></v-combobox>
                        </v-alert>
                        <v-table density="compact" class="border">
                            <thead>
                                <tr>
                                    <th class="text-left w-5"></th>
                                    <th class="text-left w-10">
                                        Description
                                    </th>
                                    <th class="text-right w-10">
                                        Area (m<sup>2</sup>)
                                    </th>
                                    <th class="text-left w-10">
                                        Flat Sheet?
                                    </th>
                                    <th class="text-left w-20">
                                        Finish
                                    </th>
                                    <th class="text-left w-10">
                                        Both Sides?
                                    </th>
                                    <th class="text-right w-5">
                                        Qty
                                    </th>
                                    <th class="w-20">
                                        <v-icon icon="mdi-calculator-variant-outline"></v-icon>
                                    </th>
                                    <th class="text-right w-5">
                                        Unit Price
                                    </th>
                                    <th class="text-right w-5">
                                        Total
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="line in jobFileData.lines" :key="line.lineNumber">
                                    <td>{{ line.lineNumber }}</td>
                                    <td>
                                        <v-text-field v-model="line.descriptionValue"
                                                      variant="plain"
                                                      disabled
                                                      class="small-field"></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field v-model.number="line.area"
                                                      variant="plain"
                                                      reverse
                                                      disabled
                                                      type="number"></v-text-field>
                                    </td>
                                    <td>
                                        <v-checkbox v-model="line.metalType" :false-value="1" :true-value="0"></v-checkbox>
                                    </td>
                                    <td>
                                        <span v-if="line.colour">
                                            {{ line.colour.selectName }} <span v-if="line.colour.colourGroupName">- {{ line.colour.colourGroupName }} <span v-if="pricingColourGroup(line)">- {{ pricingColourGroup(line).name }} (${{ pricingColourGroup(line).price }})</span></span>
                                        </span>
                                    </td>
                                    <td>
                                        <v-checkbox v-model="line.coatBothSides"></v-checkbox>
                                    </td>
                                    <td>
                                        <v-text-field v-model.number="line.qty"
                                                      variant="plain"
                                                      reverse
                                                      disabled
                                                      type="number"></v-text-field>
                                    </td>
                                    <td>
                                        {{ linePriceCalc(line) }}
                                    </td>
                                    <td class="text-right">
                                        ${{ numberWithCommas(lineUnitPrice(line), 2) }}
                                    </td>
                                    <td class="text-right">
                                        <b>${{ numberWithCommas(lineUnitPrice(line)*line.qty, 2) }}</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="8" class="text-right">
                                        <h3>SUBTOTAL</h3>
                                    </td>
                                    <td class="text-right">
                                        <h3>${{ numberWithCommas(subTotal, 2) }} </h3>
                                    </td>
                                </tr>
                            </tbody>
                        </v-table>
                    </div>
                    <v-alert icon="mdi-alert-decagram-outline"
                                class="my-2"
                                v-if="saveErrors.length > 0"
                                type="warning">
                        <ul class="small-text">
                            <li :key="error" v-for="error in saveErrors">{{ error }}</li>
                        </ul>
                    </v-alert>
                    <div class="mt-2">
                        <v-btn v-if="!jobFileData"
                               :disabled="saveErrors.length > 0 || saving"
                               flat
                               :loading="saving"
                               color="primary"
                               @click="saveFile"
                               prepend-icon="mdi-floppy">UPLOAD FILE</v-btn>
                        <v-btn v-if="jobFileData"
                               :disabled="saveErrors.length > 0 || saving"
                               flat
                               :loading="saving"
                               color="primary"
                               @click="confirmSendXero = true"
                               prepend-icon="mdi-floppy">SEND TO XERO</v-btn>
                        <v-btn flat class="ml-2" @click="showModal = false">CANCEL</v-btn>
                        <v-dialog v-model="confirmSendXero"
                                  width="auto">
                            <v-card>
                                <v-card-text>
                                    Please confirm all the details are correct. Are you sure you want to send this job to Xero?
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn :loading="saving"
                                           color="primary"
                                           prepend-icon="mdi-floppy"
                                           @click="confirmXero"
                                           dark>
                                        SEND TO XERO
                                    </v-btn>
                                    <v-btn dark @click="confirmSendXero = false" :disabled="saving">
                                        CANCEL
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<style>
    #dropping-zone-job-file {
        position: absolute;
        z-index: 10000;
        background-color: lightgrey;
        color: #6c757d;
        height: 60px;
        font-size: 20px;
        border: 2px white dashed;
        text-align: center;
        width: 100%;
        padding-top: 4px;
        cursor: copy;
        margin-left: -5px;
        display: none;
    }
</style>
<script>
    import { mapActions, mapState, mapGetters } from "vuex";
    import { roundAway2dp, numberWithCommas } from "../../mixins/numberMixins";
    import $ from "jquery";
    function initialState() {
        return {
            saving: false,
            loading: false,
            showModal: false,
            file: [],
            jobFileData: null,
            foldTypes: [
                { name: "Flat Sheet", value: 0 },
                { name: "Folded Panel", value: 1 }
            ],
            existingColour: null,
            loadingNewColour: false,
            confirmSendXero: false,
            xeroInvoice: null
        };
    }
    export default {
        name: "ContactJobFileImport",
        data: function () {
            return initialState();
        },
        mounted: function () {
            var fileUploadFunction = this.dropFileLoad;
            $(document).on("dragenter", function (e) {
                if (e.target == this) {
                    return;
                }
                $('#dropping-zone-job-file').show();
                e.preventDefault();
            }).on("dragover", function (e) {
                e.preventDefault();
            }).on("dragleave", function (e) {
                if (e.originalEvent.pageX != 0 || e.originalEvent.pageY != 0) {
                    return false;
                }
                $('#dropping-zone-job-file').hide();
            }).on("drop", function (e) {
                $('#dropping-zone-job-file').hide();
                e.preventDefault();
                e.stopPropagation();
                if (e.target instanceof HTMLElement && e.target == $('#dropping-zone-job-file')[0]) {
                    fileUploadFunction(e.originalEvent);
                }
            });
        },
        props: {
            contact: Object
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack",
                "loadSelectColours",
            ]),
            roundAway2dp,
            numberWithCommas,
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            dropFileLoad(e) {
                var files = e.target.files || e.dataTransfer.files;
                if (!files.length)
                    return;
                this.file.push(files[0]);
                document.getElementById('file-input').value = null;
            },
            showFileImport() {
                this.showModal = true;
                this.loadSelectColours();
            },
            pricingColourGroup(line) {
                if (line.colour) {
                    switch (line.colour.colourGroupName) {
                        case "Standard":
                            return { name: 'Group 1', price: line.metalType == 0 ? 27 : 25 };
                        case "P2":
                        case "P3":
                            return { name: 'Group 2', price: line.metalType == 0 ? 34 : 32 };
                        default:
                            return { name: 'Group 3', price: line.metalType == 0 ? 39 : 37 };
                    }
                }
                return null;
            },
            lineUnitPrice(line) {
                let result = null;
                let pricingGroup = this.pricingColourGroup(line);
                if (line.area && line.qty && pricingGroup) {
                    result = (line.area * pricingGroup.price);
                }
                if (result != null && line.coatBothSides) {
                    result *= 1.12;
                }
                return this.roundAway2dp(result);
            },
            linePriceCalc(line) {
                let result = line.area;
                let pricingGroup = this.pricingColourGroup(line);
                if (pricingGroup) {
                    result += " * (" + line.colour.colourGroupName + ' - ' + pricingGroup.name + ") $" + pricingGroup.price;
                }
                if (line.coatBothSides) {
                    result += ' + 12%';
                }
                return result;
            },
            saveFile() {
                this.saving = true;
                let data = new FormData();
                if (this.file && this.file.length > 0) {
                    data.append("file", this.file[0]);
                }
                this.$api.post("/Contacts/loadjobfile/"+this.contact.id, data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(res => {
                    this.saving = false;
                    this.showSuccessSnack("File loaded!");
                    this.file = null;
                    this.jobFileData = res.data;
                }).catch(error => {
                    this.saving = false;
                    this.showErrorSnack("There was an issue uploading the file");
                });
            },
            AddNewColour() {
                this.loadingNewColour = true;
                let data = new FormData();
                data.append('code', this.jobFileData.newColourCode);
                data.append('name', this.jobFileData.newColourName);
                data.append('colourGroup.id', 4);
                this.$api.post("/Colours/", data)
                    .then(res => {
                        this.jobFileData.newColourCode = null;
                        this.jobFileData.newColourName = null;
                        this.showSuccessSnack("New colour loaded!");
                        this.assignColour(res.data);
                    }).catch(error => {
                        this.loadingNewColour = false;
                        this.showErrorSnack("There was an issue loading the new colour");
                    });
            },
            assignExistingColour() {
                if (this.existingColour) {
                    this.assignColour(this.existingColour);
                }
            },
            assignColour(colour) {
                this.jobFileData.lines.forEach(line => {
                    line.colour = colour;
                });
            },
            confirmXero() {
                this.saving = true;
                let formData = new FormData();
                formData.append('to', this.jobFileData.toValue);
                formData.append('date', this.jobFileData.dateValue);
                formData.append('dueDate', this.jobFileData.dueDateValue);
                formData.append('reference', this.jobFileData.referenceValue);
                let i = 0;
                this.jobFileData.lines.forEach(line => {
                    formData.append('lines[' + i + '].lineNumber', line.lineNumber);
                    formData.append('lines[' + i + '].description', line.descriptionValue);
                    formData.append('lines[' + i + '].colour.id', line.colour.id);
                    formData.append('lines[' + i + '].qty', line.qty);
                    formData.append('lines[' + i + '].lineAmount', this.lineUnitPrice(line));
                    formData.append('lines[' + i + '].coatBothSides', line.coatBothSides);
                    formData.append('lines[' + i + '].metalType', line.metalType);
                    i++;
                });
                this.$api.post("/Contacts/FinishJobFile/"+this.contact.id, formData)
                    .then(res => {
                        this.saving = false;
                        this.xeroInvoice = res.data;
                        this.confirmSendXero = false;
                    }).catch(error => {
                        this.saving = false;
                        this.showErrorSnack("There was an issue saving the job");
                    });
            },
            viewXeroInvoice() {
                if (this.xeroInvoice) {
                    window.open("https://go.xero.com/AccountsReceivable/Edit.aspx?InvoiceID=" + this.xeroInvoice.InvoiceID, '_blank').focus();
                }
            },
            resetForm() {
                this.reset();
                this.showModal = true;
            }
        },
        computed: {
            ...mapState({
                coloursSelect: (state) => state.colours.coloursSelect,
                coloursSelectLoading: (state) => state.colours.coloursSelectLoading
            }),
            subTotal() {
                let total = 0;
                if (this.jobFileData) {
                    this.jobFileData.lines.forEach(line => {
                        total += this.lineUnitPrice(line) * line.qty;
                    });
                }
                return total;
            },
            saveErrors() {
                let errors = [];
                if (!this.jobFileData) {
                    if ((!this.file || this.file.length <= 0)) {
                        errors.push('Need upload file');
                    }
                }
                return errors;
            },
            newColour() {
                if (this.jobFileData) {
                    return this.jobFileData.newColourCode + ' ' + this.jobFileData.newColourName;
                }
                return '';
            }
        }
    };
</script>