import { shallowRef, ref, computed } from 'vue'
import MessageTemplateForm from '../../forms/MessageTemplateForm.vue'

export default {
    ICON: 'mdi-book-outline',
    TYPE: 'MessageTemplate',
    SINGULAR: 'Message Template',
    PLURAL: 'Message Templates',
    ROUTE: 'messagetemplates/',
    DELETE: 'messagetemplates/',
    CREATE: 'messagetemplates/',
    UPDATE: 'messagetemplates/',
    FORM: shallowRef(MessageTemplateForm),
    HASDETAIL: false,
    TABLEHEADERS: [
        { title: 'Type', key: 'type' },
        { title: 'Name', key: 'name' },
        { title: 'Subject', key: 'subject' },
        { title: '', key: 'controls' },
    ]
}