<template>
    <v-card v-if="definition" class="pa-2">
        <h2><a @click="$router.go(-1)" class="cursor-pointer"><v-icon icon="mdi-chevron-left"></v-icon></a> {{ definition.title }}</h2>
        <component :is="definition.component" :definition="definition"></component>
    </v-card>
</template>
<style scoped>
</style>
<script>
    import reports from '../constants/reports';
    function initialState() {
        return {
            saving: false,
            loading: false
        };
    }
    export default {
        name: "ReportsRunView",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            id: String
        },
        methods: {
            reset: function () {
                Object.assign(this.$data, initialState());
            },
        },
        computed: {
            definition() {
                let repId = this.id;
                return reports.definitions.find((element) => element.code == repId);
            }
        }
    };
</script>