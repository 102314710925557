<template>
    <v-breadcrumbs v-if="breadCrumbItems && breadCrumbItems.length > 0" :items="breadCrumbItems" density="compact">
        <template v-slot:prepend>
            <v-icon v-if="$route.meta.breadcrumbs" size="small" :icon="$route.meta.breadcrumbs.icon"></v-icon>
        </template>
        <template v-slot:item="{ item }">
            <v-breadcrumbs-item :to="item.to"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact>
                {{ item.title }}
            </v-breadcrumbs-item>
        </template>
    </v-breadcrumbs>
</template>
<style scoped>
</style>
<script>
    import { mapState } from "vuex";
    function initialState() {
        return {
            breadCrumbData: null
        };
    }
    export default {
        name: "BreadcrumbBar",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
        },
        methods: {
            reset: function () {
                Object.assign(this.$data, initialState());
            },
        },
        computed: {
            ...mapState({
                entityRecord: (state) => state.entities.entityRecord,
            }),
            breadCrumbItems() {
                if (this.$route.meta.breadcrumbs){
                    var name = this.entityRecord != null ? this.entityRecord.name : "";
                    return this.$route.meta.breadcrumbs.items.map(function(item) {
                       return {
                            title : item.title ? item.title.replace('Name', name) : item.title,
                            disabled : item.disabled,
                            to :item.to
                        };
                    });
                }
                return [];
            }
        }
    };
</script>