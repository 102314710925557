<template>
    <v-avatar v-if="object.colour" :color="object.colour.toLowerCase()">
        <span>{{ object.avatar }}</span>
    </v-avatar>
    <v-avatar v-if="!object.colour" :class="['avatar-colour', autoAvatarColour]">
        <span>{{ object.avatar }}</span>
    </v-avatar>
</template>
<style scoped>
</style>
<script>
    function initialState() {
        return {
            loading: false
        };
    }
    export default {
        name: "AvatarSmall",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            object: Object
        },
        methods: {
        },
        computed: {
            autoAvatarColour() {
                let ident = this.object.id.toString();
                return `avatar-${ident.slice(-1)}`;
            }
        }
    };
</script>