<template>
    <div v-if="currentTask != null">
        <v-btn @click.stop="showConfirm('completetask')"
               size="small"
               variant="flat"
               color="success"
               :disabled="confirmChange"><v-icon icon="mdi-play-circle-outline"></v-icon> Complete</v-btn>
        <v-dialog v-model="confirmChange"
                  max-width="800"
                  persistent>
            <v-card :prepend-icon="confirmIcon"
                    :text="confirmText"
                    :title="confirmTitle">
                <template v-slot:text>
                    <div v-if="confirmMode == 'completetask'">
                        <small>Please indicate below the location where you have put the completed material in the warehouse if applicable.</small>
                        <v-textarea v-model="materialLocation"
                                    label="Material Location"
                                    single-line></v-textarea>
                    </div>
                    <div v-if="job.currentTask && job.currentTask.group == finishGroup">
                        <v-divider class="my-2"></v-divider>
                        <v-checkbox label="Does the job require rework?" hide-details="auto" density="compact" v-model="reworkRequired"></v-checkbox>
                        <div v-if="reworkRequired">
                            <p>Please indicate below what items are <strong>outstanding</strong>.</p>
                            <job-task-item-select-table v-model:job-item-tasks="reworkItemsList"></job-task-item-select-table>
                        </div>
                    </div>
                </template>
                <template v-slot:actions>
                    <v-alert icon="mdi-alert-circle"
                             class="mb-2"
                             v-if="completeErrors.length > 0"
                             type="warning">
                        <ul class="small-text">
                            <li :key="error" v-for="error in completeErrors">{{ error }}</li>
                        </ul>
                    </v-alert>
                    <v-spacer></v-spacer>
                    <v-btn @click="confirmChange = false" :disabled="loadingConfirm">
                        Cancel
                    </v-btn>
                    <v-btn @click="acceptConfirm" :loading="loadingConfirm" :disabled="completeErrors.length > 0">
                        Confirm
                    </v-btn>
                </template>
            </v-card>
        </v-dialog>
    </div>
</template>
<style scoped>
</style>
<script>
    import { numberWithCommas } from "../../mixins/numberMixins";
    import { mapActions } from "vuex";
    import task from '../../constants/tasks';
    import _cloneDeep from "lodash/cloneDeep";
    import { buildJobTaskItems } from "../../mixins/jobMixins";
    function initialState() {
        return {
            loading: false,
            confirmChange: false,
            confirmMode: null,
            loadingConfirm: false,
            disableBtns: false,
            materialLocation:'',
            reworkRequired:false,
            reworkItemsList: []
        };
    }
    export default {
        name: "JobTaskButtons",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            task: Object,
            job: Object, 
            taskRunning: Boolean
        },
        methods: {
            numberWithCommas,
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack",
                "reloadHistory"
            ]),
            showConfirm(mode) {
                this.confirmMode = mode;
                this.confirmChange = true;
                this.materialLocation = '';
                 if (this.job) {
                    this.reworkItemsList = this.job.jobItems.map(jobItem => ({
                        jobItemId: jobItem.id,
                        itemDescription: jobItem.description,
                        quantity : 0,
                        itemQuantity: jobItem.quantity - jobItem.quantityCompleted
                    }));
                }
            },
            acceptConfirm() {
                this.loadingConfirm = true;
                let formData = new FormData();
                formData.append('location', this.materialLocation);
                if (this.reworkRequired) {
                    let i = 0;
                    this.reworkItemsList.filter(item => { return item.quantity > 0 }).forEach(item => {
                        formData.append(`reworkItems[${i}].id`, item.jobItemId);
                        formData.append(`reworkItems[${i}].quantity`, item.quantity);
                        i++;
                    });
                }
                this.$api.post('/jobs/' + this.confirmMode + '/?id=' + this.job.id, formData)
                    .then(res => {
                        this.loadingConfirm = false;
                        this.confirmChange = false;
                        this.$emit('update:job', res.data);
                        this.reloadHistory();
                    }).catch(error => {
                        this.showErrorSnack('We ran into an issue');
                        this.loadingConfirm = false;
                    });
            },
            buildJobTaskItems
        },
        computed: {
            incompleteItems() {
                return this.reworkItemsList.filter(item => {
                    return item.quantity > 0;
                });
            },
            completeErrors() {
                let errors = [];
                if (this.reworkRequired && this.incompleteItems.length == 0){
                    errors.push("Need at least 1 item to rework.");
                }
                return errors;
            },
            finishGroup() {
                return task.GRP_FINISH;
            },
            currentTask() {
                if (this.job) {
                    return this.job.currentTask;
                }
                return null;
            },
            confirmIcon() {
                switch (this.confirmMode) {
                    case "starttimer":
                        return "mdi-timer";
                    case "completetask":
                        return "mdi-play-circle-outline";
                }
                return "";
            },
            confirmTitle() {
                switch (this.confirmMode) {
                    case "starttimer":
                        return "Confirm Start Timer";
                    case "completetask":
                        return "Confirm Complete Task";
                }
                return "";
            },
            confirmText() {
                switch (this.confirmMode) {
                    case "starttimer":
                        return "Are you sure you want to start the timer?";
                    case "completetask":
                        return "Are you sure you want to complete the task?";
                }
                return "";
            }
        }
    };
</script>