import jobs from "../constants/jobs";
export function getTagDetails(tag) {
    if (!tag || tag.length == 0) {
        return null;
    }
    let tags = jobs.tags;
    let details = tags.find(item => item.name == tag);
    return details;
}

export function getTagClass(tag) {
    let details = getTagDetails(tag);
    if (details) {
        return 'bg-' + details.colour.replace('darken-1', 'lighten-4');
    }
    return null;
}

export function buildJobTaskItems(task) {
    let partItems = [];
    let excludeList = [];
    let fullItems = [];
    task.jobItemTasks.forEach(jobItem => {
        if (jobItem.quantity == 0 && jobItem.quantity != jobItem.itemQuantity) {
            excludeList.push(jobItem.itemDescription + "(<b>x0</b>)");
        } else if (jobItem.quantity < jobItem.itemQuantity) {
            partItems.push(jobItem.itemDescription + " (<b>x" + jobItem.quantity + "</b>)");
        } else {
            fullItems.push(jobItem.itemDescription)
        }
    });
    return {
        partItems: partItems,
        excludeList: excludeList,
        fullItems: fullItems
    };
}
export function jobTaskItemsDesc(task, ignoreAll) {
    let description = ignoreAll ? '' : '<b>All items</b>';
    let lists = buildJobTaskItems(task);
    if (lists.partItems.length > 0 || lists.excludeList.length > 0) {
        if (lists.excludeList.length > 0 && (lists.partItems.length + lists.fullItems.length) == 0) {
            description = "None";
        } else if (lists.excludeList.length > 0 && lists.excludeList.length > (lists.partItems.length + lists.fullItems.length)) {
            description = "<b>Only</b> " + lists.fullItems.concat(lists.partItems).join(', ');
        } else {
            description = "<b>All but</b> " + lists.excludeList.concat(lists.partItems).join(', ');
        }
    }
    return description;
}