import JobSchedulePanel from '../components/panels/JobSchedulePanel.vue'
import JobTaskPanel from '../components/panels/JobTaskPanel'
import JobCompletePanel from '../components/panels/JobCompletePanel'
// Archive
import JobInwardsPanel from '../components/panels/JobInwardsPanel'
// Groups
const GRP_UNREC = 4;
const GRP_PREPREP = 3;
const GRP_PREP = 2;
const GRP_FINISH = 1;
const GRP_POST = 0;
export default {
    GRP_UNREC,
    GRP_PREPREP,
    GRP_PREP,
    GRP_FINISH,
    GRP_POST,
    types: [
        {
            id: 0,
            icon: 'mdi-calendar-blank',
            title: 'Schedule',
            component: JobSchedulePanel,
            schedule: false,
            group: GRP_POST
        },
        {
            id: 1,
            icon: 'mdi-cube-scan',
            title: 'Inwards',
            schedule: false,
            timer: false,
            component: JobTaskPanel,
            group: GRP_UNREC
        },
        {
            id: 2,
            icon: 'mdi-bucket-outline',
            title: 'Stripping',
            schedule: true,
            timer: false,
            showColours: false,
            scheduleOrder: 3,
            selected: false,
            startDate: null,
            group: GRP_PREPREP,
            component: JobTaskPanel,
            jobItemTasks: []
        },
        {
            id: 3,
            icon: 'mdi-spray',
            title: 'Blasting',
            schedule: true,
            timer: false,
            showColours: false,
            scheduleOrder: 4,
            selected: false,
            startDate: null,
            group: GRP_PREPREP,
            component: JobTaskPanel,
            jobItemTasks: []
        },
        {
            id: 4,
            icon: 'mdi-shower-head',
            title: 'Pretreat',
            schedule: true,
            timer: true,
            showColours: false,
            scheduleOrder: 2,
            selected: true,
            startDate: null,
            group: GRP_PREP,
            component: JobTaskPanel,
            jobItemTasks: []
        },
        {
            id: 5,
            icon: 'mdi-spray-bottle',
            title: 'Line 1',
            schedule: true,
            timer: true,
            showColours: true,
            scheduleOrder: 0,
            selected: false,
            startDate: null,
            group: GRP_FINISH,
            component: JobTaskPanel,
            jobItemTasks: []
        },
        {
            id: 6,
            icon: 'mdi-spray-bottle',
            title: 'Line 2',
            schedule: true,
            timer: true,
            showColours: true,
            scheduleOrder: 1,
            selected: false,
            startDate: null,
            group: GRP_FINISH,
            component: JobTaskPanel,
            jobItemTasks:[]
        },
        {
            id: 7,
            icon: 'mdi-stove',
            title: 'Batch Oven',
            schedule: true,
            timer: true,
            showColours: true,
            scheduleOrder: 1,
            selected: false,
            startDate: null,
            group: GRP_FINISH,
            component: JobTaskPanel,
            jobItemTasks: []
        },
        {
            id: 8,
            icon: 'mdi-invoice-text-outline',
            title: 'Invoice',
            schedule: false,
            timer: false,
            component: JobTaskPanel,
            group: GRP_POST,
        },
        {
            id: 9,
            icon: 'mdi-truck-fast',
            title: 'Dispatch',
            schedule: false,
            timer: false,
            component: JobTaskPanel,
            group: GRP_POST,
        },
        {
            id: 10,
            icon: 'mdi-check-circle',
            title: 'Complete',
            schedule: false,
            timer: false,
            component: JobCompletePanel,
            group: GRP_POST,
        },
    ]
}