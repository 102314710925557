<template>
    <div>
        <div>
            <v-overlay :model-value="loading"
                       class="align-center justify-center">
                <v-progress-circular color="primary"
                                     indeterminate
                                     size="64"></v-progress-circular>
            </v-overlay>
            <div v-if="job" class="d-flex w-100 justify-space-between mb-2 pb-2 border-b-sm">
                <v-btn prepend-icon="mdi-calendar-today"
                       size="large"
                       :color="reschedule ? 'orange-lighten-2' : 'primary'"
                       :loading="saving"
                       @click="scheduleJob"
                       :disabled="dateErrors.length > 0">
                    <span v-if="reschedule">Reschedule</span><span v-if="!reschedule">Schedule</span> Job
                </v-btn>
            </div>
            <v-row>
                <v-col v-if="job">
                    <div class="job-schedule-input">
                        <v-text-field label="Material Arrival Date"
                                      type="date"
                                      @change="updateTaskDates"
                                      density="compact"
                                      hide-details="auto"
                                      :disabled="saving"
                                      :loading="loadingDates"
                                      v-model="materialArrivalDate"></v-text-field>
                        <v-text-field label="Target Completion Date"
                                      type="date"
                                      @change="updateTaskDates"
                                      density="compact"
                                      hide-details="auto"
                                      :disabled="saving || !materialArrivalDate"
                                      :loading="loadingDates"
                                      v-model="targetCompletionDate"></v-text-field>
                        <v-sheet class="mt-3" border>
                            <strong class="panel-section-header mt-0"> Tasks</strong>
                            <table class="task-list">
                                <tr v-for="task in sortedTasks" :key="task.id">
                                    <td style="position:relative" :class="{'selected-task' : task.selected}">
                                        <v-checkbox :label="task.title" :disabled="saving || task.completed || !materialArrivalDate" hide-details="auto" density="compact" v-model="task.selected" :loading="loadingDates" @change="loadDates"></v-checkbox>
                                        <v-text-field label="Start Date"
                                                      v-model="task.startDate"
                                                      density="compact"
                                                      :loading="loadingDates"
                                                      :disabled="!targetCompletionDate || saving || task.completed || !materialArrivalDate"
                                                      @change="updateTaskGroupDate(task)"
                                                      hide-details="auto"
                                                      type="date"></v-text-field>
                                        <v-overlay v-model="task.completed"
                                                   persistent
                                                   class="align-center justify-center"
                                                   contained>
                                            <v-alert icon="mdi-check-circle"
                                                     type="success">
                                                Complete
                                            </v-alert>
                                        </v-overlay>
                                        <v-btn size="x-small" 
                                               :disabled="!task.selected || !materialArrivalDate" 
                                               prepend-icon="mdi-list-status"
                                               @click="selectTaskItems(task)">Select task items</v-btn>
                                        <div v-if="task.selected"><small v-html="jobTaskItemsDesc(task, false)"></small></div>
                                    </td>
                                </tr>
                            </table>
                        </v-sheet>
                    </div>
                </v-col>
                <v-col :cols="job ? '9' : '12'">
                    <v-alert icon="mdi-calendar-range"
                             class="mb-2"
                             v-if="dateErrors.length > 0"
                             type="warning">
                        <ul class="small-text">
                            <li :key="error" v-for="error in dateErrors">{{ error }}</li>
                        </ul>
                    </v-alert>
                    <schedule-calendar :schedule-job="calendarJob"></schedule-calendar>
                </v-col>
            </v-row>
            <v-dialog v-model="confirmReplace"
                      max-width="400"
                      persistent>
                <v-card prepend-icon="mdi-file-replace-outline"
                        text="Change of dates found. Would you like to replace all existing dates?"
                        title="Replace dates?">
                    <template v-slot:actions>
                        <v-spacer></v-spacer>

                        <v-btn @click="confirmReplace = false">
                            No
                        </v-btn>

                        <v-btn @click="loadDates" :loading="loadingDates">
                            Yes, Replace
                        </v-btn>
                    </template>
                </v-card>
            </v-dialog>
            <v-dialog v-model="selectJobItemDialog"
                      max-width="800"
                      persistent>
                <v-card prepend-icon="mdi-list-status"
                        text="Specify what items are to be completed for this task if it is not all the job items.">
                    <template v-slot:title>
                        <div class="d-flex justify-space-between align-center">
                            <div class="text-medium-emphasis ps-2">
                                {{ selectJobItemTask.title }} task items
                            </div>
                            <v-btn icon="mdi-close"
                                   variant="text"
                                   @click="selectJobItemDialog = false"></v-btn>
                        </div>
                    </template>
                    <template v-slot:text>
                        <div class="d-flex align-content-start mb-2">
                            <v-btn size="x-small" prepend-icon="mdi-playlist-check" @click="selectJobSelectAll">Select all</v-btn>
                            <v-btn size="x-small" prepend-icon="mdi-playlist-remove" class="ml-2" @click="selectJobUnselectAll">Unselect all</v-btn>
                        </div>
                        <job-task-item-select-table v-model:job-item-tasks="selectJobItemTask.jobItemTasks"></job-task-item-select-table>
                    </template>
                    <template v-slot:actions>
                        <v-spacer></v-spacer>

                        <v-btn @click="selectJobItemDialog = false">
                            Close
                        </v-btn>
                    </template>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>
<style scoped>
    .task-list {
        width:100%;
        border-collapse:collapse;
    }
    .task-list td {
        border-bottom: 1px solid #e5e5e5;
        padding: 5px;
        padding-bottom: 10px;
        background-color: #f5f5f5;
    }
    .task-list td.selected-task{
        background-color: white;
    }
    .small-text {
        font-size: small;
    }
    .job-schedule-input {
        position:relative;
        min-height:600px;
        padding:5px;
        border-radius:5px;
    }
</style>
<script>
    import { mapActions, mapState, mapMutations } from "vuex";
    import tasks from "../../constants/tasks";
    import _cloneDeep from "lodash/cloneDeep";
    import { buildJobTaskItems, jobTaskItemsDesc } from "../../mixins/jobMixins"
    function initialState() {
        return {
            processedJob: null,
            saving: false,
            loading: false,
            targetCompletionDate: null,
            materialArrivalDate: null,
            sortedTasks: [],
            confirmReplace: false,
            loadingDates: false,
            selectJobItemDialog: false,
            selectJobItemTask: null
        };
    }
    export default {
        name: "JobSchedulePanel",
        data: function () {
            return initialState();
        },
        mounted: function () {
            if (this.job) {
                this.loadJob();
            }
        },
        props: {
            job: Object,
            reschedule: Boolean
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack",
                "reloadHistory"
            ]),
            ...mapMutations([
                "setEntityRecord",
                "setShowRescheduleJob"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            updateTaskDates() {
                // Need material arrival date
                if (this.materialArrivalDate) {
                    // No target completiond date so populate
                    if (!this.targetCompletionDate){
                        this.loadDates();
                    } else {
                        this.confirmReplace = true;
                    }
                }
            },
            updateTaskGroupDate(task) {
                if (this.dateErrors.length > 0) {
                    return;
                }
                this.loadDates(task.group, task.startDate);
            },
            loadDates(group, date) {
                if (this.materialArrivalDate == null){
                    return;
                }
                this.loadingDates = true;
                let query = [];
                query.push('materialArrival=' + this.materialArrivalDate);
                if (this.targetCompletionDate){
                    query.push('targetCompletion=' + this.targetCompletionDate);
                }
                query.push('selectedTasks=' + this.selectedTasks.map(item => item.id).join(','));
                if (this.reschedule) {
                    query.push('rescheduleJobId='+this.job.id);
                }
                if (group && date){
                    query.push('updateGroup=' + group);
                    query.push('updateDate=' + date);
                }
                this.$api.get('/jobtask/EstimateTaskDates?'+query.join('&'))
                    .then(res => {
                        this.targetCompletionDate = this.$dayjs(res.data.targetCompletion).format('YYYY-MM-DD');
                        this.confirmReplace = false;
                        this.assignTaskDates(res.data.taskDates);
                        this.loadingDates = false;
                    }).catch(error => {
                        this.loadingDates = false;
                        this.confirmReplace = false;
                        this.showErrorSnack("There was an issue estimating the task dates.");
                    });
            },
            assignTaskDates(taskDates){
                this.sortedTasks.forEach(task => {
                    if (task.id in taskDates){
                        task.startDate = this.$dayjs(taskDates[task.id]).format('YYYY-MM-DD');
                    }
                });
            },
            scheduleJob() {
                this.saving = true;
                let formData = new FormData();
                formData.append('id', this.job.id);
                formData.append('dateTargetCompletion', this.targetCompletionDate);
                formData.append('dateReceiveEstimate', this.materialArrivalDate);
                let i = 0;
                this.selectedTasks.forEach(item => {
                    formData.append(`jobTasks[${i}].task`, item.id);
                    formData.append(`jobTasks[${i}].targetStartDate`, item.startDate);
                    let lists = this.buildJobTaskItems(item);
                    let changeList = lists.excludeList.concat(lists.partItems);
                    if (changeList.length > 0) {
                        let j = 0;
                        item.jobItemTasks.forEach(changeItem => {
                            formData.append(`jobTasks[${i}].jobItemTasks[${j}].jobItemId`, changeItem.jobItemId);
                            formData.append(`jobTasks[${i}].jobItemTasks[${j}].quantity`, changeItem.quantity);
                            j++;
                        });
                    }
                    i++;
                });
                this.$api.post('/jobs/schedule', formData)
                    .then(res => {
                        this.saving = false;
                        // Set job
                        this.$emit('update:job', res.data);
                        // Update entity record
                        if (this.entityRecord && this.entityRecord.entityType == "Job" && res.data.id == this.entityRecord.id){
                            this.setEntityRecord(res.data);
                        }
                        this.reloadHistory();
                        this.setShowRescheduleJob(false);
                        
                    }).catch(error => {
                        this.saving = false;
                        this.showErrorSnack("There was an issue scheduling this job");
                    });
            },
            loadJob() {
                let jobItemTasks = this.job.jobItems.map(jobItem => ({
                    jobItemId: jobItem.id,
                    itemDescription: jobItem.description,
                    quantity: jobItem.quantity - jobItem.quantityCompleted,
                    itemQuantity: jobItem.quantity - jobItem.quantityCompleted
                }));
                this.targetCompletionDate = null;
                this.materialArrivalDate = null;
                if (this.job.dateTargetCompletion) {
                    this.targetCompletionDate = this.$dayjs(this.job.dateTargetCompletion).format('YYYY-MM-DD');
                }
                if (this.job.dateReceiveEstimate) {
                    this.materialArrivalDate = this.$dayjs(this.job.dateReceiveEstimate).format('YYYY-MM-DD');
                }
                if (this.reschedule == true) {
                    this.sortedTasks = this.job.jobTasks.map(task => ({
                        title: task.name,
                        startDate: this.$dayjs(task.targetStartDate).format('YYYY-MM-DD'),
                        id: task.task,
                        selected: true,
                        group: task.group,
                        completed: task.endTime != null,
                        jobItemTasks: (task.jobItemTasks.length > 0 ? task.jobItemTasks : _cloneDeep(jobItemTasks)),
                    }));
                } else {
                    let selectedLines = this.job.jobItems.reduce((uniqueLines, obj) => {
                        if (obj.line && obj.line.trim() !== '' && !uniqueLines.includes(obj.line)) {
                            uniqueLines.push(obj.line);
                        }
                        return uniqueLines;
                    }, []);
                    this.sortedTasks = _cloneDeep(tasks.types).filter(item => item.schedule).sort((a, b) => a.id - b.id);
                    this.sortedTasks.forEach(task => {
                        task.startDate = null;
                        if (selectedLines.includes(task.title)) {
                            task.selected = true;
                        }
                        task.jobItemTasks = _cloneDeep(jobItemTasks)
                    });
                }
                this.processedJob = this.job.id;
            },
            selectTaskItems(task){
                this.selectJobItemTask = task;
                this.selectJobItemDialog = true;
            },
            selectJobSelectAll() {
                if (this.selectJobItemTask) {
                    this.selectJobItemTask.jobItemTasks.forEach(jobItem => {
                        jobItem.quantity = jobItem.itemQuantity;
                    });
                }
            },
            selectJobUnselectAll() {
                if (this.selectJobItemTask) {
                    this.selectJobItemTask.jobItemTasks.forEach(jobItem => {
                        jobItem.quantity = 0;
                    });
                }
            },
            buildJobTaskItems,
            jobTaskItemsDesc
        },
        computed: {
            ...mapState({
                entityRecord: (state) => state.entities.entityRecord,
            }),
            selectedTasks(){
                return this.sortedTasks.filter(task => {
                    return task.selected && !task.completed;
                });
            },
            dateErrors() {
                let errors = [];
                if (!this.job) {
                    return errors;
                }
                if (!this.materialArrivalDate){
                    errors.push('Need the date you think the material will arrive');
                    return errors;
                }
                let completionDate = this.$dayjs(this.targetCompletionDate);
                let arrivalDate = this.$dayjs(this.materialArrivalDate);
                if (completionDate.isBefore(arrivalDate)) {
                    errors.push('Completion date cannot be before arrival date.');
                }
                this.selectedTasks.forEach(task => {
                    let taskDate = this.$dayjs(task.startDate);
                    if (!(taskDate.isAfter(arrivalDate) || taskDate.isSame(arrivalDate, 'day')) || !(taskDate.isBefore(completionDate) || taskDate.isSame(completionDate, 'day'))) {
                        errors.push(`Task ${task.title} must be between the arrival and completion date.`);
                    }
                });
                return errors;
            },
            calendarJob() {
                if (this.job) {
                    return {
                        id: this.job.id,
                        name: this.job.name,
                        colours: this.job.jobColours.map(item => item.name).filter((value, index, self) => self.indexOf(value) === index),
                        jobTotal: this.job.jobSubTotal,
                        jobTasks: this.dateErrors.length == 0 ? this.selectedTasks : []
                    };
                } else {
                    return null;
                }
            },
        },
        watch: {
            job:{
                handler(){
                    if (this.job && this.processedJob != this.job.id){
                        this.loadJob();
                    }
                }
            }
        }
    };
</script>