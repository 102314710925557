import api from '../../helpers/api';
import entities from '../../constants/entities';
export const stock = {
    state: {
        stockLoading: false,
        stock: []
    },
    mutations: {
        setStock(state, stock) {
            state.stock = stock;
        },
        setStockLoading(state, loading) {
            state.stockLoading = loading;
        }
    },
    actions: {
        loadStock({ commit, dispatch }) {
            commit("setStockLoading", true);
            api.get(entities.Stock.ROUTE).then(res => {
                commit("setStockLoading", false);
                commit("setStock", res.data);
            })
            .catch(error => {
                commit("setStockLoading", false);
                dispatch("showErrorSnack", 'Cannot load stock');
            });
        },
    },
    getters: {
    }
}