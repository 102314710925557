export function capitalizeFirstLetter(string) {
    if (!string || string.length < 1) {
        return '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
}
export function truncate(str, n) {
    return (str.length > n) ? str.substr(0, n - 1) + '...' : str;
}
export function emailValidator(email) {
    if (!email || email.length == 0) {
        return true;
    }
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
export function hasNonAlphanumeric(str) {
    var regex = /[^a-zA-Z0-9]/;
    // Test the string against the regular expression
    return regex.test(str);
}

export function removeNull(str) {
    if (str == 'null' || str == null) {
        return '';
    } else {
        return str;
    }
}

export function firstWord(str) {
    if (!str || str.length < 1) {
        return str;
    }
    return str.split(" ")[0];
}