<template>
    <div>
        <v-dialog v-model="showSendMail"
                  fullscreen
                  :scrim="false">
            <v-toolbar dark
                       color="primary">
                <v-toolbar-title><v-icon icon="mdi-email-fast-outline"></v-icon> New Email</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon
                       dark
                       @click="showSendMail = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-overlay :model-value="loadingMail"
                       class="align-center justify-center">
                <v-progress-circular color="primary"
                                     indeterminate
                                     size="64"></v-progress-circular>
            </v-overlay>
            <v-card>
                <v-card-text>
                    <v-form v-model="valid" @submit.prevent="sendEmail" :disabled="loading || mailSending">
                        <v-select v-model="fromAddress"
                                  :items="fromAddresses"
                                  label="From Address"></v-select>
                        <v-combobox label="To"
                                    v-model="toAddresses"
                                    :items="addressOptions"
                                    :rules="[$rules.required]"
                                    chips
                                    multiple></v-combobox>
                        <v-row>
                            <v-col>
                                <v-combobox label="CC"
                                            v-model="ccAddresses"
                                            :items="addressOptions"
                                            chips
                                            multiple></v-combobox>
                            </v-col>
                            <v-col>
                                <v-combobox label="BCC"
                                            v-model="bccAddresses"
                                            :items="addressOptions"
                                            chips
                                            multiple></v-combobox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field v-model="subject" label="Subject" :rules="[$rules.required]"></v-text-field>
                            </v-col>
                            <v-col>
                                <v-select label="Email Template"
                                          v-model="messageTemplate"
                                          :items="messageTemplates"
                                          item-title="name"
                                          item-value="id"
                                          return-object></v-select>
                            </v-col>
                        </v-row>
                        <small>Body</small>
                        <email-body-editor v-model="body" :template="false"></email-body-editor>
                        <div class="mt-2 pt-2 pb-2 mb-2">
                            <v-btn :loading="loading || mailSending"
                                   type="submit"
                                   :disabled="!valid || mailSending"
                                   flat
                                   color="primary"
                                   prepend-icon="mdi-email-fast-outline">SEND</v-btn>
                            <v-btn flat class="ml-2" @click="showSendMail = false">CANCEL</v-btn>
                        </div>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<style scoped>
</style>
<script>
    import { mapActions, mapState } from "vuex";
    function initialState() {
        return {
            loading: false,
            valid: true,
            messageTemplate: null
        };
    }
    export default {
        name: "NewEmailDialog",
        data: function () {
            return initialState();
        },
        components: {
        },
        mounted: function () {
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack",
                "loadEmailTemplate",
                "sendEmail"
            ]),
            loadTemplate() {
                if (this.messageTemplate) {
                    this.subject = this.messageTemplate.subject;
                    this.body = this.messageTemplate.body;
                    this.messageTemplate = null;
                }
            }
        },
        computed: {
            ...mapState({
                loadingMail: (state) => state.mail.loadingMail,
                subject: (state) => state.mail.subject,
                addressOptions: (state) => state.mail.addressOptions,
                messageTemplates: (state) => state.mail.messageTemplates,
                fromAddresses: (state) => state.mail.fromAddresses,
                mailSending: (state) => state.mail.mailSending
            }),
            fromAddress: {
                get() {
                    return this.$store.state.mail.fromAddress
                },
                set(value) {
                    this.$store.commit('setFromAddress', value)
                }
            },
            toAddresses: {
                get() {
                    return this.$store.state.mail.toAddresses
                },
                set(value) {
                    this.$store.commit('setToAddress', value)
                }
            },
            ccAddresses: {
                get() {
                    return this.$store.state.mail.ccAddresses
                },
                set(value) {
                    this.$store.commit('setCCAddress', value)
                }
            },
            bccAddresses: {
                get() {
                    return this.$store.state.mail.bccAddresses
                },
                set(value) {
                    this.$store.commit('setBCCAddress', value)
                }
            },
            showSendMail: {
                get() {
                    return this.$store.state.mail.showSendMail
                },
                set(value) {
                    this.$store.commit('setSendMail', value)
                }
            },
            subject: {
                get() {
                    return this.$store.state.mail.subject
                },
                set(value) {
                    this.$store.commit('setSubject', value)
                }
            },
            body: {
                get() {
                    return this.$store.state.mail.body
                },
                set(value) {
                    this.$store.commit('setBody', value)
                }
            }
        },
        watch: {
            messageTemplate: {
                handler: function () {
                    this.loadTemplate();
                }
            }
        }
    };
</script>