<template>
    <div class="buttons">
        <div class="button-controls d-flex justify-space-between">
            <v-btn prepend-icon="mdi-printer" @click="printPage">Print</v-btn>
            <div class="w-25">
                <v-text-field label="Program Date"
                              type="date"
                              density="compact"
                              @change="loadData"
                              :hide-details="true"
                              v-model="date"></v-text-field>
            </div>
        </div>
    </div>
    <div class="book">
        <v-skeleton-loader v-if="loading" type="table"></v-skeleton-loader>
        <div v-if="!loading && data">
            <div v-for="(list, index) in data.lists" :key="list.name">
                <div v-if="list.jobs && list.jobs.length > 0" class="page">
                    <div class="subpage">
                        <div>
                            <div class="d-flex justify-space-between">
                                <div>
                                    <h2><small>{{ list.name }} Program -</small> {{ data.date }}</h2>
                                </div>
                                <div>
                                    <v-img height="50"
                                           width="50"
                                           style="background:black;"
                                           src="/assets/logo.png"></v-img>
                                </div>
                            </div>
                            <div class="mb-3">
                                <table v-if="index < 2" class="job-table">
                                    <thead class="header-repeat">
                                        <tr>
                                            <td colspan="5" class="no-border"></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th class="w-10">#</th>
                                            <th class="w-20">Customer</th>
                                            <th class="w-20">Colour</th>
                                            <th class="w-20">Cust#</th>
                                            <th class="w-10 text-right">Powder</th>
                                            <th class="w-10">Start Time</th>
                                            <th class="w-10">End Time</th>
                                        </tr>
                                        <tr v-for="job in list.jobs" :key="job.id" :class="getTagClass(job.jobTag)">
                                            <td>
                                                {{ job.jobName }} <entity-tag-chip :object="{entityType:'Job', jobTag:job.jobTag }"></entity-tag-chip>
                                            </td>
                                            <td><span v-if="job.contact">{{ job.contact.name }}</span></td>
                                            <td><span v-if="job.colours">{{ job.colours }}</span></td>
                                            <td>{{ job.customerNumber }}</td>
                                            <td class="text-right"><span v-if="job.jobColours.length == 1">{{ job.jobColours[0].powderRequired }}</span></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                    <tfoot class="footer-repeat">
                                        <tr>
                                            <td colspan="5" class="no-border"></td>
                                        </tr>
                                    </tfoot>
                                </table>
                                <table v-if="index > 1" class="job-table">
                                    <thead class="header-repeat">
                                        <tr>
                                            <td colspan="5" class="no-border"></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th class="w-10">#</th>
                                            <th class="w-20">Customer</th>
                                            <th class="w-20">Colour</th>
                                            <th class="w-20">Cust#</th>
                                            <th class="w-30">Comments</th>
                                        </tr>
                                        <tr v-for="job in list.jobs" :key="job.id" :class="getTagClass(job.jobTag)">
                                            <td>
                                                {{ job.jobName }}
                                                <entity-tag-chip :object="{entityType:'Job', jobTag:job.jobTag }"></entity-tag-chip>
                                            </td>
                                            <td><span v-if="job.contact">{{ job.contact.name }}</span></td>
                                            <td><span v-if="job.colours">{{ job.colours }}</span></td>
                                            <td>{{ job.customerNumber }}</td>
                                            <td>
                                                <v-textarea variant="plain"
                                                            v-model="job.items"
                                                            class="small-field"></v-textarea>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot class="footer-repeat">
                                        <tr>
                                            <td colspan="5" class="no-border"></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .buttons {
        width:100%;
        position: fixed;
        top: 0;
        background-color: #FAFAFA;
        border-bottom: 1px #D3D3D3 solid;
        z-index:10;
    }
    .button-controls {
        padding-top: 20px;
        width: 287mm;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 20px;
    }
    :deep(.v-textarea textarea) {
        font-size: 12px !important;
    }
    .job-table {
        border-collapse: collapse;
        width: 100%;
        margin-top: 10px;
        border: none !important;
    }
    .job-table, .job-table td, th {
        border: 1px #D3D3D3 solid;
        font-size: 12px;
        padding:3px;
    }
    body {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        background-color: #FAFAFA;
        font: 12pt "Tahoma";
    }

    * {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
    }

    .book {
        margin-top:80px;
    }

    .page {
        width: 287mm;
        min-height: 210mm;
        padding: 10mm;
        margin: 10mm auto;
        border: 1px #D3D3D3 solid;
        border-radius: 5px;
        background: white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }

    .subpage {
        padding: 1cm;
        width: 257mm;
        height: auto;
        position: relative;
        display: table;
        table-layout: fixed;
    }
    .footer-repeat, .header-repeat {
        display: none;
    }

    @page {
        size: A4;
        margin: 0;
    }

    @media print {
        .no-border {
            border: none !important;
            height: 5mm;
        }

        .footer-repeat {
            display: table-footer-group;
            border: none !important;
        }

        .header-repeat {
            display: table-header-group;
            border: none !important;
        }
        .bg-orange-lighten-4 {
            box-shadow: inset -1px -1px 100px 100px #ffe0b2;
        }
        .bg-orange-darken-1 {
            box-shadow: inset -1px -1px 100px 100px #fb8c00;
        }
        .bg-red-lighten-4 {
            box-shadow: inset -1px -1px 100px 100px #FFCDD2;
        }
        .bg-red-darken-1 {
            box-shadow: inset -1px -1px 100px 100px #E53935;
        }
        @page {
            size: landscape
        }
        html, body {
            height: 210mm;
            width: 297mm;
        }
        .book {
            margin-top: 0px;
        }
        .buttons {
            display:none;
        }

        :deep(.v-textarea textarea) {
            overflow: hidden;
        }

        .page {
            margin: 0;
            border: initial;
            border-radius: initial;
            width: initial;
            min-height: initial;
            box-shadow: initial;
            background: initial;
            page-break-after: always;
        }
    }
</style>
<script>
    import { mapActions, mapState } from "vuex";
    import { getTagClass } from '../mixins/jobMixins';
    import jobs from "../constants/jobs";
    function initialState() {
        return {
            saving: false,
            loading: false,
            data: null,
            date: null
        };
    }
    export default {
        name: "DayProgramView",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.date = this.$dayjs().format('YYYY-MM-DD');
            this.loadData();
        },
        props: {
        },
        methods: {
            getTagClass,
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            printPage() {
                window.print();
            },
            loadData() {
                this.loading = true;
                this.$api.get('dashboard/dayprogram?date='+this.date)
                    .then(res => {
                        this.data = res.data;
                        this.loading = false;
                    })
                    .catch(error => {
                        this.loading = false;
                        this.showErrorSnack('There was an issue getting the jobs');
                    });
            },
        },
        computed: {
            jobConstants() {
                return jobs;
            },
        }
    };
</script>