<template>
    <div class="pa-4">
        <v-row>
            <v-col v-for="report in reportDefinitions" md="4" :key="report.code" sm="12">
                <v-card class="my-3"
                        :title="report.title"
                        :subtitle="report.subtitle"
                        :to="'/reports/'+report.code"
                        link></v-card>
            </v-col>
        </v-row>
    </div>
</template>
<style scoped>

</style>
<script>
    import reports from '../constants/reports';
    import { mapGetters } from "vuex";
    function initialState() {
        return {
            saving: false,
            loading: false,
        };
    }
    export default {
        name: "ReportsOverviewView",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
        },
        methods: {
            reset: function () {
                Object.assign(this.$data, initialState());
            },
        },
        computed: {
            ...mapGetters(['hasAccess']),
            reportDefinitions() {
                let accessCheck = this.hasAccess;
                return reports.definitions.filter(item => {
                    return accessCheck(item.accessLevel);
                });
            }
        }
    };
</script>