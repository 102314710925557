<template>
    <div class="rgb-square" :style="{ 'background-color': rgbStyle }"></div>
</template>
<style scoped>
    .rgb-square {
        height: 50px;
        width: 50px;
        border: 1px solid lightgray;
        border-radius: 10px;
        margin:5px;
    }
</style>
<script>
    function initialState() {
        return {
            loading: false
        };
    }
    export default {
        name: "RgbSquare",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            red: Number,
            green: Number,
            blue: Number
        },
        methods: {
        },
        computed: {
            rgbStyle() {
                if (this.red != null && this.green != null && this.blue != null) {
                    return `rgba(${this.red}, ${this.green}, ${this.blue}, 1)`;
                }
                return '';
            }
        }
    };
</script>