export default {
    accessLevels: [
        {
            level: 10,
            icon: 'mdi-account',
            title: 'Standard',
        },
        {
            level: 12,
            icon: 'mdi-badge-account',
            title: 'Support',
        },
        {
            level: 15,
            icon: 'mdi-account-supervisor',
            title: 'Manager'
        },
        {
            level: 100,
            icon: 'mdi-ninja',
            title: 'Admin'
        },
    ]
}