export const jobs = {
    state: {
        showRescheduleJob: false,
        rescheduleJob: null
    },
    mutations: {
        setShowRescheduleJob(state, show) {
            state.showRescheduleJob = show;
        },
        setRescheduleJob(state, job) {
            state.rescheduleJob = job;
        }
    },
    actions: {
        rescheduleJob({ commit }, job) {
            commit('setShowRescheduleJob', true);
            commit('setRescheduleJob', job);
        },
    },
    getters: {
    }
}