<template>
    <v-table v-if="items || archived" class="small-table text-caption" density="compact">
        <thead>
            <tr>
                <th class="text-left">
                    Task
                </th>
                <th class="text-left">
                    Target Start
                </th>
                <th class="text-right">
                    End
                </th>
            </tr>
        </thead>
        <tbody v-if="items">
            <tr><td colspan="3" class="text-center task-header"><v-icon icon="mdi-calendar-check" class="mr-1"></v-icon> ACTIVE TASKS</td></tr>
            <tr v-for="task in items" :key="task.id" :class="{ 'current-task' : task.task == currentTask, 'complete-task' : task.endTime  }">
                <td class="text-left task-name">{{ task.name }}</td>
                <td :colspan="task.endTime ? 1 : 2" class="text-left">
                    <span v-if="task.targetStartDate">
                        {{ $dayjs(task.targetStartDate).format('DD-MM-YY') }}
                        <date-diff-chip v-if="task.task != 9 && !task.endTime && !task.running" :date="task.targetStartDate" :countdown="true"></date-diff-chip>
                        <v-chip prepend-icon="mdi-timer" variant="flat" size="x-small" v-if="task.running" color="orange" class="ml-1"><job-task-timer :task="task"></job-task-timer></v-chip>
                    </span>
                    <div v-html="jobTaskItemsDesc(task, true)"></div>
                </td>
                <td v-if="task.endTime" class="text-right">{{ $dayjs(task.endTime).format('DD-MM-YY') }}</td>
            </tr>
        </tbody>
        <tbody v-if="archived">
            <tr><td colspan="3" class="text-center task-header"><v-icon icon="mdi-archive" class="mr-1"></v-icon> ARCHIVED TASKS</td></tr>
            <tr v-for="task in archived" :key="task.id" class="complete-task">
                <td class="text-left task-name">{{ task.name }}</td>
                <td colspan="2" class="text-right">
                    {{ $dayjs(task.endTime).format('DD-MM-YY') }}
                    <div v-html="jobTaskItemsDesc(task, true)"></div>
                </td>
            </tr>
        </tbody>
    </v-table>
</template>
<style scoped>
    .complete-task .task-name {
        text-decoration: line-through;
    }
    .task-header {
        font-weight:bold;
    }
</style>
<script>
    import { jobTaskItemsDesc } from "../../mixins/jobMixins"
    function initialState() {
        return {
            loading: false
        };
    }
    export default {
        name: "JobTasksSmall",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            currentTask: Number,
            items: Array,
            archived: Array
        },
        methods: {
            jobTaskItemsDesc
        },
        computed: {
        }
    };
</script>