<template>
    <div>
        <v-btn-toggle v-model="toggle"
                      variant="outlined"
                      density="compact"
                      v-if="editor"
                      multiple
                      class="mb-2"
                      divided>
            <v-btn icon="mdi-format-bold" @click="editor.chain().focus().toggleBold().run()"
                   :disabled="!editor.can().chain().focus().toggleBold().run()"
                   :active="editor.isActive('bold')"></v-btn>
            <v-btn icon="mdi-format-italic"
                   @click="editor.chain().focus().toggleItalic().run()"
                   :disabled="!editor.can().chain().focus().toggleItalic().run()"
                   :active="editor.isActive('italic')"></v-btn>
            <v-btn icon="mdi-format-paragraph"
                   @click="editor.chain().focus().setParagraph().run()"
                   :active="editor.isActive('paragraph')"></v-btn>
            <v-btn icon="mdi-format-header-1"
                   @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
                   :active="editor.isActive('heading', { level: 1 })"></v-btn>
            <v-btn icon="mdi-format-header-2"
                   @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
                   :active="editor.isActive('heading', { level: 2 })"></v-btn>
            <v-btn icon="mdi-format-header-3"
                   @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
                   :active="editor.isActive('heading', { level: 3 })"></v-btn>
            <v-btn icon="mdi-format-header-4"
                   @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
                   :active="editor.isActive('heading', { level: 4 })"></v-btn>
            <v-btn icon="mdi-format-header-5"
                   @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
                   :active="editor.isActive('heading', { level: 5 })"></v-btn>
            <v-btn icon="mdi-format-list-bulleted"
                   @click="editor.chain().focus().toggleBulletList().run()"
                   :active="editor.isActive('bulletList')"></v-btn>
            <v-btn icon="mdi-format-list-numbered"
                   @click="editor.chain().focus().toggleOrderedList().run()"
                   :active="editor.isActive('orderedList')"></v-btn>
            <v-btn icon="mdi-undo"
                   @click="editor.chain().focus().undo().run()"
                   :disabled="!editor.can().chain().focus().undo().run()"></v-btn>
            <v-btn icon="mdi-redo"
                   @click="editor.chain().focus().redo().run()"
                   :disabled="!editor.can().chain().focus().redo().run()"></v-btn>
        </v-btn-toggle>
        <editor-content :editor="editor" class="email-editor" />
    </div>
</template>
<style>
    .email-editor {
        width: 100%;
        height: 350px;
        overflow-y: auto;
        padding: 16px;
        border-radius: 4px;
        border: thin #7A7A7A solid;
    }
    .email-editor > .tiptap {
        height: 100%;
    }
    .ProseMirror:focus {
        outline: none;
    }
    .swap {
        border: 1px solid #000;
        border-radius: 0.4rem;
        padding: 0.1rem 0.3rem;
        box-decoration-break: clone;
    }
</style>
<script>
    import StarterKit from '@tiptap/starter-kit'
    import Mention from '@tiptap/extension-mention'
    import Image from '@tiptap/extension-image'
    import Table from '@tiptap/extension-table'
    import TextStyle from '@tiptap/extension-text-style'
    import TableRow from '@tiptap/extension-table-row'
    import TableHeader from '@tiptap/extension-table-header'
    import TableCell from '@tiptap/extension-table-cell'
    import { Editor, EditorContent } from '@tiptap/vue-3'
    import suggestion from '../../helpers/mailTemplateSwaps'
    import { mapState, mapMutations } from "vuex";
    
    const CustomImage = Image.extend({
        addAttributes() {
            return {
                style: {
                    default: null,
                        renderHTML: attributes => {
                            return { style:  attributes.style }
                        }
                },
                src: {
                     default: null,
                        renderHTML: attributes => {
                            return { src:  attributes.src }
                        }
                }
            }
        }
    })

    const CustomTextStyle = TextStyle.extend({
        addAttributes() {
            return {
                class: {
                    default: null,
                        renderHTML: attributes => {
                            return { class:  attributes.class }
                        }
                },
            }
        }
    })

    export default {
      components: {
        EditorContent,
      },

      props: {
        modelValue: {
          type: String,
          default: '',
        },
        template:Boolean
      },

      emits: ['update:modelValue'],

      data() {
        return {
          editor: null,
          toggle: null
        }
      },
     methods: {
        ...mapMutations([
            "setTemplateInput"
        ]),
        getSwapValue(swapKey) {
            if (this.template){
                return '@'+swapKey;
            } else {
                if (this.messageSwaps && swapKey in this.messageSwaps) {
                    return this.messageSwaps[swapKey];
                }
                return swapKey;
            }
        },
      },
      computed: {
          ...mapState({
              messageSwaps: (state) => state.mail.messageSwaps,
              messageSignature: (state) => state.mail.messageSignature
          }),
      },
      watch: {
        modelValue(value) {
          // HTML
          const isSame = this.editor.getHTML() === value

          if (isSame) {
            return
          }

          this.editor.commands.setContent(value, false)
        },
      },

      mounted() {
          this.setTemplateInput(this.template);
          let swapFunc = this.getSwapValue;
          let isTemplate = this.template;
          this.editor = new Editor({
              extensions: [
                StarterKit,
                Mention.configure({
                    HTMLAttributes: {
                        class: 'swap',
                    },
                    renderHTML({ options, node }) {
                        return [
                            "span",
                            { class: isTemplate ? 'swap' : 'insert-swap', style: 'swap' },
                            swapFunc(node.attrs.label ?? node.attrs.id),
                        ];
                    },
                    suggestion,
                }),
                CustomImage.configure({
                    allowBase64: true,
                    inline: true,
                }),
                Table.configure({
                    HTMLAttributes: {
                        class: 'table',
                    },
                }),
                TableRow,
                TableHeader,
                TableCell,
                CustomTextStyle
              ],
              content: this.modelValue,
              onUpdate: () => {
                  // HTML
                  this.$emit('update:modelValue', this.editor.getHTML())
              },
          })
      },
      beforeUnmount() {
        this.editor.destroy()
      },
    }
</script>