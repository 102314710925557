import { shallowRef, ref, computed } from 'vue'

export default {
    ICON: 'mdi-account-group',
    TYPE: 'Staff',
    SINGULAR: 'Staff',
    PLURAL: 'Staff',
    ROUTE: 'staff/',
    DELETE: null,
    CREATE: null,
    UPDATE: null,
    FORM: null,
    DETAILPANEL: null,
    HASDETAIL: false,
    TABLEHEADERS: [
        { title: 'Name', key: 'name', width: "100%" },
    ],
    PANELS: [
    ]
}