require("es6-promise").polyfill()

import { createStore } from 'vuex'

// Import store
import { app } from "./modules/app";
import { jobs } from "./modules/jobs";
import { auth } from "./modules/auth";
import { entities } from "./modules/entities";
import { contactGroups } from "./modules/contactgroups";
import { colourGroups } from "./modules/colourgroups";
import { colours } from "./modules/colours";
import { products } from "./modules/products";
import { jobItemTemplates } from "./modules/jobItemTemplates";
import { notes } from "./modules/notes";
import { userNotifications } from "./modules/usernotifications"; 
import { mail } from "./modules/mail";
import { history } from "./modules/history";
import { stock } from "./modules/stock";

const store = createStore({
    modules: {
        app,
        jobs,
        auth,
        entities,
        contactGroups,
        colourGroups,
        colours,
        products,
        jobItemTemplates,
        notes,
        userNotifications,
        mail,
        history,
        stock
    }
})

export default store