import { createRouter, createWebHashHistory } from 'vue-router'
import NProgress from 'nprogress'

// Views 
import AppLayout from '../layouts/AppLayout';
import entities from '../constants/entities'

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '/customerjobs',
            name: 'customerjobs',
            component: () => import('../views/CustomerJobs'),
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('../views/LoginView'),
        },
        {
            path: '/dashboard/dayprogram',
            name: 'dayprogram',
            component: () => import('../views/DayProgramView'),
            meta: {
                accessLevel: 10
            },
        },
        {
            path: '/dashboard/powderreport',
            name: 'powderreport',
            component: () => import('../views/PowderReportView'),
            meta: {
                accessLevel: 10
            },
        },
        {
            path: '',
            component: AppLayout,
            children: [
                {
                    path: '',
                    name: 'dashboard',
                    component: () => import('../views/DashboardView'),
                    meta: {
                        accessLevel: 10
                    },
                },
                {
                    path: '/jobs',
                    name: 'jobs',
                    component: () => import('../views/JobsView'),
                    meta: {
                        accessLevel: 10
                    },
                },
                {
                    path: '/jobs/:id',
                    name: 'jobpanel',
                    component: () => import('../views/JobPanelView'),
                    props: true,
                    meta: {
                        accessLevel: 10,
                        showNotes: true,
                        showHistory: true,
                        breadcrumbs: {
                            icon: entities.Job.ICON,
                            items: [
                                {
                                    title: entities.Job.PLURAL,
                                    disabled: false,
                                    to: '/jobs',
                                },
                                {
                                    title: 'Name',
                                    disabled: true,
                                    to: '#',
                                },
                            ]
                        }
                    }
                },
                {
                    path: '/contacts',
                    name: 'contacts',
                    component: () => import('../views/ContactsView'),
                    meta: {
                        accessLevel: 10
                    },
                },
                {
                    path: '/contacts/:id',
                    name: 'contactpanel',
                    component: () => import('../views/ContactPanelView'),
                    props: true,
                    meta: {
                        accessLevel: 10,
                        showNotes: true,
                        showHistory: true,
                        breadcrumbs: {
                            icon: entities.Contact.ICON,
                            items: [
                                {
                                    title: entities.Contact.PLURAL,
                                    disabled: false,
                                    to: '/contacts',
                                },
                                {
                                    title: 'Name',
                                    disabled: true,
                                    to: '#',
                                },
                            ]
                        }
                    }
                },
                {
                    path: '/staff',
                    name: 'staff',
                    component: () => import('../views/StaffView'),
                    meta: {
                        accessLevel: 10
                    },
                },
                {
                    path: '/stock',
                    name: 'stock',
                    component: () => import('../views/StockView'),
                    meta: {
                        accessLevel: 10
                    },
                },
                {
                    path: '/products',
                    name: 'products',
                    component: () => import('../views/ProductsView'),
                    meta: {
                        accessLevel: 10
                    },
                },
                {
                    path: '/products/:id',
                    name: 'productspanel',
                    component: () => import('../views/ProductPanelView'),
                    props: true,
                    meta: {
                        accessLevel: 10,
                        showNotes: true,
                        showHistory: true,
                        breadcrumbs: {
                            icon: entities.Product.ICON,
                            items: [
                                {
                                    title: entities.Product.PLURAL,
                                    disabled: false,
                                    to: '/products',
                                },
                                {
                                    title: 'Name',
                                    disabled: true,
                                    to: '#',
                                },
                            ]
                        }
                    }
                },
                {
                    path: '/colours',
                    name: 'colours',
                    component: () => import('../views/ColoursView'),
                    meta: {
                        accessLevel: 10
                    },
                },
                {
                    path: '/colours/:id',
                    name: 'colourspanel',
                    component: () => import('../views/ColourPanelView'),
                    props: true,
                    meta: {
                        accessLevel: 10,
                        showNotes: true,
                        showHistory: true,
                        breadcrumbs: {
                            icon: entities.Colour.ICON,
                            items: [
                                {
                                    title: entities.Colour.PLURAL,
                                    disabled: false,
                                    to: '/colours',
                                },
                                {
                                    title: 'Name',
                                    disabled: true,
                                    to: '#',
                                },
                            ]
                        }
                    }
                },
                {
                    path: '/reports',
                    name: 'reports',
                    component: () => import('../views/ReportsOverviewView'),
                    meta: {
                        accessLevel: 10
                    },
                },
                {
                    path: '/reports/:id',
                    name: 'reportsrun',
                    props: true,
                    component: () => import('../views/ReportsRunView'),
                    meta: {
                        accessLevel: 10
                    },
                },
                {
                    path: '/settings',
                    name: 'settings',
                    props: true,
                    meta: {
                        accessLevel: 100
                    },
                    children: [
                        {
                            path: '',
                            name: 'settingsgeneral',
                            component: () => import('../views/settings/SettingsGeneralView'),
                            props: true,
                            meta: {
                                accessLevel: 100
                            },
                        },
                        {
                            path: 'users',
                            name: 'settingsusers',
                            component: () => import('../views/settings/SettingsUsersView'),
                            props: true,
                            meta: {
                                accessLevel: 100
                            },
                        },
                        {
                            path: 'colourgroups',
                            name: 'settingscolourgroups',
                            component: () => import('../views/settings/SettingsColourGroupsView'),
                            props: true,
                            meta: {
                                accessLevel: 100
                            },
                        },
                        {
                            path: 'contactgroups',
                            name: 'settingscontactgroups',
                            component: () => import('../views/settings/SettingsContactGroupsView'),
                            props: true,
                            meta: {
                                accessLevel: 100
                            },
                        },
                        {
                            path: 'jobitemtemplates',
                            name: 'settingsjobitemtemplates',
                            component: () => import('../views/settings/SettingsJobItemTemplateView'),
                            props: true,
                            meta: {
                                accessLevel: 100
                            },
                        },
                        {
                            path: 'messagetemplates',
                            name: 'settingsmessagetemplates',
                            component: () => import('../views/settings/SettingsMessageTemplatesView'),
                            props: true,
                            meta: {
                                accessLevel: 100
                            },
                        },
                        {
                            path: 'xero',
                            name: 'settingsxero',
                            component: () => import('../views/settings/SettingsXeroView'),
                            props: true,
                            meta: {
                                accessLevel: 100
                            },
                        },
                        {
                            path: 'smartsheet',
                            name: 'settingssmartsheet',
                            component: () => import('../views/settings/SettingsSmartsheetView'),
                            props: true,
                            meta: {
                                accessLevel: 100
                            },
                        }
                    ]
                }
            ]
        }
    ]
})

router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    if (to.name) {
        // Start the route progress bar.
        NProgress.start()
    }
    next();
})

router.afterEach((to, from, next) => {
    // Complete the animation of the route progress bar.
    NProgress.done()
})

import store from '../store/store.js'

router.beforeEach(async (to) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login', '/customerjobs'];
    const authRequired = !publicPages.includes(to.path);
    // Check role
    if (authRequired && !store.state.auth.user) {
        store.commit('setReturnUrl', to.fullPath);
        return '/login';
    }
    // Check accesslevel
    if (to.meta && to.meta.accessLevel && to.meta.accessLevel > 0) {
        if (!store.getters.hasAccess(to.meta.accessLevel)) {
            store.dispatch('showErrorSnack', 'Not authorised to view page');
            return '/';
        }
    }
})

export default router