<template>
    <div class="pt-2">
        <div class="v-progress-linear v-progress-linear--active v-theme--light v-locale--is-ltr" role="progressbar" aria-hidden="false" aria-valuemin="0" aria-valuemax="100" aria-valuenow="20" style="top: 0px; height: 25px; --v-progress-linear-height: 25px; left: 50%; transform: translateX(-50%);">
            <div class="v-progress-linear__determinate" :class="[ lineOneColour ]" :style="{ 'width' : lineOnePercentage+'%' }"><strong>{{ numberWithCommas(lineOnePercentage, 0) }}%</strong></div>
            <div class="v-progress-linear__determinate" :class="[ lineTwoColour ]" :style="{ 'width' : lineTwoPercentage+'%' }"><strong>{{ numberWithCommas(lineTwoPercentage, 0) }}%</strong></div>
        </div>
        <div class="d-inline-flex w-100">
            <div class="text-center" :style="{ 'width' : lineOnePercentage+'%' }"><span v-if="lineOnePercentage > 5">Line 1</span></div>
            <div class="text-center" :style="{ 'width' : lineTwoPercentage+'%' }"><span v-if="lineTwoPercentage > 5">Line 2</span></div>
        </div>
    </div>
</template>
<style scoped>
    .v-progress-linear {
        display:inline-flex;
    }
    .v-progress-linear__determinate {
        position:relative;
    }
</style>
<script>
    import { numberWithCommas } from "../mixins/numberMixins";
    function initialState() {
        return {
            loading: false
        };
    }
    export default {
        name: "LineProgressBar",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            lineOne: Number,
            lineTwo: Number,
            color: String
        },
        methods: {
            numberWithCommas
        },
        computed: {
            lineOneColour() {
                return 'bg-' + this.color + '-darken-3';
            },
            lineTwoColour() {
                return 'bg-' + this.color + '-lighten-3';
            },
            lineTotal() {
                return this.lineOne + this.lineTwo;
            },
            lineOnePercentage() {
                if (this.lineTotal > 0){
                    return (this.lineOne / this.lineTotal) * 100;
                } else {
                    return 50;
                }
            },
            lineTwoPercentage() {
                if (this.lineTotal > 0){
                    return (this.lineTwo / this.lineTotal) * 100;
                } else {
                    return 50;
                }
            }
        }
    };
</script>