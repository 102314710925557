<template>
    <v-sheet v-if="nextTask" 
             rounded 
             class="pa-4 previous-task-sheet current-task">
        <div class="w-100 mb-2 pb-2">
            <small><v-icon icon="mdi-page-next"></v-icon> CURRENT</small>
            <h4>{{ nextTask.name }}</h4>
        </div>
        <div class="mb-3">
            <div v-if="nextTask.targetStartDate">
                <small>TARGET START DATE</small>
                <div>{{ $dayjs(nextTask.targetStartDate).format('DD-MM-YY') }}
                    <date-diff-chip v-if="nextTask.task != 9 && !nextTask.endTime && !nextTask.running" :date="nextTask.targetStartDate" :countdown="true"></date-diff-chip>
                    <v-chip prepend-icon="mdi-timer" variant="flat" size="x-small" v-if="nextTask.running" color="orange" class="ml-1"><job-task-timer :task="nextTask"></job-task-timer></v-chip>
                </div>
            </div>
        </div>
    </v-sheet>
</template>
<style scoped>
</style>
<script>
    function initialState() {
        return {
            loading: false
        };
    }
    export default {
        name: "JobNextTask",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            nextTask: Object
        },
        methods: {
        },
        computed: {
        }
    };
</script>