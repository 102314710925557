<template>
    <v-btn prepend-icon="mdi-calendar-sync-outline" 
           @click="rescheduleJob(job)" 
           :size="size" 
           :block="block"
           variant="flat" 
           :disabled="job.currentTask && job.currentTask.running"
           color="orange-lighten-2">
        Reschedule Job
    </v-btn>
</template>
<style scoped>

</style>
<script>
    function initialState() {
        return {
            loading: false
        };
    }
    import { mapActions } from "vuex";
    export default {
        name: "RescheduleJobButton",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            size: String,
            block: Boolean,
            job: Object
        },
        methods: {
            ...mapActions([
                'rescheduleJob'
            ])
        },
        computed: {
            showRescheduleJob: {
                get() {
                    return this.$store.state.jobs.showRescheduleJob
                },
                set(value) {
                    this.$store.commit('setShowRescheduleJob', value)
                }
            }
        }
    };
</script>