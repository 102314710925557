<template>
    <div>
        <v-row>
            <v-col>
                <strong class="panel-section-header mt-0">
                    Details
                </strong>
                <v-table density="compact" class="text-caption">
                    <tbody>
                        <tr align="right">
                            <th class="w-50"></th>
                            <td><rgb-square v-bind="entityRecord"></rgb-square></td>
                        </tr>
                        <tr align="right">
                            <th>Code</th>
                            <td>{{ entityRecord.code }}</td>
                        </tr>
                        <tr align="right">
                            <th>Brand</th>
                            <td>{{ entityRecord.brand }}</td>
                        </tr>
                        <tr align="right">
                            <th>Range</th>
                            <td>{{ entityRecord.range }}</td>
                        </tr>
                        <tr align="right">
                            <th>Finish</th>
                            <td>{{ entityRecord.finishTypeName }}</td>
                        </tr>
                        <tr v-if="entityRecord.colourGroup" align="right">
                            <th>Group</th>
                            <td>{{ entityRecord.colourGroup.name }}</td>
                        </tr>
                    </tbody>
                </v-table>
            </v-col>
        </v-row>
    </div>
</template>
<style scoped>
</style>
<script>
    import { mapActions, mapState, mapGetters } from "vuex";
    function initialState() {
        return {
            saving: false,
            loading: false
        };
    }
    export default {
        name: "ColourDetailsPanel",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
        },
        computed: {
            ...mapState({
                entityRecord: (state) => state.entities.entityRecord,
                entityRecordLoading: (state) => state.entities.entityRecordLoading
            }),
        }
    };
</script>