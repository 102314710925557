<template>
    <span v-html="runningTime"></span>
</template>
<style scoped>

</style>
<script>
    function initialState() {
        return {
            loading: false,
            timer: null,
            seconds: 0,
            minutes: 0,
            hours: 0
        };
    }
    export default {
        name: "JobTaskTimer",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.timer = setInterval(() => {
                this.updateTimer();
            }, 1000)
        },
        props: {
            task: Object
        },
        methods: {
            updateTimer() {
                // Calculate the time difference between now and the start date
                var timeDiff = this.$dayjs().diff(this.$dayjs(this.task.startTime));
                // Convert the time difference to seconds, minutes, and hours
                this.seconds = Math.floor((timeDiff / 1000) % 60);
                this.minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
                this.hours = Math.floor((timeDiff / (1000 * 60 * 60)));
            }
        },
        computed: {
            runningTime() {
                return this.hours.toString().padStart(2, "0")+":"+this.minutes.toString().padStart(2, "0") + ":" + this.seconds.toString().padStart(2, "0");
            }
        }
    };
</script>