<template>
    <div>
        <v-row>
            <v-col :md="taskDetails.showColours ? 9 : 12">
                <v-table v-if="jobsList.length > 0">
                    <thead>
                        <tr>
                            <th class="w-5">#</th>
                            <th class="w-15">Job</th>
                            <th class="w-5">Status</th>
                            <th class="w-10">Customer</th>
                            <th class="w-20">Cust#</th>
                            <th class="w-20">Colour</th>
                            <th class="w-25"></th>
                        </tr>
                    </thead>
                    <tbody v-for="(job, index) in filteredJobsList" :key="job.id">
                        <tr class="job-row" @click="expandJob(job)" :class="{'expanded-job': job.expand, 'job-warning': job.status < taskDetails.id, 'job-complete':job.status > taskDetails.id }">
                            <td>{{ job.currentTask.dayOrder }}</td>
                            <td>
                                <job-link :job="job" :search-term="searchTerm"></job-link>
                            </td>
                            <td><type-chip :type="job.status" entity="Job"></type-chip></td>
                            <td><contact-link :contact="job.contact" :search-term="searchTerm"></contact-link></td>
                            <td><span v-html="formatSearchName(job.customerNumberFull, searchTerm)"></span></td>
                            <td><span v-html="formatSearchName(job.colours, searchTerm)"></span></td>
                            <td class="text-right">
                                <job-task-buttons v-if="job.status == taskDetails.id" v-model:job="jobsList[index]" :task="taskDetails"></job-task-buttons>
                                <v-chip v-if="job.status > taskDetails.id" color="success">
                                    <v-icon icon="mdi-check-circle" start></v-icon>
                                    Complete
                                </v-chip>
                            </td>
                        </tr>
                        <tr v-if="job.expand">
                            <td colspan="7" class="ma-1 expand-cell">
                                
                                <v-alert icon="mdi-alert-octagon-outline"
                                            class="mt-1"
                                            v-if="job.status < taskDetails.id"
                                            type="warning">
                                    Job is not at the correct status to process
                                </v-alert>
                                <v-row>
                                    <v-col md="6">
                                        <v-sheet border
                                                    class="mx-1 my-3 pa-1"
                                                    rounded>
                                            <h4>Items</h4>
                                            <div v-if="job.status == taskDetails.id && job.currentTask.jobItemTasks && taskItemLines(job).length > 0">
                                                <v-alert icon="mdi-alert-octagon-outline"
                                                         class="mt-1"
                                                         type="warning">
                                                    Only certain items have been selected for this task
                                                    <div><small>Please only complete the items listed below</small></div>
                                                    <v-table>
                                                        <thead>
                                                            <tr>
                                                                <th class="text-left">
                                                                    Product
                                                                </th>
                                                                <th class="text-right">
                                                                    Qty
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="jobTaskItem in taskItemLines(job)" :key="jobTaskItem.id">
                                                                <td class="text-left">{{ jobTaskItem.itemDescription }}</td>
                                                                <td class="text-right">{{ numberWithCommas(jobTaskItem.quantity, 2) }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </v-table>
                                                </v-alert>
                                            </div>
                                            <v-divider class="my-1"></v-divider>
                                            <job-items-small :items="job.jobItems"></job-items-small>
                                        </v-sheet>
                                    </v-col>
                                    <v-col md="6">
                                        <v-sheet v-if="taskDetails.showColour" border
                                                 class="ma-1 pa-1"
                                                 rounded>
                                            <h4>Powder</h4>
                                            <v-divider class="my-1"></v-divider>
                                            <v-table density="compact">
                                                <thead>
                                                    <tr>
                                                        <th class="text-left w-80">
                                                            Colour
                                                        </th>
                                                        <th class="text-right w-20">
                                                            Est. KG
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="jobColour in job.jobColours" :key="jobColour.colour">
                                                        <td class="text-left">{{ jobColour.colour }}</td>
                                                        <td class="text-right">{{ numberWithCommas(jobColour.powderRequired, 2) }}</td>
                                                    </tr>
                                                </tbody>
                                            </v-table>
                                        </v-sheet>
                                        <v-sheet border
                                                 class="ma-1 pa-1"
                                                 rounded>
                                            <h4>Completion Details</h4>
                                            <v-divider class="my-1"></v-divider>
                                            <div>Received <date-ago-display :date="job.dateReceived" :days-ago="job.daysSinceReceived" :countdown="false"></date-ago-display></div>
                                            <div v-if="job.dateTargetCompletion && !job.dateCompleted">
                                                Target Completion Date {{ $dayjs(job.dateTargetCompletion).format('DD-MM-YYYY') }}
                                                <date-diff-chip :date="job.dateTargetCompletion" :countdown="true"></date-diff-chip>
                                            </div>
                                            <div v-if="job.previousTask">
                                                <div class="mb-3">
                                                    {{ job.previousTask.name }} was completed {{ $dayjs(job.previousTask.endTime).format('DD/MM/YYYY HH:MM') }}.
                                                </div>
                                                <div v-if="job.previousTask.assignedLocation">
                                                    <h6 class="border-b-sm">Location </h6> {{ job.previousTask.assignedLocation }}
                                                </div>
                                            </div>
                                        </v-sheet>
                                    </v-col>
                                </v-row>
                            </td>
                        </tr>
                    </tbody>
                </v-table>
            </v-col>
            <v-col v-if="taskDetails.showColours" md="3">
                <v-card color="amber-lighten-4" variant="flat" class="text-center">
                    <template v-slot:subtitle>
                        POWDER EST.
                    </template>
                    <template v-slot:text>
                        <v-table density="compact">
                            <thead>
                                <tr>
                                    <th class="text-left w-80">
                                        Colour
                                    </th>
                                    <th class="text-right w-20">
                                        Est. KG
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="jobColour in jobColourTotal" :key="jobColour.colour">
                                    <td class="text-left">{{ jobColour.colour }}</td>
                                    <td class="text-right">{{ numberWithCommas(jobColour.powder, 2) }}</td>
                                </tr>
                            </tbody>
                        </v-table>
                    </template>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import { mapActions } from "vuex";
    import { numberWithCommas } from "../../mixins/numberMixins";
    import { formatSearchName } from "../../mixins/searchMixins";
    function initialState() {
        return {
            jobsList: []
        };
    }
    export default {
        name: "TaskTimetableTable",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.jobsList = this.tableJobs;
        },
        props: {
            taskDetails: Object,
            tableJobs: Array,
            searchTerm: String
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack",
            ]),
            numberWithCommas,
            formatSearchName,
            expandJob(job) {
                if (job.expand) {
                    job.expand = false;
                } else {
                    job.expand = true;
                }
                this.jobsList.forEach(otherJob => {
                    if (otherJob.id != job.id) {
                        otherJob.expand = false;
                    }
                });
            },
            taskItemLines(job) {
                return job.currentTask.jobItemTasks.filter(item => item.quantity != 0);
            }
        },
        computed: {
            jobColourTotal() {
                if (!this.jobsList || this.jobsList.length == 0) {
                    return 0;
                }
                let result = [];
                this.jobsList.forEach(item => {
                    item.jobColours.forEach(jobColour => {
                        let index = result.findIndex(obj => obj.name === jobColour.name);
                        if (index < 0) {
                            result.push({ colour: jobColour.colour, powder: jobColour.powderRequired })
                        } else {
                            result[index].powder += jobColour.powderRequired;
                        }
                    });
                });
                return result.sort((a, b) => b.powder - a.powder);
            },
            filteredJobsList() {
                if (this.jobsList) {
                    if (this.searchTerm && this.searchTerm.length > 0) {
                        let upperSearch = this.searchTerm.toUpperCase();
                        return this.jobsList.filter(item => {
                            return item.customerNumberFull.toUpperCase().includes(upperSearch) || item.name.toUpperCase().includes(upperSearch) || item.colours.toUpperCase().includes(upperSearch) ||
                            item.contact.name.toUpperCase().includes(upperSearch);
                        });
                    }
                    return this.jobsList;
                }
                return [];
            }
        },
        watch: {
        }
    };
</script>
<style scoped>
    .job-row {
        cursor: pointer;
    }
    .expand-cell {
        background-color: #f5f5f5;
    }
    .expanded-job td {
        font-weight: bold;
        background-color: #BBDEFB;
    }

    .job-row:hover td {
        background-color: #BBDEFB;
    }

    .job-warning td {
        background-color: #FFECB3;
    }

    .job-complete td {
        background-color: #f5f7fa;
        text-decoration: line-through;
    }
</style>