<template>
    <v-main>
        <div class="d-flex align-center justify-center" style="height: 100vh">
            <div>
                <v-card class="mx-auto"
                        elevation="1"
                        min-width="500"
                        rounded="lg">
                    <v-img height="200"
                           style="background:black;"
                           src="/assets/logo.png"></v-img>
                    <v-card-text>
                        <v-form fast-fail>
                            <v-text-field variant="underlined"
                                          v-model="email"
                                          label="Email"
                                          :rules="emailRules"
                                          v-on:keyup.enter="login({email, password})"
                                          required></v-text-field>
                            <v-text-field variant="underlined"
                                          type="password"
                                          label="Password"
                                          v-model="password"
                                          v-on:keyup.enter="login({email, password})"
                                          required></v-text-field>

                            <v-btn @click="login({email, password})" color="primary" block class="mt-2">Sign in</v-btn>
                        </v-form>
                    </v-card-text>
                </v-card>
                <v-alert v-if="loginError" :title="loginError.title" :text="loginError.message" :type="loginError.type" class="my-2 text-left"></v-alert>
            </div>
        </div>
    </v-main>
</template>
<style>
    
</style>
<script>
    import { mapActions, mapState } from "vuex";
    function initialState() {
        return {
            saving: false,
            loading: false,
            email: "",
            password: "",
            visible: false,
            emailRules: [
                value => {
                    if (value) return true

                    return 'E-mail is requred.'
                },
                value => {
                    if (/.+@.+\..+/.test(value)) return true

                    return 'E-mail must be valid.'
                },
            ],
        };
    }
    export default {
        name: "LoginView",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
        },
        methods: {
            ...mapActions([
                "login",
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
        },
        computed: {
            ...mapState({
                loginError: (state) => state.auth.loginError,
            })
        }
    };
</script>