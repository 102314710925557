<template>
    <v-sheet
             v-if="previousTask" 
             rounded 
             class="pa-4 previous-task-sheet complete-task">
        <div class="w-100 mb-2 pb-2">
            <small><v-icon icon="mdi-page-previous"></v-icon> PREVIOUS</small>
            <h4>{{ previousTask.name }}</h4>
        </div>
        <div class="mb-3">
            Completed {{ $dayjs(previousTask.endTime).format('DD/MM/YYYY HH:MM') }} by {{ previousTask.endedBy }}.
        </div>
        <div v-if="previousTask.assignedLocation">
            <h6 class="border-b-sm">Location </h6> {{ previousTask.assignedLocation }}
        </div>
    </v-sheet>
</template>
<style scoped>
</style>
<script>
    function initialState() {
        return {
            loading: false
        };
    }
    export default {
        name: "JobPreviousTask",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            previousTask: Object
        },
        methods: {
        },
        computed: {
        }
    };
</script>