<template>
    <div>
        <div v-bind="getRootProps()" class="vue-dropzone" :class="{'is-dropping':isDragActive}">
            <input v-bind="getInputProps()" />
            <p class="dz-message">Drop files here ...</p>
        </div>
        <v-skeleton-loader v-if="loading"
                           type="table">
        </v-skeleton-loader>
        <v-table v-if="!loading" density="compact" class="text-caption">
            <tbody>
                <tr>
                    <th class="w-40">File</th>
                    <th class="w-10">Created</th>
                    <th class="w-20">By</th>
                    <th class="w-10 text-right"></th>
                </tr>
                <tr v-for="file in files" :key="file.id">
                    <td><a :href="file.fileLink" target="_blank">{{ file.fileName }}</a> <div v-if="file.tag"><small>{{ file.tag }}</small></div></td>
                    <td>{{ file.dateCreated }}</td>
                    <td>{{ firstWord(file.createdBy) }}</td>
                    <td class="text-right">
                        <v-btn icon="mdi-trash-can-outline"
                               @click="showConfirmDelete(file)"
                               size="x-small"></v-btn>
                    </td>
                </tr>
            </tbody>
        </v-table>
        <v-dialog v-model="confirmDelete"
                  width="auto">
            <v-card>
                <v-card-text v-if="deleteFile">
                    Are you sure you want to delete this file?
                </v-card-text>
                <v-card-actions>
                    <v-btn :loading="deleting"
                           color="primary"
                           prepend-icon="mdi-trash-can-outline"
                           @click="confirmDeleteItem"
                           dark>
                        DELETE
                    </v-btn>
                    <v-btn dark @click="confirmDelete = false" :disabled="deleting">
                        CANCEL
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<style scoped>
    .vue-dropzone {
        border-radius: 0.25rem;
        background: rgba(0,0,0,.03);
        border: 1px solid rgba(0,0,0,.03);
        padding:20px 20px;
        min-height:150px;
    }
    .is-dropping {
        background: #BBDEFB;
        border: #1E88E5;
    }
    .dz-message {
        text-align: center;
        margin: 2em 0;
        cursor: pointer;
    }
</style>
<script>
    import { useDropzone } from "vue3-dropzone";
    import { mapActions, mapState } from "vuex";
    import { getCurrentInstance, ref } from "vue";
    import { firstWord } from '../../mixins/stringMixins';
    export default {
        name: "FilesPanel",
        data: function () {
            return {
                loading: false,
                files: [],
                deleteFile: null,
                confirmDelete: false,
                deleting: false
            }
        },
        setup() {
            const { proxy } = getCurrentInstance();
            function onDrop(acceptFiles, rejectReasons) {
                const formData = new FormData(); // pass data as a form
                for (var x = 0; x < acceptFiles.length; x++) {
                    formData.append("Files[]", acceptFiles[x]);
                }
                formData.append('Type', '0');
                formData.append('SaveName', 'Upload');
                formData.append('FileName', 'Upload');
                if (proxy.entity.TYPE == "Job") {
                    formData.append('JobId', proxy.object.id);
                }
                if (proxy.tag) {
                    formData.append('Tag', proxy.tag);
                }
                proxy.$api.post("/systemfiles/", formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then((response) => {
                        proxy.showSuccessSnack(acceptFiles.length > 1 ? "Files uploaded" : "File uploaded");
                        proxy.loadFiles();
                    })
                    .catch((err) => {
                        proxy.showErrorSnack("There was an error uploading the file");
                    });
            }
            const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });
            return {
                getRootProps,
                getInputProps,
                ...rest,
            };
        },
        props: {
            object: Object,
            entity: Object,
            tag: String
        },
        mounted: function () {
            this.loading = true;
            this.loadFiles();
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack"
            ]),
            firstWord,
            loadFiles() {
                let route = 'SystemFiles/?';
                if (this.entity.TYPE == "Job") {

                    route += "jobId=" + this.object.id;
                }
                if (this.tag) {
                    route += '&tag=' + this.tag;
                }
                this.$api.get(route)
                    .then(res => {
                        this.loading = false;
                        this.files = res.data;
                    }).catch(error => {
                        this.showErrorSnack("There was an issue getting the files.")
                    });
            },
            showConfirmDelete(file) {
                this.deleteFile = file;
                this.confirmDelete = true;
            },
            confirmDeleteItem() {
                this.deleting = true;
                this.$api.delete('SystemFiles/' + this.deleteFile.id)
                    .then(res => {
                        const index = this.files.findIndex(obj => obj.id === this.deleteFile.id);
                        this.files.splice(index, 1);
                        this.confirmDelete = false;
                        this.deleteFile = null;
                        this.deleting = false;
                        this.showSuccessSnack("Removed!");
                    }).catch(error => {
                        this.deleting = false;
                        this.showErrorSnack("Whoops. We ran into an error.");
                    });
            },
        },
        computed: {
            ...mapState({
                user: (state) => state.auth.user,
            }),
        }
    };
</script>