
const RATE_PRICING = 1;
const RATE_MULTI = 2;
const RATE_LENGTH = 3;
const RATE_DEPTH = 4;
const RATE_HEIGHT = 5;
const RATE_WIDTH = 6;
const RATE_PERI = 7;
const RATE_GAUGE = 8;
const RATE_GENLENPER = 9;
const RATE_GENUNITSQM = 10;

export default {
    RATE_PRICING,
    RATE_MULTI,
    RATE_LENGTH,
    RATE_DEPTH,
    RATE_HEIGHT,
    RATE_WIDTH,
    RATE_PERI,
    RATE_GAUGE,
    RATE_GENLENPER,
    RATE_GENUNITSQM,
    types: {
        1: {
            id: RATE_PRICING,
            title: 'Pricing',
            hint: 'This is the pricing figure applied for this item',
            prefix: '$',
            suffix: ''
        },
        2: {
            id: RATE_MULTI,
            title: 'Pricing Multiplier',
            hint: 'This is the multiplier applied to pricing calculation methods',
            prefix: '',
            suffix: 'x'
        },
        3: {
            id: RATE_LENGTH,
            title: 'Length',
            hint: 'This is the products length',
            prefix: '',
            suffix: 'MM'
        },
        4: {
            id: RATE_DEPTH,
            title: 'Depth',
            hint: 'This is the products depth',
            prefix: '',
            suffix: 'MM'
        },
        5: {
            id: RATE_HEIGHT,
            title: 'Height',
            hint: 'This is the products height',
            prefix: '',
            suffix: 'MM'
        },
        6: {
            id: RATE_WIDTH,
            title: 'Width',
            hint: 'This is the products width',
            prefix: '',
            suffix: 'MM'
        },
        7: {
            id: RATE_PERI,
            title: 'Periphery',
            hint: 'This is the products periphery per lineal meter',
            prefix: '',
            suffix: 'MM'
        },
        8: {
            id: RATE_GAUGE,
            title: 'Gauge Thickness',
            hint: 'This is the products gauge thickness',
            prefix: '',
            suffix: 'Gauge'
        },
        9: {
            id: RATE_GENLENPER,
            title: 'Length Only UOM Periphery',
            hint: 'This is the periphery applied to all products with Length Only UOM',
            prefix: '',
            suffix: 'MM'
        },
        10: {
            id: RATE_GENUNITSQM,
            title: 'Unit Only Total Square Meter Periphery',
            hint: 'This is the total square meter periphery applier to all products with Unit Only UOM',
            prefix: '',
            suffix: 'M2'
        },
    }
}