import { shallowRef, ref, computed } from 'vue'

export default {
    ICON: 'mdi-invoice-list-outline',
    TYPE: 'Invoice',
    SINGULAR: 'Invoice',
    PLURAL: 'Invoices',
    ROUTE: 'invoices/',
    DELETE: 'invoices/',
    CREATE: 'invoices/',
    UPDATE: 'invoices/',
    FORM: null,
    DETAILPANEL: null,
    HASDETAIL: false,
    TABLEHEADERS: [],
    PANELS: []
}