import { shallowRef, ref, computed } from 'vue'
import ColourGroupForm from '../../forms/ColourGroupForm.vue'

export default {
    ICON: 'mdi-palette-swatch-outline',
    TYPE: 'ColourGroup',
    SINGULAR: 'Colour Group',
    PLURAL: 'Colour Groups',
    ROUTE: 'colourgroups/',
    DELETE: 'colourgroups/',
    CREATE: 'colourgroups/create',
    UPDATE: 'colourgroups/',
    FORM: shallowRef(ColourGroupForm),
    HASDETAIL: false,
    TABLEHEADERS: [
        { title: 'Name', key: 'name' },
        { title: 'Multiplier', key: 'rate' },
        { title: '', key: 'controls' },
    ]
}