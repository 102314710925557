<template>
    <div>
        <v-dialog v-model="showEntityForm"
                  fullscreen
                  persistent
                  :scrim="false">
            <v-card>
                <v-toolbar dark
                           color="primary">
                    <v-toolbar-title v-if="editEntity"><v-icon icon="mdi-pencil-outline"></v-icon> Editing {{ editEntity.entityString }}</v-toolbar-title>
                    <v-toolbar-title v-if="!editEntity"><v-icon icon="mdi-plus"></v-icon> Creating a {{ entity.SINGULAR }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon
                           dark
                           @click="showEntityForm = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <component :is="entity.FORM"></component>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<style scoped>
</style>
<script>
    import { mapActions, mapState, mapGetters } from "vuex";
    function initialState() {
        return {
            saving: false,
            loading: false
        };
    }
    export default {
        name: "EditEntityDialog",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
        },
        computed: {
            ...mapState({
                entity: (state) => state.entities.entity,
                editEntity: (state) => state.entities.editEntity
            }),
            showEntityForm: {
                get() {
                    return this.$store.state.entities.showEntityForm
                },
                set(value) {
                    this.$store.commit('setShowEntityForm', value)
                }
            }
        }
    };
</script>