<template>
    <v-chip variant="flat" :color="statusColour" size="x-small" flat>
        <v-icon start :icon="icon"></v-icon>
        {{ status }}
    </v-chip>
</template>
<style scoped>

</style>
<script>
    function initialState() {
        return {
            loading: false
        };
    }
    export default {
        name: "InvoiceStatus",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            status: String
        },
        methods: {
        },
        computed: {
            icon() {
                switch (this.status) {
                    case "Submitted":
                        return "mdi-open-in-app";
                    case "Voided":
                    case "Deleted":
                        return "mdi-cancel";
                    case "Authorised":
                        return "mdi-account-multiple-check-outline";
                    case "Paid":
                        return "mdi-check-circle-outline";
                    default:
                        return "mdi-moon-new";
                }
            },
            statusColour() {
                switch (this.status) {
                    case "Submitted":
                        return "blue-darken-1";
                    case "Voided":
                    case "Deleted":
                        return "red-darken-1";
                    case "Authorised":
                        return "teal-darken-1";
                    case "Paid":
                        return "green-darken-1";
                    default:
                        return "blue-grey-lighten-1";
                }
            }
        }
    };
</script>