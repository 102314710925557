<template>
    <div>
        <v-menu>
            <template v-slot:activator="{ props }">
                <v-btn :icon="anyUnread ? 'mdi-bell-badge-outline' : 'mdi-bell-outline'"
                       :class="{'text-yellow-lighten-3' : anyUnread }"
                       class="mx-3"
                       v-bind="props">
                </v-btn>
            </template>
            <v-list class="notification-menu" slim>
                <v-list-item class="notification-header text-grey-darken-3"><v-icon icon="mdi-bell-circle-outline"></v-icon> NOTIFICATIONS</v-list-item>
                <v-divider></v-divider>
                <v-skeleton-loader :loading="notificationsLoading" type="list-item-avatar, list-item-avatar, list-item-avatar">
                    <v-list-item v-for="(item, index) in notifications"
                                 :key="index"
                                 @click="viewNote(item)"
                                 :class="{ 'bg-yellow-lighten-3' : !item.viewedAt }"
                                 :value="index">
                        <template v-slot:prepend>
                            <v-icon :icon="!item.viewedAt ? 'mdi-message-badge-outline' : 'mdi-message-check-outline'"></v-icon>
                        </template>
                        <v-list-item-title>
                            <div class="small-text font-weight-bold">{{ item.description }}</div>
                            <div class="text-grey-darken-2 small-text">{{ item.user }} at {{ item.dateCreated }}</div>
                        </v-list-item-title>
                    </v-list-item>
                </v-skeleton-loader>
            </v-list>
        </v-menu>
        <v-snackbar v-model="showNewNotification"
                    vertical>
            <div class="text-subtitle-1 pb-2"><v-icon icon="mdi-bell-circle-outline"></v-icon> New Notification Received</div>

            <v-list class="notification-menu" slim>
                <v-list-item @click="viewNote(newNotification)">
                    <template v-slot:prepend>
                        <v-icon :icon="!newNotification.viewedAt ? 'mdi-message-badge-outline' : 'mdi-message-check-outline'"></v-icon>
                    </template>
                    <v-list-item-title>
                        <div class="small-text font-weight-bold">{{ newNotification.description }}</div>
                        <div class="text-grey-darken-2 small-text">{{ newNotification.user }} at {{ newNotification.dateCreated }}</div>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
            <template v-slot:actions>
                <v-btn color="indigo"
                       variant="text"
                       @click="showNewNotification = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
<style scoped>
    .notification-menu {
        font-size:12px;
        max-width:300px;
    }
    .notification-menu .small-text {
        font-size: 12px !important;
        line-height: 1rem;
    }
    .notification-header {
        text-align: center;
        font-weight: bold;
    }
    .notification-header a {
        text-decoration: none;
    }
</style>
<script>
    function initialState() {
        return {
            loading: false,
        };
    }
    import { mapActions, mapState, mapGetters } from "vuex";
    export default {
        name: "NotificationsBar",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.initNotifications();
        },
        props: {
        },
        methods: {
            ...mapActions([
                "initNotifications",
                "markNotificationViewed"
            ]),
            viewNote(notification) {
                this.markNotificationViewed(notification.id);
                let route = '/' + notification.link;
                if (notification.noteId) {
                    route += "?noteid=" + notification.noteId;
                }
                this.$router.push(route);
            }
        },
        computed: {
            ...mapState({
                notificationsLoading: (state) => state.userNotifications.entity,
                notifications: (state) => state.userNotifications.notifications,
                newNotification: (state) => state.userNotifications.newNotification,
            }),
            anyUnread() {
                if (this.notifications) {
                    return this.notifications.filter(item => {
                        return !item.viewedAt;
                    }).length > 0;
                }
                return false;
            },
            showNewNotification: {
                get() {
                    return this.$store.state.userNotifications.showNewNotification
                },
                set(value) {
                    this.$store.commit('setShowNewNotification', value)
                }
            },
        }
    };
</script>