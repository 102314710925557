import entities from '../../constants/entities';
import api from '../../helpers/api';
export const colourGroups = {
    state: {
        colourGroupsLoading: false,
        colourGroups: [],
    },
    mutations: {
        setColourGroupsLoading(state, loading) {
            state.colourGroupsLoading = loading;
        },
        setColourGroups(state, colourGroups) {
            state.colourGroups = colourGroups;
        }
    },
    actions: {
        loadColourGroups({ commit, dispatch }) {
            commit("setColourGroupsLoading", true);
            api.get(entities.ColourGroup.ROUTE+"?page=1&itemsPerPage=100").then(res => {
                commit("setColourGroupsLoading", false);
                commit("setColourGroups", res.data.items);
            })
            .catch(error => {
                commit("setColourGroupsLoading", false);
                dispatch("showErrorSnack", 'Cannot load colour groups');
            });
        },
    },
    getters: {
    }
}