<template>
    <div>
        <v-stepper v-model="currentStep" hide-actions flat class="job-status-stepper">
            <v-stepper-header>
                <v-stepper-item v-for="(jobTask, index) in jobTasks"
                                :key="jobTask.id"
                                :title="jobTask.name"
                                :value="index"
                                :complete="jobTask.endDate != null"></v-stepper-item>
            </v-stepper-header>
            <v-stepper-window>
                <v-card flat>
                    <component v-if="taskDetails" :is="taskDetails.component" :task="taskDetails" v-model:job="entityRecord"></component>
                </v-card>
            </v-stepper-window>
        </v-stepper>
    </div>
</template>
<style>
    .job-status-stepper .v-stepper-header {
        box-shadow: none !important;
        border-bottom: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
    }
    .job-status-stepper .v-stepper-item__title {
        font-size:14px;
    }
    .job-status-stepper .v-stepper-item {
        padding: 1rem;
    }
</style>
<script>
    import tasks from "../../constants/tasks";
    import { mapActions, mapState } from "vuex";
    function initialState() {
        return {
            saving: false,
            loading: false
        };
    }
    export default {
        name: "JobStatusPanel",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
        },
        computed: {
            ...mapState({
                entityRecordLoading: (state) => state.entities.entityRecordLoading
            }),
            entityRecord: {
                get() {
                    return this.$store.state.entities.entityRecord
                },
                set(value) {
                    this.$store.commit('setEntityRecord', value)
                }
            },
            jobTasks() {
                if (this.entityRecord.jobTasks) {
                    return this.entityRecord.jobTasks;
                }
                return [];
            },
            taskDetails() {
                return tasks.types.find(item => item.id == this.entityRecord.status);
            },
            currentStep() {
                if (this.entityRecord) {
                    return this.jobTasks.findIndex(task => task.task === this.entityRecord.status);
                }
                return 1;
            }
        },
        watch: {
        }
    };
</script>