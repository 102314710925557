<template>
    <v-btn size="x-small"
           v-if="address"
           class="mb-1 send-email-btn mt-1"
           color="primary"
           @click="openNewEmail">{{ address }}</v-btn>
</template>
<style scoped>
    .send-email-btn {
        text-decoration: none;
        text-transform: none;
        letter-spacing: 0.5px;
    }
</style>
<script>
    import { mapActions, mapState } from "vuex";
    function initialState() {
        return {
            loading: false
        };
    }
    export default {
        name: "NewEmailBtn",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            address: String,
            name: String,
            contact: Object,
            contactPerson: Object,
            job: Object
        },
        methods: {
            ...mapActions([
                "newEmail"
            ]),
            openNewEmail() {
                this.newEmail({
                    toAddress: this.address,
                    toName: this.name,
                    contactId: (this.contact ? this.contact.id : null),
                    contactPersonId: (this.contactPerson ? this.contactPerson.id : null),
                    jobId: (this.job ? this.job.id : null),
                    toOptions: this.emailOptions
                })
            },
            getContactEmails(contact, emails){
                if (contact){
                    if (contact.accountsEmail && !emails.includes(contact.accountsEmail)){
                        emails.push(contact.accountsEmail);
                    }
                    if (contact.contactPeople) {
                        contact.contactPeople.forEach(item => {
                            if (item.email){
                                emails.push(item.email);
                            }
                        });
                    }
                }
                return emails;
            }
        },
        computed: {
            emailOptions() {
                let result = this.getContactEmails(this.contact, []);
                if (this.contactPerson && this.contactPerson.email && !result.includes(this.contactPerson.email)){
                    result.push(this.contactPerson.email);
                }
                if (this.job && this.job.contact){
                    result = this.getContactEmails(this.job.contact, result);
                }
                return result;
            }
        }
    };
</script>