<template>
    <div>
        <v-overlay :model-value="loading"
                   class="align-center justify-center">
            <v-progress-circular color="primary"
                                 indeterminate
                                 size="64"></v-progress-circular>
        </v-overlay>
        <v-card variant="flat" class="pa-4 my-2">
            <h4>Jobs ready for despatch</h4>
            <v-table density="compact" class="text-caption">
                <thead>
                    <tr>
                        <th>Job</th>
                        <th>Cust#</th>
                        <th>Customer</th>
                        <th>Completed</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="job in despatch" :key="job.id">
                        <td class="w-25">
                            <job-link :job="job"></job-link>
                        </td>
                        <td>
                            <div v-if="job.customerNumberFull">{{ job.customerNumberFull }}</div>
                        </td>
                        <td class="w-25">
                            <contact-link :contact="job.contact"></contact-link>
                        </td>
                        <td>
                            <div v-if="job.previousTask">
                                {{ $dayjs(job.previousTask.endTime).format('DD-MM-YYYY') }}
                                <date-diff-chip :date="job.previousTask.endTime" :countdown="false"></date-diff-chip>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </v-table>
        </v-card>
    </div>
</template>
<script>
    import { mapActions } from "vuex";
    import { numberWithCommas } from "../../mixins/numberMixins";
    function initialState() {
        return {
            loading: false,
            today: null,
            despatch: []
        };
    }
    export default {
        name: "DespatchDashboard",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.loadDespatch();
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack",
            ]),
            numberWithCommas,
            loadDespatch() {
                this.loading = true;
                this.$api.get('/dashboard/statusjobs?status=8')
                    .then(res => {
                        this.despatch = res.data;
                        this.loading = false;
                    }).catch(error => {
                        this.loading = false;
                        this.showErrorSnack("There was an issue getting the despatch.");
                    });
            },
        },
        computed: {
        },
        watch: {
        }
    };
</script>
<style scoped>
</style>