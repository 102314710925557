import axios from 'axios'
import store from '../store/store'
import token from './token'
import NProgress from 'nprogress'

const api = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    }
})

api.interceptors.request.use((config) => {
    NProgress.start();
    if (store.state.auth.user) {
        config.headers.Authorization = `Bearer ${store.state.auth.user.token}`;
    }
    return config
});

api.interceptors.response.use(function (response) {
    NProgress.done();
    return response;
}, async function (error) {
    const originalRequest = error.config;
    if (error.code == 'ERR_NETWORK' || 401 === error.response.status) {
        if (originalRequest && !originalRequest._retry && store.state.auth.user.refreshToken) {
            originalRequest._retry = true;
            await token.refreshToken();
            return api(originalRequest);
        } else {
            store.dispatch('logout');
        }
    }
    return Promise.reject(error);
});

export default api