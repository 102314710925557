<template>
    <div class="history-footer pa-3">
        <h5 class="pb-2 text-center border-b-sm mb-2">HISTORY</h5>
        <div v-if="historyLoading" class="align-content-center my-2"><v-progress-circular indeterminate></v-progress-circular></div>
        <div v-if="!historyLoading" class="text-caption">
            <div v-for="item in history" class="hist-panel mb-1" :key="item.id">
                <span class="text-muted font-weight-bold">{{ item.header }} - </span>
                <span class="text-muted">{{ item.description }} by <span class="font-weight-bold"><v-icon icon="mdi-account"></v-icon> {{ item.createdBy }}</span> at <span class="font-weight-bold"><v-icon icon="mdi-clock-outline"></v-icon> {{ item.dateDifference }}</span>.</span>
            </div>
            <div class="text-center">
                <span v-if="!history" class="text-muted font-italic">No history yet...</span>
                <span v-if="lastHistoryPage && !historyLoading" class="text-muted font-italic"><v-icon icon="mdi-check-circle"></v-icon> ALL HISTORY LOADED</span>
                <v-progress-circular v-if="historyLoadingMore || historyLoading" indeterminate></v-progress-circular>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .history-footer {
        background-color: rgb(var(--v-theme-surface));
        margin-top: 20px;
    }
</style>
<script>
    import { mapActions, mapState } from "vuex";
    function initialState() {
        return {
            saving: false,
        };
    }
    export default {
        name: "HistoryFooter",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
        },
        methods: {
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            handleScroll(event) {
                if (!this.lastHistoryPage) {
                    var element = event.target;
                    if (Math.ceil(element.scrollHeight - element.scrollTop) - 1 <= element.clientHeight) {
                        this.loadHistory();
                    }
                }
            },
            ...mapActions([
                'loadHistory',
            ]),
        },
        computed: {
            ...mapState({
                history: (state) => state.history.history,
                historyLoading: (state) => state.history.historyLoading,
                historyLoadingMore: (state) => state.history.historyLoadingMore,
                historyTotalPages: (state) => state.history.historyTotalPages,
                historyPage: (state) => state.history.historyPage,
            }),
            lastHistoryPage() {
                return (this.historyPage >= this.historyTotalPages);
            }
        }
    };
</script>