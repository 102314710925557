<template>
    <div>
        <v-overlay :model-value="loading"
                   class="align-center justify-center">
            <v-progress-circular color="primary"
                                 indeterminate
                                 size="64"></v-progress-circular>
        </v-overlay>
        <v-card variant="flat" class="pa-4 my-2" v-if="!loading && inwards.length <= 0">
            <h4>No jobs awaiting inwards...</h4>
        </v-card>

        <v-card variant="flat" class="pa-4 my-2" color="yellow-lighten-4" v-if="overdueJobs && overdueJobs.length > 0">
            <h4>Overdue Expected Arrival</h4>
            <v-table density="compact" class="text-caption" color="yellow-lighten-4">
                <tbody>
                    <tr v-for="job in overdueJobs" :key="job.id">
                        <td class="w-25">
                            {{ $dayjs(job.currentTask.targetStartDate).format('DD-MM-YYYY') }}
                        </td>
                        <td class="w-25">
                            <job-link :job="job"></job-link>
                        </td>
                        <td class="w-25">
                            <contact-link :contact="job.contact"></contact-link>
                        </td>
                        <td class="w-25">
                            ${{ numberWithCommas(job.jobSubTotal, 2) }}
                        </td>
                    </tr>
                </tbody>
            </v-table>
        </v-card>

        <v-card variant="flat" class="pa-4 my-2" v-if="sevenDayJobs && sevenDayJobs.length > 0">
            <h4>Due Next 7 Days</h4>
            <v-table density="compact" class="text-caption">
                <tbody>
                    <tr v-for="job in sevenDayJobs" :key="job.id">
                        <td class="w-25">
                            {{ $dayjs(job.currentTask.targetStartDate).format('DD-MM-YYYY') }}
                        </td>
                        <td class="w-25">
                            <job-link :job="job"></job-link>
                        </td>
                        <td class="w-25">
                            <contact-link :contact="job.contact"></contact-link>
                        </td>
                        <td class="w-25">
                            ${{ numberWithCommas(job.jobSubTotal, 2) }}
                        </td>
                    </tr>
                </tbody>
            </v-table>
        </v-card>

        <v-card variant="flat" class="pa-4 my-2" v-if="monthJobs && monthJobs.length > 0">
            <h4>Due Next 30 Days</h4>
            <v-table density="compact" class="text-caption">
                <tbody>
                    <tr v-for="job in monthJobs" :key="job.id">
                        <td class="w-25">
                            {{ $dayjs(job.currentTask.targetStartDate).format('DD-MM-YYYY') }}
                        </td>
                        <td class="w-25">
                            <job-link :job="job"></job-link>
                        </td>
                        <td class="w-25">
                            <contact-link :contact="job.contact"></contact-link>
                        </td>
                        <td class="w-25">
                            ${{ numberWithCommas(job.jobSubTotal, 2) }}
                        </td>
                    </tr>
                </tbody>
            </v-table>
        </v-card>

        <v-card variant="flat" class="pa-4 my-2" v-if="futureJobs && futureJobs.length > 0">
            <h4>Due 30+ Days</h4>
            <v-table density="compact" class="text-caption">
                <tbody>
                    <tr v-for="job in futureJobs" :key="job.id">
                        <td class="w-25">
                            {{ $dayjs(job.currentTask.targetStartDate).format('DD-MM-YYYY') }}
                        </td>
                        <td class="w-25">
                            <job-link :job="job"></job-link>
                        </td>
                        <td class="w-25">
                            <contact-link :contact="job.contact"></contact-link>
                        </td>
                        <td class="w-25">
                            ${{ numberWithCommas(job.jobSubTotal, 2) }}
                        </td>
                    </tr>
                </tbody>
            </v-table>
        </v-card>
    </div>
</template>
<script>
    import { mapActions } from "vuex";
    import { numberWithCommas } from "../../mixins/numberMixins";
    function initialState() {
        return {
            loading: false,
            today: null,
            inwards: []
        };
    }
    export default {
        name: "InwardsDashboard",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.loadInwards();
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack",
            ]),
            numberWithCommas,
            loadInwards() {
                this.loading = true;
                this.$api.get('/dashboard/statusjobs?status=1')
                    .then(res => {
                        this.inwards = res.data;
                        this.loading = false;
                    }).catch(error => {
                        this.loading = false;
                        this.showErrorSnack("There was an issue getting the inwards.");
                    });
            },
        },
        computed: {
            overdueJobs(){
                if (this.inwards){
                    return this.inwards.filter(job => {
                        if (job.currentTask) {
                            return this.$dayjs(job.currentTask.targetStartDate) < this.$dayjs();
                        }
                        return false;
                    });
                }
                return null;
            },
            sevenDayJobs(){
                if (this.inwards) {
                    return this.inwards.filter(job => {
                        if (job.currentTask) {
                            let due = this.$dayjs(job.currentTask.targetStartDate);
                            return due >= this.$dayjs() && due < this.$dayjs().add(7, 'day');
                        }
                        return false;
                    });
                }
                return null;
            },
            monthJobs(){
                if (this.inwards) {
                    return this.inwards.filter(job => {
                        if (job.currentTask) {
                            let due = this.$dayjs(job.currentTask.targetStartDate);
                            return due >= this.$dayjs().add(7, 'day') && due < this.$dayjs().add(30, 'day');
                        }
                        return false;
                    });
                }
                return null;
            },
            futureJobs(){
                if (this.inwards){
                    return this.inwards.filter(job => {
                        if (job.currentTask) {
                            let due = this.$dayjs(job.currentTask.targetStartDate);
                            return due >= this.$dayjs().add(30, 'day');
                        }
                        return false;
                    });
                }
                return null;
            },
        },
        watch: {
        }
    };
</script>
<style scoped>
</style>