<template>
    <div>
        <div class="d-flex">
            <div class="flex-1-0">
                <h1>Xero</h1>
                <v-overlay contained v-model="loading" class="align-center justify-center">
                    <v-progress-circular color="primary"
                                         indeterminate
                                         size="64"></v-progress-circular>
                </v-overlay>
                <div v-if="status.connected">
                    <p>Your Xero is succesfully linked.</p>
                    <v-btn prepend-icon="mdi-link-variant-off" @click="disconnectXero" color="primary">
                        Disconnect Xero
                    </v-btn>
                </div>
                <div v-if="!status.connected">
                    <p>Xero is currently not connected. Click the button and follow the prompts below to connect.</p>
                    <v-btn prepend-icon="mdi-link-variant" @click="connectXero" color="primary">
                        Connect Xero
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
</style>
<script>
    function initialState() {
        return {
            saving: false,
            loading: false,
            status: {
                connected: false
            }
        };
    }
    export default {
        name: "SettingsXeroView",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.loading = true;
            this.$api.get('/xero/status')
                .then(res => {
                    this.loading = false;
                    this.status = res.data;
                });
        },
        props: {
        },
        methods: {
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            disconnectXero() {
                console.log('disconnect');
            }, 
            connectXero() {
                window.location.href = this.status.connectURI
            }
        },
        computed: {
        }
    };
</script>