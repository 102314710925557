import entities from '../../constants/entities';
import api from '../../helpers/api';
export const colours = {
    state: {
        coloursSelectLoading: false,
        coloursSelect: [],
    },
    mutations: {
        setColoursSelectLoading(state, loading) {
            state.coloursSelectLoading = loading;
        },
        setColoursSelect(state, colours) {
            state.coloursSelect = colours;
        }
    },
    actions: {
        loadSelectColours({ commit, dispatch }) {
            commit("setColoursSelectLoading", true);
            api.get(entities.Colour.ROUTE+"selectlist").then(res => {
                commit("setColoursSelectLoading", false);
                commit("setColoursSelect", res.data);
            })
            .catch(error => {
                commit("setColoursSelectLoading", false);
                dispatch("showErrorSnack", 'Cannot load colour selects');
            });
        },
    },
    getters: {
    }
}