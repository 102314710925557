import { shallowRef, ref, computed } from 'vue'
import StockPanel from '../../components/panels/StockPanel.vue'
import StockForm from '../../forms/StockForm.vue'

export default {
    ICON: 'mdi-package-variant',
    TYPE: 'Stock',
    SINGULAR: 'Stock Adjustment',
    PLURAL: 'Stock',
    ROUTE: 'Stock/',
    DELETE: null,
    CREATE: 'Stock/',
    UPDATE: null,
    FORM: shallowRef(StockForm),
    DETAILPANEL: null,
    HASDETAIL: false,
    CUSTOMOVERVIEW: shallowRef(StockPanel),
    PANELS: [
    ]
}