<template>
    <div class="w-100">
        <v-skeleton-loader v-if="loading" type="table"></v-skeleton-loader>
        <v-table v-if="!loading && tableData">
            <thead>
                <tr>
                    <th v-for="header in tableData.headers" :class="headerClass(header.type)" :key="header.code">
                        {{ header.title }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in filteredData" :key="row.id" :class="rowClass(row)">
                    <td v-for="header in tableData.headers" :class="cellClass(header.type)" :key="header.code">
                        <div v-if="header.type == 'colour'">
                            <router-link v-if="row.data.colourId" :to="'/'+$entities.Colour.ROUTE+row.data.colourId">{{ row.data.colourName }}</router-link>
                            <span v-if="!row.data.colourId">{{ row.data.colourName }}</span>
                        </div>
                        <div v-if="header.type == 'extendedChip'">
                            <v-chip variant="flat" :color="getColourFromInt(row.data[header.code] ? 1 : 0)" size="x-small">
                                {{ (row.data[header.code] ? 'Extended WIP' : 'Current WIP')}}
                            </v-chip>
                        </div>
                        <div v-if="header.type == 'decimal'">
                            {{ numberWithCommas(row.data[header.code], 2) }}
                        </div>
                        <div v-if="header.type == 'string'">
                            {{ row.data[header.code] }}
                        </div>
                        <div v-if="header.type == 'powderjobs'">
                            <v-expansion-panels elevation="0" flat>
                                <v-expansion-panel>
                                    <v-expansion-panel-title>
                                        {{ row.data.jobs.length+' Jobs' }}
                                    </v-expansion-panel-title>
                                    <v-expansion-panel-text>
                                        <v-table density="compact">
                                            <thead>
                                                <tr>
                                                    <td style="width:30%">Target</td>
                                                    <th style="width:30%">Job</th>
                                                    <th style="width:30%">Contact</th>
                                                    <th style="width:20%" class="text-right">Powder</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="job in row.data.jobs" :key="job.jobId">
                                                    <td>
                                                        <span v-if="job.dateTargetCompletion">{{ $dayjs(job.dateTargetCompletion).format('DD-MM-YYYY') }}</span>
                                                    </td>
                                                    <td>
                                                        <router-link :to="'/'+$entities.Job.ROUTE+job.jobId">{{ job.jobName }}</router-link>
                                                        <v-chip v-if="job.dateReceived" color="success" variant="flat" size="x-small" class="ml-1">
                                                            <v-icon start icon="mdi-check-circle"></v-icon>
                                                            Received
                                                        </v-chip>
                                                    </td>
                                                    <td>
                                                        <router-link :to="'/'+$entities.Contact.ROUTE+job.contactId">{{ job.contactName }}</router-link>
                                                    </td>
                                                    <td class="text-right">
                                                        {{ numberWithCommas(job.totalPowderRequired, 2) }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </v-table>
                                    </v-expansion-panel-text>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </div>
                    </td>
                </tr>
            </tbody>
        </v-table>
    </div>
</template>
<style scoped>
    .powder-jobs-table{
        width:50%;
    }
</style>
<script>
    import { numberWithCommas } from "../../mixins/numberMixins";
    import { getColourFromInt } from '../../mixins/colourMixins'
    function initialState() {
        return {
            loading: false,
            tableData: null
        };
    }
    export default {
        name: "TableReport",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.loadData();
        },
        props: {
            definition: Object
        },
        methods: {
            getColourFromInt,
            numberWithCommas,
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            loadData() {
                this.loading = true;
                this.$api.get(this.definition.route)
                    .then(res => {
                        this.loading = false;
                        this.tableData = res.data;
                    }).catch(error => {
                        this.loading = false;
                    });
            },
            headerClass(type) {
                switch (type) {
                    case 'powderjobs':
                        return "powder-jobs-table"
                    case "decimal":
                        return "text-right";
                    default:
                        return "";
                }
            },
            cellClass(type) {
                switch (type) {
                    case "decimal":
                        return "text-right";
                    default:
                        return "";
                }
            },
            rowClass(data) {

            }
        },
        computed: {
            filteredData() {
                if (this.tableData) {
                    switch (this.definition.code) {
                        case 'wip-powder':
                            return [...this.tableData.rows].sort(function (x, y) {
                                return (x.extended === y.extended) ? 0 : x.extended ? 0 : 1;
                            }).reverse();
                        default:
                            return this.tableData.rows;
                    }
                }
                return [];
            }
        },
        watch: {
        }
    };
</script>