<template>
    <div>
        <entity-overview entity-type="Contact"></entity-overview>
    </div>
</template>
<style scoped>
</style>
<script>
    function initialState() {
        return {
            saving: false,
            loading: false,
        };
    }
    export default {
        name: "ContactsView",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
        },
        methods: {
            reset: function () {
                Object.assign(this.$data, initialState());
            },
        },
        computed: {
        }
    };
</script>