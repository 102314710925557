<template>
    <div>
        <v-overlay :model-value="loading"
                   class="align-center justify-center">
            <v-progress-circular color="primary"
                                 indeterminate
                                 size="64"></v-progress-circular>
        </v-overlay>
        <div class="d-flex justify-space-between">
            <h2 v-if="weekStart && weekEnd">{{ weekStart.format('MMMM D YYYY') }} - {{ weekEnd.format('MMMM D YYYY') }}</h2>
            <v-btn-toggle variant="flat"
                          divided>
                <v-btn @click="moveWeekPrevious" icon="mdi-chevron-left-circle-outline"></v-btn>
                <v-btn @click="moveWeekToday">Today</v-btn>
                <v-btn @click="moveWeekNext" icon="mdi-chevron-right-circle-outline"></v-btn>
            </v-btn-toggle>
        </div>
        <div class="work-calendar">
            <table class="week-calendar" v-if="weekDays.length > 0">
                <tr>
                    <td class="week-header-cell" style="width:7%;"></td>
                    <td v-for="weekDay in weekDays" :key="weekDay" class="week-header-cell"
                        :class="{'week-today-date' :weekDay.isSame(today), 'week-header-past' : weekDay.isBefore(today) }">
                        <span class="week-header-dayname-date">{{ weekDay.format('D') }}</span>
                        <span class="week-header-dayname-name">{{ weekDay.format('ddd') }}</span>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td class="week-total-header" 
                        :class="{'week-today-date' :weekDay.isSame(today), 'week-header-past' : weekDay.isBefore(today) }"
                        v-for="weekDay in weekDays" 
                        :key="weekDay">${{ numberWithCommas(dayTotal(weekDay), 2) }}</td>
                </tr>
                <tr v-for="task in tasks" :key="task.id">
                    <td class="week-header-cell"><h5> {{ task.title }}</h5></td>
                    <td @click="viewDayJobs(weekDay, task)" v-for="weekDay in weekDays" :key="weekDay" class="week-line-cell" :class="{ 'week-cell-weekend' : weekendDays.includes(weekDay.day()), 'scheduling-day' : schedulingTaskDay(weekDay, task), 'holiday-day' : isHoliday(weekDay)}">
                        <v-progress-linear :color="getGroupColour(task.group)"
                                           :height="12"
                                           :model-value="daySubTotalPercentage(weekDay, task)">
                        </v-progress-linear>
                        <div class="week-line-cell-total">
                            ${{ numberWithCommas(daySubTotal(weekDay, task), 2) }}
                        </div>
                        <div class="week-line-cell-subtitle">
                            <div>{{ dayCountTotal(weekDay, task) }} jobs</div>
                        </div>
                        <v-chip class="ml-1" size="x-small" prepend-icon="mdi-island" v-if="!weekendDays.includes(weekDay.day()) && isHoliday(weekDay)">
                            Holiday
                        </v-chip>
                        <div class="day-add-details">
                            <v-divider></v-divider>
                            <div class="week-line-cell-colours" v-if="task.showColours && !weekendDays.includes(weekDay.day())">
                                <ul class="list-style">
                                    <li v-for="colour in dayColours(weekDay, task)" 
                                        :class="{'highlight-text' : isScheduledColour(weekDay, task, colour) }"
                                        :key="colour" 
                                        v-html="colour"></li>
                                </ul>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        <v-dialog v-model="viewJobs"
                  max-width="1200"
                  persistent>
            <v-card prepend-icon="mdi-calendar-today"
                    :title="viewJobTitle">
                <v-btn class="close-popup"
                       icon="mdi-close-thick"
                       color="black"
                       size="x-small"
                       @click="viewJobs = false"
                       elevation="0" />
                <template v-slot:text>
                    <div class="overflow-y-auto">
                        <v-table density="compact">
                            <thead>
                                <tr>
                                    <th class="text-left w-20">
                                        Order
                                    </th>
                                    <th class="text-left w-30">
                                        Job
                                    </th>
                                    <th class="text-left w-20">
                                        Contact
                                    </th>
                                    <th class="text-left w-20">
                                        Colours
                                    </th>
                                    <th class="text-right w-10">
                                        Total
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="schedulingTaskDay(viewJobDay, viewJobTask)" class="scheduling-day">
                                    <td></td>
                                    <td><job-link :job="scheduleJob"></job-link></td>
                                    <td>{{ scheduleJob.contact }}</td>
                                    <td>{{ scheduleJob.colours.join(', ') }}</td>
                                    <td class="text-right">${{ numberWithCommas(scheduleJob.jobTotal, 2) }}</td>
                                </tr>
                            </tbody>
                            <draggable v-model="viewJobList"
                                       ghost-class="ghost"
                                       item-key="id"
                                       tag="tbody"
                                       handle=".handle"
                                       @end="saveDayOrder">
                                <template #item="{element}">
                                    <tr>
                                        <td><v-icon class="handle" icon="mdi-dots-vertical"></v-icon> {{ element.dayOrder }}</td>
                                        <td><job-link :job="element"></job-link></td>
                                        <td>{{ element.contact }}</td>
                                        <td>{{ element.colours.join(', ') }}</td>
                                        <td class="text-right">${{ numberWithCommas(element.jobTotal, 2) }}</td>
                                    </tr>
                                </template>
                            </draggable>
                        </v-table>
                    </div>
                </template>
                <template v-slot:actions>
                    <v-spacer></v-spacer>

                    <v-btn @click="viewJobs = false">
                        Cancel
                    </v-btn>
                </template>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import { numberWithCommas } from "../../mixins/numberMixins";
    import tasks from "../../constants/tasks";
    import _cloneDeep from "lodash/cloneDeep";
    import { mapActions } from "vuex";
    import draggable from "vuedraggable";
    function initialState() {
        return {
            loading: false,
            scheduleData: [],
            holidays: [],
            today: null,
            weekStart: null,
            weekEnd: null,
            weekDays: [],
            weekendDays: [0, 6],
            viewJobs: false,
            viewJobDay: null,
            viewJobTask: null,
            viewJobList: [],
            key:0,
            drag: false,
        };
    }
    export default {
        name: "ScheduleCalendar",
        data: function () {
            return initialState();
        },
        mounted: function () {
            if (!this.scheduleJob) {
                this.moveWeekToday();
            }
        },
        components: {
            draggable
        },
        props: {
            scheduleJob: Object
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack"
            ]),
            numberWithCommas,
            buildWeekDays() {
                // Load jobs and public holidays for week
                this.$api.get('/jobtask/schedule?start=' + this.weekStart.format('YYYY-MM-DD') + '&end=' + this.weekEnd.format('YYYY-MM-DD'))
                    .then(res => {
                        this.scheduleData = res.data;
                    }).catch(error => {
                        this.showErrorSnack('There was an issue loading the schedule for this week.');
                    });
                let buildDays = [];
                let firstDay = this.weekStart.clone();
                for (var i = 0; i < 7; i++) {
                    buildDays.push(firstDay.clone());
                    firstDay = firstDay.add(1, 'day');
                }
                this.weekDays = buildDays;
            },
            saveDayOrder: function(e) {
                let newIndex = e.newIndex;
                let item = this.viewJobList[newIndex];
                if ((newIndex + 1) != item.dayOrder){
                    this.$api.patch('/jobtask/'+item.taskId, [{
                            op: "replace",
                            path: "/dayOrder",
                            value: newIndex + 1
                        }]).then(res => {
                            this.viewJobList = res.data;
                            this.showSuccessSnack("Job day order updated");
                        });
                }
            },
            moveTo(date) {
                if (date.day() == 0) {
                    this.weekStart = date.clone().day(-6);
                    this.weekEnd = date.clone().day(0);
                } else {
                    this.weekStart = date.clone().day(1);
                    this.weekEnd = date.clone().day(7);
                }
                this.buildWeekDays();
            },
            moveWeekPrevious() {
                this.weekStart = this.weekStart.add(-7, 'day');
                this.weekEnd = this.weekEnd.add(-7, 'day');
                this.buildWeekDays();
            },
            moveWeekNext() {
                this.weekStart = this.weekStart.add(7, 'day');
                this.weekEnd = this.weekEnd.add(7, 'day');
                this.buildWeekDays();
            },
            moveWeekToday() {
                this.today = this.$dayjs();
                this.moveTo(this.today); 
            },
            findDataDay(day) {
                if (this.scheduleData){
                    return this.scheduleData.find(item => {
                        return item.day == day.format("YYYY-MM-DD");
                    });
                }
            },
            dayTotal(day) {
                let dayData = this.findDataDay(day);
                if (dayData) {
                    return dayData.dayTotal;
                }
                return 0;
            },
            isHoliday(day) {
                let dayData = this.findDataDay(day);
                if (dayData) {
                    return dayData.isHoliday;
                }
                return false;
            },
            dayCountTotal(day, task) {
                let total = 0;
                if (this.schedulingTaskDay(day, task) && this.showScheduledJob) {
                    total += 1;
                }
                let dayData = this.dataTaskDay(day, task);
                if (dayData) {
                    total += dayData.jobs.length;
                }
                return total;
            },
            daySubTotal(day, task) {
                let total = 0;
                if (this.schedulingTaskDay(day, task) && this.showScheduledJob) {
                    total += this.scheduleJob.jobTotal;
                }
                let dayData = this.dataTaskDay(day, task);
                if (dayData) {
                    total += dayData.dayTotal;
                }
                return total;
            },
            daySubTotalPercentage(day, task) {
                let result = this.daySubTotal(day, task) / 10000;
                return (result > 1 ? 1 : result) * 100;
            },
            dataTaskDay(day, task) {
                let dayData = this.findDataDay(day);
                if (dayData) {
                    return dayData.taskGroups.find(item => item.taskType == task.id);
                }
            },
            dayColours(day, task) {
                let result = [];
                let dayData = this.dataTaskDay(day, task);
                if (dayData) {
                    result = dayData.colours;
                }
                let schedulingJobDay = this.schedulingTaskDay(day, task);
                if (this.showScheduledJob) {
                    this.scheduleJob.colours.forEach(colour => {
                        let index = result.indexOf(colour);
                        if (schedulingJobDay && index == -1) {
                            result.push(colour);
                        }
                    });
                }
                return result;
            },
            schedulingTaskDay(day, task) {
                if (!this.scheduleJob) {
                    return false;
                }
                let scheduledTask = this.scheduleJob.jobTasks.find(jobTask => jobTask.id == task.id);
                if (scheduledTask && scheduledTask.startDate) {
                    return this.$dayjs(scheduledTask.startDate).isSame(day, 'day');
                }
                return false;
            },
            viewDayJobs(day, task) {
                this.viewJobDay = day;
                this.viewJobTask = task;
                this.viewJobs = true;
                this.viewJobList = [];
                let dayData = this.dataTaskDay(this.viewJobDay, this.viewJobTask);
                if (dayData) {
                    this.viewJobList = dayData.jobs;
                }
            },
            isScheduledColour(day, task, colour) {
                if (this.scheduleJob) {
                    return this.scheduleJob.colours.includes(colour) && !this.schedulingTaskDay(day, task);
                }
                return false;
            },
            getGroupColour(group) {
                switch (group) {
                    case 1:
                        return "blue-darken-4";
                    case 2:
                        return "deep-purple-darken-1";
                    case 3:
                        return "pink-darken-3";
                    case 4:
                        return "red-darken-3";
                }
            },
            loadScheduledJob() {
                if (this.showScheduledJob) {
                    let dates = this.scheduleJob.jobTasks.map(task => this.$dayjs(task.startDate));
                    if (dates.length > 0) {
                        let lastDay = dates.slice(-1)[0];
                        this.moveTo(lastDay);
                    }
                }
            }
        },
        computed: {
            tasks(){
                return _cloneDeep(tasks.types).filter(item => item.schedule).sort((a, b) => a.scheduleOrder - b.scheduleOrder);
            },
            viewJobTitle() {
                if (this.viewJobDay && this.viewJobTask) {
                    return `${this.viewJobTask.title} jobs for ${this.viewJobDay.format('ddd D MMMM YYYY')}`;
                }
                return '';
            },
            showScheduledJob(){
                return this.scheduleJob != null;
            }
        },
        watch: {
            scheduleJob: {
                handler: function () {
                    // Move calendar to see end of schedule
                    this.loadScheduledJob();
                }
            }
        }
    };
</script>
<style scoped>
    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
    }
    .handle {
        cursor:grab;
    }
    .holiday-day:not(.week-cell-weekend) {
        background-color: #B0BEC5 !important
    }
    .scheduling-day {
        background-color: #ffb74d !important
    }
    .has-scheduling-colour {
        background-color: #ffb74d !important
    }
    .week-total-header {
        text-align:center;
    }
    .week-calendar {
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
    }

    .week-user-row {
        width: 16%;
        border-top: 1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
    }
    .holiday-day:not(.week-cell-weekend) > .week-line-cell-total {
        display: none;
    }
    .holiday-day:not(.week-cell-weekend) > .week-line-cell-subtitle {
        display: none;
    }
    .holiday-day:not(.week-cell-weekend) > .day-add-details {
        display: none;
    }
    .week-header-cell {
        border-top: 1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
        height: 42px;
        padding: 0px 5px;
    }

    .week-header-past {
        color: #bbb;
    }

    .week-header-dayname-date {
        font-size: 23px;
        line-height: 38px;
    }

    .week-header-dayname-name {
        font-size: 12px;
    }

    .week-today-date {
        font-weight: bold;
    }

    .week-today-date {
        background-color: #f5c6cb;
        color: #dc3545;
    }

    .week-line-cell:hover {
        background-color: #f8f9fa;
    }

    .week-cell-weekend {
        background-color: #f8f9fa;
    }

    .week-line-cell {
        border: 1px solid #e5e5e5;
        padding: 0;
        vertical-align: baseline;
        height: 100px;
        cursor: pointer;
    }

    .week-line-cell-total {
        padding:0 5px;
    }
    .week-line-cell-subtitle {
        padding: 0 5px;
        font-size: 12px;
    }
    .week-line-cell-colours {
        padding: 0 5px;
        font-size: 8px;
    }
</style>