<template>
    <div>
        <v-row class="h-100">
            <v-col cols="4" class="border-right panel-inner-height">
                <div>
                    <v-text-field clearable
                                  density="compact"
                                  :loading="loading"
                                  ref="searchInput"
                                  hide-details
                                  v-model="searchTerm"
                                  label="Search mail..."
                                  prepend-icon="mdi-magnify"></v-text-field>
                </div>
                <v-skeleton-loader :loading="loading"
                                   type="table">
                    <div class="mail-list">
                        <div v-for="(mail, index) in items" :key="mail.id">
                            <div @click="setActiveMail(mail)" v-if="items" class="mail-list-item" :class="{ 'active-mail-item' : (activeMail && activeMail.id == mail.id) }">
                                <div class="w-100 d-flex justify-space-between">
                                    <div class="mb-1 h5">{{ mail.fromAddress }}</div>
                                    <small>{{ emailReceived(mail.dateCreated) }}</small>
                                </div>
                                <div class="w-100 d-flex justify-space-between">
                                    <div class="w-60"><p class="mb-1 mail-subject">{{ mail.subject }}</p></div>
                                </div>
                                <small class="my-0">
                                    <span v-for="recipient in recipientList(mail, 'To')" :key="recipient.id">
                                        <span v-html="recipient.name ? recipient.name : recipient.emailAddress"></span><span v-if="index != Object.keys(recipientList(mail, 'To')).length - 1">, </span>
                                    </span>
                                </small>
                            </div>
                        </div>
                    </div>
                </v-skeleton-loader>
            </v-col>
            <v-col cols="8" class="panel-inner-height">
                <div class="mail-details">
                    <div v-if="activeMail">
                        <h3>{{ activeMail.subject }}</h3>
                        <small>{{ activeMail.sentTime }}</small>
                        <div class="w-100 d-flex justify-space-between">
                            <div class="w-25">
                                <strong>From</strong>
                                <div>{{ activeMail.fromAddress }}</div>
                            </div>
                            <div v-for="recepientType in ['To', 'CC', 'BCC']" :key="recepientType" class="w-25">
                                <div v-if="recipientList(activeMail, recepientType).length > 0">
                                    <strong>{{ recepientType }}</strong>
                                    <div v-for="recipient in recipientList(activeMail, recepientType)" :key="recipient.emailAddress">
                                        <a v-if="recipient.contactId" :to="'/'+$entities.Contact.ROUTE+recipient.contactId" v-html="recipient.name ? recipient.name : recipient.emailAddress"></a>
                                        <span v-if="!recipient.contactId" v-html="recipient.name ? recipient.name : recipient.emailAddress"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="activeMail" v-html="activeMail.body" class="pa-2"></div>
            </v-col>
        </v-row>
    </div>
</template>
<style scoped>
    .mail-details {
        width: 100%;
        height: 120px;
        border-radius: 0.25rem;
        background: rgba(0, 0, 0, .03);
        border: 1px solid rgba(0, 0, 0, .03);
        padding: 20px 20px 0px 20px;
    }
    .mail-list {
        height: 480px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
        margin-top: 10px;
        width: 100%;
    }
    .mail-list-item {
        border-width: 0 0 1px;
        z-index: 2;
        position: relative;
        display: block;
        padding: .5rem 0.75rem;
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.125);
        cursor: pointer;
        width: 100%;
    }
    .mail-subject {
        font-size:80%;
    }
    .active-mail-item {
        z-index: 2;
        color: #fff;
        background-color: #b8daff;
        color: black !important;
    }
</style>
<script>
    import { mapActions, mapState, mapGetters } from "vuex";
    function initialState() {
        return {
            saving: false,
            searchTerm: '',
            loading: false,
            page: 1,
            totalItems: 0,
            items: [],
            activeMail: null
        };
    }
    export default {
        name: "MailPanel",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.loadEmails();
        },
        props: {
            object: Object,
            entity: Object
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            loadEmails() {
                this.loading = true;
                let query = [];
                if (this.entity.TYPE == "Job") {
                    query.push("jobId=" + this.object.id);
                }
                if (this.entity.TYPE == "Contact") {
                    query.push("contactId=" + this.object.id);
                }
                if (this.searchTerm && this.searchTerm.length > 3) {
                    query.push('searchTerm=' + this.searchTerm);
                }
                this.$api.get('/Mail/EntityMail?' + query.join('&'))
                    .then(res => {
                        this.items = res.data.items;
                        this.totalItems = res.data.totalItems;
                        this.loading = false;
                        if (this.items.length > 0) {
                            this.setActiveMail(this.items[0]);
                        }
                    }).catch(error => {
                        this.showErrorSnack("There was an issue getting the mail.");
                    });
            },
            setActiveMail(mail) {
                if (this.activeMail && this.activeMail.id == mail.id) {
                    this.activeMail = null;
                } else {
                    this.activeMail = mail;
                }
            },
            recipientList(mail, type) {
                if (mail && mail.recipients) {
                    return mail.recipients.filter(function (item) {
                        return item.type == type;
                    });
                }
                return [];
            },
            emailReceived(date) {
                var d = this.$dayjs(date, "DD/MM/YY");
                if (Math.round(this.$dayjs().diff(d, 'day', true)) < 1) {
                    return d.format("h:mm a");
                } else {
                    return d.format("D MMM YY");
                }
            },
        },
        computed: {
            ...mapState({
                entityRecord: (state) => state.entities.entityRecord,
                entityRecordLoading: (state) => state.entities.entityRecordLoading
            }),
        },
        watch: {
            searchTerm: {
                handler() {
                    if (this.searchTerm && this.searchTerm.length > 3) {
                        this.loadEmails();
                    }
                }
            }
        }
    };
</script>