import api from '../../helpers/api';
export const notes = {
    state: {
        mentionUsers: [],
        notesLoading: false,
        notesSaving: false,
        notes: [],
        notesLoadingMore: false,
        noteTotalPages: 0,
        notesPage: 1,
        lastNotesPage: false,
        noteClassName: null,
        noteClassId: null,
        notesPagesLoaded: 1
    },
    mutations: {
        setNoteSaving(state, saving) {
            state.notesSaving = saving;
        },
        setNoteClass(state, { classId, className }) {
            state.noteClassName = className;
            state.noteClassId = classId;
        },
        setMentionUsers(state, mentionUsers) {
            state.mentionUsers = mentionUsers;
        },
        setNotesLoading(state, loading) {
            state.notesLoading = loading;
        },
        setNotesLoadingMore(state, loading) {
            state.notesLoadingMore = loading;
        },
        setNotesSaving(state, saving) {
            state.notesSaving = saving;
        },
        setNotes(state, notes) {
            state.notes = notes;
        },
        setNoteTotalPages(state, pages) {
            state.noteTotalPages = pages;
        },
        setNotesPage(state, page) {
            state.notesPage = page;
        },
        setLastNotesPage(state, last) {
            state.lastNotesPage = last;
        },
        updateNote(state, note) {
            const index = state.notes.findIndex(obj => obj.id === note.id);
            state.notes.splice(index, 1, note);
        },
        setNotesPagesLoaded(state, pages) {
            state.notesPagesLoaded = pages;
        },
        addNewNote(state, note) {
            state.notes.splice(0, 0, note);
        }
    },
    actions: {
        initNotes({ commit, dispatch }, { classId, className }) {
            commit('setNoteClass', { classId, className });
            dispatch('loadNotes');
        },
        loadNotes({ commit, dispatch, state }) {
            commit('setNotesLoading', true);
            var query = [];
            query.push('className=' + state.noteClassName);
            query.push('classId=' + state.noteClassId);
            query.push('page=' + state.notesPage);
            api.get('/Notes/GetNotes?' + query.join("&"))
                .then(res => {
                    commit("setNoteTotalPages", res.data.pageCoun);
                    commit("setNotes", res.data.notes);
                    commit("setMentionUsers", res.data.mentionUsers);
                    commit('setNotesLoading', false);
                }).catch(error => {
                    commit('setNotesLoading', false);
                    dispatch('showErrorSnack', 'There was an issue loading the notes.');
                });
        },
        loadNotesNextPage({ commit, dispatch, state }) {
            if (state.notesPage == state.notesPagesLoaded) {
                commit("setNotesPage", state.notesPage + 1);
                commit("setNotesLoadingmore", true);
                // Set query
                var query = [];
                query.push('className=' + state.noteClassName);
                query.push('classId=' + state.noteClassId);
                query.push('page=' + state.notesPage);
                api.get("/Notes/GetNotes/?" + query.join("&"))
                    .then(res => {
                        commit("addMoreNotes", res.data.notes);
                        commit("setNotesLoadingmore", false);
                        if (res.data.notes.length > 0) {
                            commit("setNotesPagesLoaded", state.notesPage);
                        }
                    })
                    .catch(error => {
                        commit("setNotesLoadingMore", false);
                        dispatch("showErrorSnack", 'There was an issue loading more notes.');
                    });
            }
        },
        createClassNote({ dispatch, state }, { body, attachments }) {
            dispatch('saveNote', { body: body, classId: state.noteClassId, className: state.noteClassName, type: 1, attachments: attachments });
        },
        saveNote({ commit, dispatch }, { body, classId, className, type, attachments }) {
            commit("setNoteSaving", true);
            const data = new FormData();
            data.append('Body', body);
            data.append('ClassId', classId);
            data.append('ClassName', className);
            data.append('Type', type);
            attachments.forEach(function (item) {
                data.append("Files[]", item);
            });
            api.post("/notes/create", data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                    commit("setNoteSaving", false );
                    commit("addNewNote", res.data);
                })
                .catch(error => {
                    commit("setNoteSaving", false);
                    dispatch("showErrorSnack", 'There was an issue saving the note.');
                });
        },
        deleteNote({ commit, dispatch, state }, note) {
            api.delete("/notes/" + note.id)
                .then(res => {
                    note.deleted = true;
                    commit("updateNote", note);
                }).catch(error => {
                    dispatch("showErrorSnack", 'We hit an issue and could not delete note.');
                });

        },
        showDeleted({ commit }, note) {
            note.showDeleted = true;
            commit("updateNote", note);
        },
    },
    getters: {
    }
}