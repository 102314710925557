<template>
    <div>
        <v-data-table-server v-model:items-per-page="itemsPerPage"
                             v-if="typeof(totalItems) != undefined"
                             :headers="tableHeaders"
                             :items-length="totalItems"
                             :items="tableItems"
                             :loading="tableLoading"
                             :disable-pagination="removePaging"
                             :hide-default-footer="removePaging"
                             :hide-actions="removePaging"
                             density="compact"
                             @update:itemsPerPage="itemsPerPageChange"
                             @update:page="pageChange"
                             class="text-caption"
                             fixed-header>
            <template v-slot:[`item.status`]="{ item }">
                <invoice-status :status="item.status"></invoice-status>
            </template>
            <template v-slot:[`item.totalAmount`]="{ item }">
                ${{ numberWithCommas(item.totalAmount, 2) }}
            </template>
            <template #bottom v-if="removePaging">
            </template>
        </v-data-table-server>
    </div>
</template>
<style scoped>
</style>
<script>
    import { numberWithCommas } from "../../mixins/numberMixins";
    import { mapActions, mapState } from "vuex";
    function initialState() {
        return {
            saving: false,
            loading: false,
            itemsPerPage: 10,
            totalItems: 0,
            tableItems: [],
            tableLoading: false,
            page: 1,
            sortBy: null
        };
    }
    export default {
        name: "InvoicesTablePanel",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.loadDatatable();
        },
        props: {
            object: Object,
            entity: Object
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            loadDatatable() {
                this.tableLoading = true;
                let route = this.$entities.Invoice.ROUTE + "entityinvoices?page=" + this.page + "&itemsPerPage=" + this.itemsPerPage;
                if (this.entity.TYPE == "Contact") {
                    route += "&contactId="+this.object.id;
                }
                if (this.entity.TYPE == "Job"){

                    route += "&jobId="+this.object.id;
                }
                if (this.sortBy && this.sortBy.length > 0) {
                    route += "&sortBy=" + this.sortBy[0].key + "&sortOrder=" + this.sortBy[0].order;
                }
                this.$api.get(route)
                    .then(res => {
                        this.tableLoading = false;
                        this.tableItems = res.data.items;
                        this.totalItems = res.data.totalItems;
                    })
                    .catch(error => {
                        this.tableLoading = false;
                        this.showErrorSnack('There was an error loading the table data.');
                    });
            },
            itemsPerPageChange(itemsPerPage) {
                this.itemsPerPage = itemsPerPage;
                this.loadDatatable();
            },
            pageChange(page) {
                this.page = page;
                this.loadDatatable();
            },
            numberWithCommas
        },
        computed: {
            ...mapState({
            }),
            removePaging() {
                return (this.totalItems && this.totalItems <= this.itemsPerPage);
            },
            tableHeaders() {
                return [
                    { title: 'Created', key: 'dateCreated', width: "20%" },
                    { title: 'Number', key: 'invoiceNumber', width: "40%" },
                    { title: 'Status', key: 'status', width: "40%" },
                ];
            }
        }
    };
</script>