<template>
    <v-dialog v-model="showRescheduleJob"
              fullscreen
              persistent
              :scrim="false">
        <v-card>
            <v-toolbar dark
                       color="primary">
                <v-toolbar-title><v-icon icon="mdi-calendar-sync-outline"></v-icon> Reschedule Job - <span v-if="rescheduleJob">{{ rescheduleJob.name }}</span></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon
                       dark
                       @click="showRescheduleJob = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text v-if="rescheduleJob">
                <job-schedule-panel :job="rescheduleJob" :reschedule="true"></job-schedule-panel>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<style scoped>

</style>
<script>
    function initialState() {
        return {
            loading: false
        };
    }
    import { mapActions, mapState } from "vuex";
    export default {
        name: "RescheduleJobDialog",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
        },
        methods: {
        },
        computed: {
            ...mapState({
                rescheduleJob: (state) => state.jobs.rescheduleJob,
            }),
            showRescheduleJob: {
                get() {
                    return this.$store.state.jobs.showRescheduleJob
                },
                set(value) {
                    this.$store.commit('setShowRescheduleJob', value)
                }
            }
        }
    };
</script>