const signalR = require("@microsoft/signalr");
import api from '../../helpers/api';
export const userNotifications = {
    state: {
        notificationsLoading: false,
        notifications: [],
        newNotification: null,
        showNewNotification: false
    },
    mutations: {
        setNotificationsLoading(state, loading) {
            state.notificationsLoading = loading;
        },
        setNotifications(state, notifications) {
            state.notifications = notifications;
        },
        addNotification(state, notification) {
            state.notifications.splice(0, 0, notification);
        },
        addNewNotification(state, notification) {
            state.newNotification = notification;
            state.notifications.splice(0, 0, notification);
            state.showNewNotification = true;
        },
        setShowNewNotification(state, showNewNotification) {
            state.showNewNotification = showNewNotification;
        }
    },
    actions: {
        initNotifications({ commit, dispatch, rootState }) {
            commit("setNotificationsLoading", true);
            if (rootState.auth.user) {
                const options = {
                    accessTokenFactory: () => {
                        return `${rootState.auth.user.token}`;
                    },
                };
                let connection = new signalR.HubConnectionBuilder()
                    .withUrl(process.env.VUE_APP_API_URL + "hubs/notifications", options)
                    .build();
                connection.start().catch(error => { });
                connection.on("SendNotification", data => {
                    commit("addNewNotification", data);
                });
                connection.on('finished', (update) => {
                    connection.stop();
                });
            }
            api.get("/usernotifications/")
                .then(res => {
                    commit("setNotificationsLoading", false);
                    commit("setNotifications", res.data);
                })
                .catch(error => {
                    if (error.response) {
                        commit("setNotificationsLoading", false);
                        dispatch("showErrorSnack", 'There was an issue loading the notifications');
                    }
                });
        },
        markNotificationViewed({ commit, dispatch, state }, id) {
            const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
            // Find the index of the object with matching id
            const index = state.notifications.findIndex(notification => notification.id === id);
            api.post('/usernotifications/viewed/?id=' + id)
                .then(res => {
                    // Update notification
                    if (index !== -1) {
                        state.notifications[index].viewedAt = today;
                        commit('setShowNewNotification', false);
                    }
                });
        }
    },
    getters: {
    }
}