import api from '../../helpers/api';
import TemplateSwaps from '../../constants/mailtemplates'
export const mail = {
    state: {
        showSendMail: false,
        loadingMail: false,
        toName: "",
        contactId: null,
        contactPersonId: null,
        jobId: null,
        fromAddress: '',
        fromAddresses: [],
        toAddresses: [],
        ccAddresses: [],
        bccAddresses: [],
        subject: "",
        body: "",
        attachments: [],
        addressOptions: [],
        messageTemplates: [],
        messageSwaps: [],
        messageSignature: '',
        templateInput: false,
        mailSending: false
    },
    mutations: {
        setMailSending(state, sending) {
            state.mailSending = sending;
        },
        setLoadingMail(state, loading) {
            state.loadingMail = loading;
        },
        setSendMail(state, show) {
            state.showSendMail = show;
        },
        setToName(state, name) {
            state.toName = name;
        },
        setContactId(state, id) {
            state.contactId = id;
        },
        setContactPersonId(state, id) {
            state.contactPersonId = id;
        },
        setJobId(state, id) {
            state.jobId = id;
        },
        setFromAddress(state, fromAddress) {
            state.fromAddress = fromAddress;
        },
        setFromAddresses(state, fromAddress) {
            state.fromAddresses = fromAddress;
        },
        setToAddress(state, toAddress) {
            state.toAddresses = toAddress;
        },
        setCCAddress(state, toAddress) {
            state.ccAddresses = toAddress;
        },
        setBCCAddress(state, toAddress) {
            state.bccAddresses = toAddress;
        },
        setSubject(state, subject) {
            state.subject = subject;
        },
        setBody(state, body) {
            state.body = body;
        },
        setAttachments(state, attachments) {
            state.attachments = attachments;
        },
        addAttachment(state, attachment) {
            state.attachments.push(attachment);
        },
        removeAttachment(state, attachment) {
            let index = state.attachments.indexOf(attachment);
            if (index > -1) {
                state.attachments.splice(index, 1);
            }
        },
        setAddressOptions(state, options) {
            state.addressOptions = options;
        },
        setMessageTemplates(state, templates) {
            state.messageTemplates = templates;
        },
        setMessageSwaps(state, swaps) {
            state.messageSwaps = swaps;
        },
        setMessageSignature(state, signature) {
            state.messageSignature = signature;
        },
        setTemplateInput(state, template) {
            state.templateInput = template;
        }
    },
    actions: {
        newEmail({ commit, dispatch }, { toAddress, toName, contactId, contactPersonId, jobId, toOptions }) {
            commit('setToName', toName);
            commit('setContactId', contactId);
            commit('setContactPersonId', contactPersonId);
            commit('setJobId', jobId);
            commit('setToAddress', [toAddress]);
            commit('setCCAddress', []);
            commit('setBCCAddress', []);
            commit('setFromAddresses', []);
            commit('setBody', '');
            commit('setSubject', '');
            commit('setAttachments', []);
            commit('setAddressOptions', toOptions);
            commit('setSendMail', true);
            dispatch('loadTemplateDetails');
        },
        loadTemplateDetails({ commit, dispatch, state }) {
            let query = [];
            if (state.contactId) {
                query.push('contactId=' + state.contactId);
            }
            if (state.contactPersonId) {
                query.push('contactPersonId=' + state.contactPersonId);
            }
            if (state.jobId) {
                query.push('jobId=' + state.jobId);
            }
            api.get('/messagetemplates/newtemplatedetails?' + query.join('&'))
                .then(res => {
                    commit('setFromAddresses', res.data.fromAddresses);
                    commit('setFromAddress', res.data.fromAddresses[0]);
                    commit('setMessageTemplates', res.data.messageTemplates);
                    commit('setMessageSwaps', res.data.messageSwaps);
                    commit('setBody', res.data.basicBody);
                }).catch(error => {
                    dispatch('showErrorSnack', 'There was an issue loading the template details.');
                });
        },
        sendEmail({ commit, dispatch, state }) {
            commit('setMailSending', true);
            let formData = new FormData();
            formData.append('subject', state.subject);
            formData.append('fromAddress', state.fromAddress);
            formData.append('body', state.body);
            if (state.contactId != null && state.contactId != 'null') {
                formData.append('fromContactId', state.contactId);
            }
            if (state.contactPersonId != null && state.contactPersonId != 'null') {
                formData.append('fromContactPersonId', state.contactPersonId);
            }
            if (state.jobId) {
                formData.append('jobId', state.jobId);
            }
            let i = 0
            state.toAddresses.forEach(item => {
                formData.append(`recipients[${i}].type`, 'To');
                formData.append(`recipients[${i}].emailAddress`, item);
                i++;
            });
            state.ccAddresses.forEach(item => {
                formData.append(`recipients[${i}].type`, 'CC');
                formData.append(`recipients[${i}].emailAddress`, item);
                i++;
            });
            state.bccAddresses.forEach(item => {
                formData.append(`recipients[${i}].type`, 'BCC');
                formData.append(`recipients[${i}].emailAddress`, item);
                i++;
            });
            api.post('/mail/sendemail', formData)
                .then(res => {
                    commit('setMailSending', false);
                    commit('setSendMail', false);
                    dispatch('showSuccessSnack', 'Email queued to send');
                }).catch(error => {
                    commit('setMailSending', false);
                    dispatch('showErrorSnack', 'There was an issue queueing the email.');
                });
        }
    },
    getters: {
        mailSwaps(state) {
            if (state.templateInput) {
                return TemplateSwaps.swaps;
            } else {
                return Object.keys(state.messageSwaps);
            }
        }
    }
}