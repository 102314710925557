<template>
    <div>
        <v-overlay :model-value="loading"
                   class="align-center justify-center">
            <v-progress-circular color="primary"
                                 indeterminate
                                 size="64"></v-progress-circular>
        </v-overlay>
        <div class="d-flex w-100 justify-space-between mb-2 pb-2 border-b-sm">
            <h3 v-if="job"><v-icon icon="mdi-file-tree"></v-icon> {{ job.statusName }}</h3>
            <job-task-buttons v-model:job="localJob" :task="task"></job-task-buttons>
        </div>
        <v-row>
            <v-col v-if="job.previousTask" md="4">
                <job-previous-task :previous-task="job.previousTask"></job-previous-task>
            </v-col>
            <v-col md="4">
                <job-next-task :next-task="job.currentTask"></job-next-task>
            </v-col>
        </v-row>
    </div>
</template>
<style scoped>
</style>
<script>
    import { mapActions, mapState } from "vuex";
    import _cloneDeep from "lodash/cloneDeep";
    function initialState() {
        return {
            saving: false,
            loading: false,
            localJob: null,
        };
    }
    export default {
        name: "JobTaskPanel",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.localJob = this.job;
        },
        props: {
            task: Object,
            job: Object
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
        },
        computed: {
        },
        watch: {
            localJob: {
                handler() {
                    this.$emit('update:job', this.localJob);
                }
            },
        }
    };
</script>