export const app = {
    state: {
        snackType: null,
        snackMessage: null,
        showSnack: false
    },
    mutations: {
        setSnackType(state, type) {
            state.snackType = type;
        },
        setSnackMessage(state, message) {
            state.snackMessage = message;
        },
        setShowSnack(state, show) {
            state.showSnack = show;
        }
    },
    actions: {
        showErrorSnack({ commit }, message) {
            commit('setSnackMessage', message);
            commit('setSnackType', 'error');
            commit('setShowSnack', true);
        }, 
        showSuccessSnack({ commit }, message) {
            commit('setSnackMessage', message);
            commit('setSnackType', 'success');
            commit('setShowSnack', true);
        },
    },
    getters: {
    }
}