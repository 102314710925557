import tableReport from '../components/reports/TableReport.vue'
import contactSalesSummaryReport from '../components/reports/ContactSalesSummaryReport.vue'
export default {
    definitions: [
        {
            code: 'wip-powder',
            title: 'WIP Powder Report',
            subtitle: 'Shows estimated powder requirements for all WIP jobs.',
            component: tableReport,
            route: '/report/wippowder',
            accessLevel: 0
        },
        {
            code: 'contact-sales-sum',
            title: 'Contact Sales Summary',
            subtitle: 'Shows summary of sales list for a period by contact.',
            component: contactSalesSummaryReport,
            route: '/report/contactsalessum',
            accessLevel: 100
        },
    ]
}