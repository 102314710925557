<template>
    <v-form v-model="valid" @submit.prevent="submit" :disabled="loading">
        <v-row>
            <v-col cols="10">
                <v-stepper v-model="step" :items="steps">
                    <template v-slot:[`item.1`]>
                        <v-card title="Type" flat>
                            <v-card-subtitle>
                                Select what type of product this will be.
                            </v-card-subtitle>
                            <v-card-text>
                                <v-list v-model="editProduct.type" :lines="false">
                                    <v-list-item v-for="(item, i) in productTypes"
                                                 :key="i"
                                                 :value="item"
                                                 :active="item.id === editProduct.type"
                                                 @click="editProduct.type = item.id"
                                                 color="primary">
                                        <template v-slot:prepend>
                                            <v-icon :icon="item.icon"></v-icon>
                                        </template>
                                        <template v-slot:title>
                                            <div v-html="item.title"></div>
                                        </template>
                                        <template v-slot:subtitle>
                                            <div v-html="item.subtitle"></div>
                                        </template>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </template> 

                    <template v-slot:[`item.2`]>
                        <v-card title="Details" flat>
                            <v-card-subtitle>
                                Complete the details for the product.
                            </v-card-subtitle>
                            <v-card-text>
                                <v-text-field v-model="editProduct.name"
                                              v-on:change="addCode"
                                              :rules="[$rules.required]"
                                              label="Name *"></v-text-field>
                                <v-text-field v-model="editProduct.code"
                                              :rules="[$rules.required]"
                                              label="Code *"></v-text-field>
                                <label>Complexity Factor</label>
                                <p>The complexity factor is used in pricing and indicates additional care and time is required for this product.</p>
                                <v-rating v-model="editProduct.complexity"
                                          :item-labels="['Simple', 'Standard', 'Complex']"
                                          item-label-position="bottom"
                                          empty-icon="mdi-circle-outline"
                                          length="3"
                                          size="80"
                                          full-icon="mdi-circle"
                                          hover></v-rating>
                            </v-card-text>
                        </v-card>
                    </template>

                    <template v-slot:[`item.3`]>
                        <v-card title="UOM's" flat>
                            <v-card-subtitle>
                                Enter the structure and uom information below for the product. This will be used in calculating pricing.
                            </v-card-subtitle>
                            <v-card-text>
                                <p>Select the main UOM of this product from the list below.</p>
                                <v-list v-model="editProduct.uomType" :lines="false">
                                    <v-list-item v-for="(item, i) in uoms"
                                                 :key="i"
                                                 :value="item"
                                                 :active="item.id === editProduct.uomType"
                                                 @click="editProduct.uomType = item.id"
                                                 color="primary">
                                        <template v-slot:prepend>
                                            <v-icon :icon="item.icon"></v-icon>
                                        </template>
                                        <template v-slot:title>
                                            <div v-html="item.title"></div>
                                        </template>
                                        <template v-slot:subtitle>
                                            <div v-html="item.subtitle"></div>
                                        </template>
                                    </v-list-item>
                                </v-list>
                                <div v-if="!editProduct.id && editProduct.type != 1 && editProduct.uomType != null">
                                    <p>Please enter the required product's UOM's below.</p>
                                    <div v-if="editProduct.uomType == 'Periphery Length'">
                                        <v-text-field label="Periphery *"
                                                        v-model.number="periphery"
                                                        hint="Periphery of product in millimeters"
                                                        persistent-hint
                                                        type="number"
                                                        suffix="MM"></v-text-field>
                                        <div class="d-flex pa-4">
                                            <v-checkbox-btn v-model="editProduct.fixedSize"
                                                            label="Fixed length?"
                                                            class="pe-2"></v-checkbox-btn>
                                            <v-text-field label="Length"
                                                            v-model.number="length"
                                                            hint="Length of product in millimeters"
                                                            persistent-hint
                                                            :disabled="!editProduct.fixedSize"
                                                            hide-details
                                                            type="number"
                                                            suffix="MM"></v-text-field>
                                        </div>
                                    </div>
                                    <div v-if="editProduct.uomType == 'Length Only'">
                                        <div class="d-flex pa-4">
                                            <v-checkbox-btn v-model="editProduct.fixedSize"
                                                            label="Fixed length?"
                                                            class="pe-2"></v-checkbox-btn>
                                            <v-text-field label="Length"
                                                            v-model.number="length"
                                                            hint="Length of product in millimeters"
                                                            persistent-hint
                                                            :disabled="!editProduct.fixedSize"
                                                            hide-details
                                                            type="number"
                                                            suffix="MM"></v-text-field>
                                        </div>
                                    </div>
                                    <div v-if="editProduct.uomType == 'Periphery Area'">
                                        <div class="d-flex pa-4">
                                            <v-checkbox-btn v-model="editProduct.fixedSize"
                                                            label="Fixed size?"
                                                            class="pe-2"></v-checkbox-btn>
                                            <v-text-field label="Width"
                                                          v-model.number="width"
                                                          hint="Width of product in millimeters"
                                                          persistent-hint
                                                          :disabled="!editProduct.fixedSize"
                                                          hide-details
                                                          type="number"
                                                          suffix="MM"></v-text-field>
                                            <v-text-field label="Height"
                                                          v-model.number="height"
                                                          hint="Height of product in millimeters"
                                                          persistent-hint
                                                          :disabled="!editProduct.fixedSize"
                                                          hide-details
                                                          class="ml-2"
                                                          type="number"
                                                          suffix="MM"></v-text-field>
                                            <v-text-field label="Depth"
                                                          v-model.number="depth"
                                                          hint="Depth of product in millimeters"
                                                          persistent-hint
                                                          :disabled="!editProduct.fixedSize"
                                                          hide-details
                                                          class="ml-2"
                                                          type="number"
                                                          suffix="MM"></v-text-field>
                                        </div>
                                    </div>
                                    <div v-if="editProduct.uomType == 'Unit Only'">
                                        <p>No UOM's required.</p>
                                    </div>
                                    <p>Enter any optional UOM's below if available and relevant.</p>
                                    <v-text-field label="Gauge Thickness"
                                                    v-model.number="gaugeThickness"
                                                    hint="Length of product in millimeters"
                                                    persistent-hint
                                                    hide-details
                                                    type="number"
                                                    suffix="GAUGE"></v-text-field>
                                </div>
                                <div v-if="editProduct.id && editProduct.type != 1 && editProduct.uomType != null">
                                    <p>Please enter the required product's UOM's below.</p>
                                    <div v-if="editProduct.uomType == 'Periphery Length'">
                                        <rates-display :type="$rates.TYPE_PERIH" :class-id="editProduct.id" class-name="Product" :mandatory="true"></rates-display>
                                        <div class="d-flex pa-4">
                                            <v-checkbox-btn v-model="editProduct.fixedSize"
                                                            label="Fixed length?"
                                                            class="pe-2"></v-checkbox-btn>
                                            <rates-display :type="$rates.RATE_LENGTH" :class-id="editProduct.id" class-name="Product" :read-only="!fixedSize" :mandatory="fixedSize"></rates-display>
                                        </div>
                                    </div>
                                    <div v-if="editProduct.uomType == 'Length Only'">
                                        <div class="d-flex pa-4">
                                            <v-checkbox-btn v-model="editProduct.fixedSize"
                                                            label="Fixed length?"
                                                            class="pe-2"></v-checkbox-btn>
                                            <rates-display :type="$rates.RATE_LENGTH" :class-id="editProduct.id" class-name="Product" :read-only="!fixedSize" :mandatory="fixedSize"></rates-display>
                                        </div>
                                    </div>
                                    <div v-if="editProduct.uomType == 'Periphery Area'">
                                        <div class="d-flex pa-4">
                                            <v-checkbox-btn v-model="editProduct.fixedSize"
                                                            label="Fixed size?"
                                                            class="pe-2"></v-checkbox-btn>
                                            <rates-display :type="$rates.RATE_WIDTH" :class-id="editProduct.id" class-name="Product" :read-only="!fixedSize" :mandatory="fixedSize"></rates-display>
                                            <rates-display :type="$rates.RATE_HEIGHT" :class-id="editProduct.id" class-name="Product" :read-only="!fixedSize" :mandatory="fixedSize"></rates-display>
                                            <rates-display :type="$rates.RATE_DEPTH" :class-id="editProduct.id" class-name="Product" :read-only="!fixedSize" :mandatory="fixedSize"></rates-display>
                                        </div>
                                    </div>
                                    <p>Enter any optional UOM's below if available and relevant.</p>
                                    <rates-display :type="$rates.RATE_GAUGE" :class-id="editProduct.id" class-name="Product" :mandatory="false"></rates-display>
                                </div>
                                <v-alert density="compact"
                                         v-if="uomErrors.length > 0"
                                         type="warning"
                                         class="my-2"
                                         title="Missing UOM Information">
                                    <ul>
                                        <li v-for="error in uomErrors" :key="error">{{ error }}</li>
                                    </ul>
                                </v-alert>
                            </v-card-text>
                        </v-card>
                    </template>

                    <template v-slot:[`item.4`]>
                        <v-card title="Job Items" flat>
                            <v-card-subtitle>
                                Select the job item templates available for this product.
                            </v-card-subtitle>
                            <v-card-text>
                                <v-overlay contained v-model="pricingOptionsLoading" class="align-center justify-center">
                                    <v-progress-circular color="primary"
                                                         indeterminate
                                                         size="64"></v-progress-circular>
                                </v-overlay>
                                <div v-if="pricingOptions.length == 0 && !pricingOptionsLoading" class="align-center justify-center">
                                    <h3>No valid job items found!</h3>
                                    <p>Please change your UOM and try again.</p>
                                </div>
                                <v-list lines="one">
                                    <v-list-item v-for="option in pricingOptions"
                                                 :key="option.id"
                                                 :value="option"
                                                 :active="option.selected"
                                                 @click="option.selected = !option.selected"
                                                 color="primary">
                                        <template v-slot:prepend>
                                            <v-checkbox v-model="option.selected"></v-checkbox>
                                        </template>
                                        <template v-slot:title>
                                            <div v-html="option.name"></div>
                                        </template>
                                        <template v-slot:subtitle>
                                            <i>UOM: <b>{{ option.uom }}</b></i>
                                        </template>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </template>
                    <template v-slot:actions>
                        <v-stepper-actions @click:next="moveNext" @click:prev="movePrevious" 
                                           :disabled="stepperActionsDisabled"></v-stepper-actions>
                    </template>
                </v-stepper>
            </v-col>
            <v-col cols="2">
                <v-card title="Product Details">
                    <v-card-subtitle>
                        Review the details of the product below before saving.
                    </v-card-subtitle>
                    <v-card-text>
                        <v-list lines="one">
                            <v-list-item v-if="editProduct.type">
                                <type-chip :type="editProduct.type" entity="Product"></type-chip>
                            </v-list-item>
                            <v-list-item v-if="editProduct.name" title="Name" :subtitle="editProduct.name">
                            </v-list-item>
                            <v-list-item v-if="editProduct.code" title="Code" :subtitle="editProduct.code">
                            </v-list-item>
                            <v-list-item v-if="editProduct.uomType" title="UOM Type" :subtitle="editProduct.uomType">
                            </v-list-item>
                            <v-list-item v-if="editProduct.complexity" title="Complexity" :subtitle="editProduct.complexity">
                            </v-list-item>
                            <v-list-item title="Fixed size?" :subtitle="editProduct.fixedSize ? 'Yes' : 'No'">
                            </v-list-item>
                            <v-list-item v-if="periphery" title="Periphery" :subtitle="periphery+' MM'">
                            </v-list-item>
                            <v-list-item v-if="length" title="Length" :subtitle="length+' MM'">
                            </v-list-item>
                            <v-list-item v-if="width" title="Width" :subtitle="width+' MM'">
                            </v-list-item>
                            <v-list-item v-if="height" title="Height" :subtitle="height+' MM'">
                            </v-list-item>
                            <v-list-item v-if="depth" title="Depth" :subtitle="depth+' MM'">
                            </v-list-item>
                            <v-list-item v-if="area" title="Area" :subtitle="area+' M2'">
                            </v-list-item>
                            <v-list-item v-if="gaugeThickness" title="Gauge Thickness" :subtitle="gaugeThickness">
                            </v-list-item>
                            <v-list-item v-if="selectedPricingDisplay" title="Job Items" :subtitle="selectedPricingDisplay">
                            </v-list-item>
                        </v-list>
                        <v-alert v-if="saveValid"
                                 type="success"
                                 title="Ready to Save"
                                 text="Product is ready to be saved. Click the save button below."
                                 class="my-2">
                        </v-alert>
                        <div class="mt-2 pt-2">
                            <v-btn :loading="loading"
                                   type="submit"
                                   :disabled="!saveValid"
                                   flat
                                   color="primary"
                                   prepend-icon="mdi-floppy">SAVE</v-btn>
                            <v-btn flat class="ml-2" @click="showEntityForm = false">CANCEL</v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-alert density="compact"
                 v-if="errors"
                 type="warning"
                 class="my-2"
                 :title="errors.title">
            <ul>
                <li v-for="error in errors.errors" :key="error">{{ error[0] }}</li>
            </ul>
        </v-alert>
    </v-form>
</template>
<style scoped>
</style>
<script>
    import products from '../constants/products'
    import { mapActions, mapState } from "vuex";
    import _cloneDeep from 'lodash/cloneDeep';
    import { encodeProductBasic } from '../mixins/entityEncodeMixins';
    function initialState() {
        return {
            saving: false,
            loading: false,
            valid: false,
            errors: null,
            step:1,
            steps: ['Type', 'Details', "UOM's", 'Job Items'],
            editProduct: {
                id: null,
                type: null,
                name: "",
                code: "",
                fixedSize: false,
                uomType: null,
                complexity: 2
            },
            length: null,
            periphery: null, 
            width: null,
            height: null,
            depth: null,
            pricingOptions: [],
            pricingOptionsLoading: false,
            gaugeThickness: null,
        };
    }
    export default {
        name: "ProductFrom",
        data: function () {
            return initialState();
        },
        mounted: function () {
            if (this.editEntity) {
                this.editProduct = _cloneDeep(this.editEntity);
            }
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showSuccessSnack",
                "showErrorSnack",
                "reloadEntities"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            addCode() {
                if (this.editProduct.name.length > 0 && this.editProduct.code.length == 0){
                    this.editProduct.code = this.editProduct.name.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s+/g, '-').toUpperCase();
                }
            },
            submit() {
                this.loading = true
                let formData = new FormData();
                let config = {
                    method: 'post',
                    url: this.entity.CREATE,
                    data: new FormData()
                }
                if (this.editEntity != null) {
                    config = {
                        method: 'put',
                        url: this.entity.UPDATE + this.editEntity.id,
                        data: new FormData()
                    }
                } else {
                    if (this.length){
                        formData.append('defaultLength', this.length);
                    }
                    if (this.periphery){
                        formData.append('defaultPeriphery', this.periphery);
                    }
                    if (this.width){
                        formData.append('defaultWidth', this.width);
                    }
                    if (this.height){
                        formData.append('defaultHeight', this.height);
                    }
                    if (this.depth) {
                        formData.append('defaultDepth', this.depth);
                    }
                    if (this.gaugeThickness){
                        formData.append('defaultGaugeThickness', this.gaugeThickness);
                    }
                }
                formData = encodeProductBasic(formData, this.editProduct);
                let i = 0;
                this.selectedPricing.forEach(pricing => {
                    formData.append(`productJobItems[${i}].jobItemTemplateId`, pricing.id);
                    i++;
                });
                config.data = formData;
                this.$api(config)
                    .then(res => {
                        this.loading = false;
                        this.showEntityForm = false;
                        this.reset();
                        this.showSuccessSnack("Updated!");
                        this.reloadEntities();
                    }).catch(error => {
                        if (error.response.data && Array.isArray(error.response.data)) {
                            this.errors = error.response.data;
                        } else {
                            this.errors = { title: "Whoops", errors: [["We hit an error. Please contact support."]] };
                        }
                        this.loading = false;
                    });
            },
            moveNext() {
                if (this.step == 1) {
                    // If definition reset uoms
                    if (this.editProduct.type == 1) {
                        this.width = this.height = this.length = this.periphery = this.gaugeThickness = this.depth = null;
                    }
                }
                this.step++;
                // Load pricing options
                if (this.step == 4 && this.editProduct.uomType) {
                    this.pricingOptionsLoading = true;
                    let editId = '';
                    if (this.editProduct.id){
                        editId = '&productId=' + this.editProduct.id;
                    }
                    this.$api.get(this.$entities.JobItemTemplate.ROUTE+'uomvalid?uoms=' + this.editProduct.uomType + editId)
                        .then(res => {
                            this.pricingOptionsLoading = false;
                            this.pricingOptions = res.data;
                        }).catch(error => {
                            if (error.response.data && Array.isArray(error.response.data)) {
                                this.errors = error.response.data;
                            } else {
                                this.errors = { title: "Whoops", errors: [["We hit an error. Please contact support."]] };
                            }
                            this.pricingOptionsLoading = false;
                        });
                }
            },
            movePrevious() {
                this.step--;
            }
        },
        computed: {
            ...mapState({
                entity: (state) => state.entities.entity,
                editEntity: (state) => state.entities.editEntity,
            }),
            showEntityForm: {
                get() {
                    return this.$store.state.entities.showEntityForm
                },
                set(value) {
                    this.$store.commit('setShowEntityForm', value)
                }
            },
            productTypes() {
                return products.types;
            },
            uoms() {
                return products.uoms;
            },
            noUomType() {
                return this.editProduct.uomType == null;
            },
            area() {
                if (this.width && this.height){
                    return (this.width/1000) * (this.height/1000) * (this.depth/1000);
                }
                return null;
            },
            selectedPricing() {
                if (this.pricingOptions.length > 0) {
                    return this.pricingOptions.filter(item => {
                        return item.selected;
                    });
                }
                return [];
            },
            selectedPricingDisplay() {
                if (this.selectedPricing.length > 0) {
                    return this.selectedPricing.map(a => a.name).join(', ');
                }
                return null;
            },
            saveValid() {
                return this.step == 4 && this.selectedPricing.length > 0;
            },
            uomErrors() {
                let errors = [];
                if (this.editProduct.type == 1 || this.editProduct.id){
                    return errors;
                }
                if (this.editProduct.uomType == 'Periphery Length'){
                    if (!this.periphery){
                        errors.push('Periphery figure is required for this UOM.');
                    }
                    if (this.editProduct.fixedSize && !this.length) {
                        errors.push('Length is required if fixed length is ticked.');
                    }
                } else if (this.editProduct.uomType == 'Length Only'){
                    if (this.fixedSize && !this.length) {
                        errors.push('Length is required if fixed length is ticked.');
                    }
                } else {
                    if (this.editProduct.fixedSize && !this.width) {
                        errors.push('Width is required if fixed size is ticked.');
                    }
                    if (this.editProduct.fixedSize && !this.height) {
                        errors.push('Height is required if fixed size is ticked.');
                    }
                    if (this.editProduct.fixedSize && !this.depth) {
                        errors.push('Depth is required if fixed size is ticked.');
                    }
                }
                return errors;
            },
            stepperActionsDisabled() {
                let prevDisabled = this.step == 1;
                let nextDisabled = this.step == this.steps.length;
                switch(this.step) {
                    case 1:
                        // Type selected
                        nextDisabled = this.editProduct.type == null;
                        break;
                    case 2:
                        nextDisabled = this.editProduct.name.length == 0 && this.editProduct.code.length == 0;
                        break;
                    case 3:
                        nextDisabled = !this.editProduct.uomType || this.uomErrors.length > 0;
                        break;
                    case 4:
                        nextDisabled = true;
                        break;
                }
                if (prevDisabled && nextDisabled) {
                    return true;
                } else if (nextDisabled) {
                    return 'next';
                } else if (prevDisabled) {
                    return 'prev';
                }
                return false;
            }
        }
    };
</script>