import { shallowRef, ref, computed } from 'vue'
import JobItemTemplateForm from '../../forms/JobItemTemplateForm.vue'

export default {
    ICON: 'mdi-code-not-equal-variant',
    TYPE: 'JobItemTemplate',
    SINGULAR: 'Job Item Template',
    PLURAL: 'Job Item Templates',
    ROUTE: 'jobitemtemplates/',
    DELETE: 'jobitemtemplates/',
    CREATE: 'jobitemtemplates/',
    UPDATE: 'jobitemtemplates/',
    FORM: shallowRef(JobItemTemplateForm),
    HASDETAIL: false,
    TABLEHEADERS: [
        { title: 'Name', key: 'name' },
        { title: 'UOM', key: 'uom' },
        { title: 'Calculation', key: 'calculation' },
        { title: '', key: 'controls' },
    ]
}