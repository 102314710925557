<template>
    <v-chip v-if="object && typeSet" variant="flat" :color="typeSet.colour" size="x-small">
        <v-icon v-if="typeSet.icon" start :icon="typeSet.icon"></v-icon> {{ typeSet.name }}
    </v-chip>
</template>
<style scoped>
</style>
<script>
    import { mapActions } from "vuex";
    import { getColourFromInt } from '../../mixins/colourMixins'
    import { getTagDetails } from '../../mixins/jobMixins'
    function initialState() {
        return {
            saving: false,
            loading: false
        };
    }
    export default {
        name: "EntityTagChip",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            object: Object
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
        },
        computed: {
            typeSet() {
                switch(this.object.entityType){
                    case "Contact":
                        if (this.object.contactGroup && !this.object.contactGroup.standardGroup) {
                            switch (this.object.contactGroup.name) {
                                case "Gold":
                                    return { name: this.object.contactGroup.name, colour: "amber-darken-2", icon: 'mdi-cards-diamond-outline' };
                                case "Platinum":
                                    return { name: this.object.contactGroup.name, colour: "blue-grey-lighten-1", icon: 'mdi-diamond-stone' };
                            }
                            return { name: this.object.contactGroup.name, colour: getColourFromInt(this.object.contactGroup.id), icon: null };
                        }
                        break;
                    case "Job":
                        return getTagDetails(this.object.jobTag);
                }
                return null;
            },
        }
    };
</script>