import api from '../../helpers/api';
export const history = {
    state: {
        history: [],
        historyTotalPages: 0,
        historyPage: 1,
        historyPageLoaded: 0,
        historyFilter: null,
        historyLoading: false,
        historyLoadingMore: false
    },
    mutations: {
        setHistory(state, items) {
            state.history = items;
        },
        setHistoryPage(state, page) {
            state.historyPage = page;
        },
        setHistoryTotalPages(state, totalPages) {
            state.historyTotalPages = totalPages;
        },
        setHistoryPageLoaded(state, page) {
            state.historyPageLoaded = page;
        },
        addMoreHistory(state, history) {
            state.history.push(...history);
        },
        setHistoryFilter(state, { classId, className }) {
            state.historyClass = className;
            state.historyId = classId;
        },
        setHistoryLoading(state, loading) {
            state.historyLoading = loading;
        },
        setHistoryLoadingMore(state, loadingMore) {
            state.historyLoadingMore = loadingMore;
        },
    },
    actions: {
        initHistory({ commit, dispatch }, { classId, className }) {
            commit('setHistoryFilter', { classId, className });
            dispatch('reloadHistory');
        },
        reloadHistory({ commit, dispatch }) {
            commit('setHistoryPage', 0);
            commit('setHistoryTotalPages', 0);
            commit('setHistoryPageLoaded', 0);
            commit('setHistory', []);
            dispatch('loadHistory');
        },
        loadHistory({ commit, dispatch, state }) {
            if (state.historyClass && state.historyId) {
                if (state.historyPage == state.historyPageLoaded) {
                    if (state.historyPage == 0) {
                        commit("setHistoryLoading", true);
                    } else {
                        commit("setHistoryLoadingMore", true);
                    }
                    commit("setHistoryPage", state.historyPage + 1);
                    var query = [];
                    query.push('historyClass=' + state.historyClass);
                    query.push('historyId=' + state.historyId);
                    query.push('page=' + state.historyPage);
                    api.get("/history/?" + query.join("&"))
                        .then(res => {
                            commit("addMoreHistory", res.data.history);
                            if (res.data.history.length > 0) {
                                commit("setHistoryPageLoaded", state.historyPage);
                            }
                            commit("setHistoryLoading", false);
                            commit("setHistoryLoadingMore", false);
                        })
                        .catch(error => {
                            if (error.response) {
                                dispatch("showErrorSnack", 'There was an issue loading the history. Please try again later.');
                            }
                        });
                }
            }
        },
    },
    getters: {
    }
}