<template>
    <div class="chat" :style="{ maxHeight: (maxHeight ? maxHeight+'px' : null ) }" @scroll.passive="handleScroll">
        <div v-for="note in notes" :class="{ 'mine': note.mine, 'yours': !note.mine, 'deleted-note': (note.deleted && note.showDeleted != true) }" :key="note.id">
            <div class="message">
                <div class="note-date-section d-inline-block pb-1 w-100">
                    <div class="d-inline-block text-left float-left">
                        <v-tooltip text="Delete">
                            <template v-slot:activator="{ props }">
                                <button v-if="!note.deleted" class="note-delete-btn" v-bind="props" @click="confirmDeleteNote(note)">
                                    <small><v-icon icon="mdi-trash-can-outline"></v-icon></small>
                                </button>
                            </template>
                        </v-tooltip>
                    </div>
                    <div class="d-inline-block text-right float-right">
                        <small><v-icon icon="mdi-clock-outline"></v-icon> {{ note.dateDifference }}</small>
                    </div>
                </div>
                <div v-if="note.deleted && !note.showDeleted" class="deleted-display"><v-icon icon="mdi-cancel"></v-icon> Comment Deleted<br /> <div type="button" @click="showDeleted(note)">Show Note</div></div>
                <div v-if="!note.deleted || note.showDeleted" class="note-body">
                    <i v-if="!note.body">No Comment Body</i>
                    <span v-html="note.body"></span>
                    <div v-if="note.attachments && note.attachments.length > 0" class="row pt-1 mt-1 border-top">
                        <div class="col-12">
                            <small v-for="attachment in note.attachments" class="attachment-block" :key="attachment.id">
                                <a target="_blank" :href="attachment.link"><v-icon icon="mdi-paperclip"></v-icon> {{ attachment.fileName }}</a>
                            </small>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="note.mine" class="text-right">
                <small class="text-muted">{{ note.createdBy }} <v-icon icon="mdi-account-outline"></v-icon></small>
            </div>
            <div v-if="!note.mine" class="text-left">
                <small class="text-muted"><v-icon icon="mdi-account"></v-icon> {{ note.createdBy }}</small>
            </div>
        </div>
        <div class="text-center">
            <span v-if="lastNotesPage && !notesLoading"><small class="text-muted"><v-icon icon="mdi-check-circle-outline"></v-icon> ALL NOTES LOADED</small></span>
            <v-progress-circular v-if="notesLoadingMore || notesLoading" indeterminate></v-progress-circular>
        </div>
        <v-dialog v-model="confirmDelete"
                  width="auto">
            <v-card>
                <v-card-text>
                    Are you sure you want to delete this note?
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary"
                           prepend-icon="mdi-trash-can-outline"
                           @click="confirmingDeleteNote"
                           dark>
                        DELETE
                    </v-btn>
                    <v-btn dark @click="confirmDelete = false">
                        CANCEL
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<style scoped>
</style>
<script>
    import { mapActions, mapState } from "vuex";
    function initialState() {
        return {
            saving: false,
            confirmDelete: false,
            noteToDelete: null
        };
    }
    export default {
        name: "NoteDisplay",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            maxHeight: Number,
        },
        methods: {
            ...mapActions([
                "loadNotesNextPage",
                "deleteNote",
                "showDeleted"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            handleScroll(e) {
                if (!this.lastNotesPage) {
                    var element = e.target;
                    if (Math.ceil(element.scrollHeight - element.scrollTop) - 1 <= element.clientHeight) {
                        this.loadNotesNextPage();
                    }
                }
            },
            confirmDeleteNote(note) {
                this.noteToDelete = note;
                this.confirmDelete = true;
            },
            confirmingDeleteNote(){
                this.confirmDelete = false;
                this.deleteNote(this.noteToDelete);
            }

        },
        computed: {
            ...mapState({
                notes: (state) => state.notes.notes,
                notesLoading: (state) => state.notes.notesLoading,
                notesLoadingMore: (state) => state.notes.notesLoadingMore,
                noteTotalPages: (state) => state.notes.noteTotalPages,
                notesPage: (state) => state.notes.notesPage,
                lastNotesPage: (state) => state.notes.lastNotesPage,
                user: (state) => state.auth.user
            })
        },
        watch: {
        }
    };
</script>