import { shallowRef, ref, computed } from 'vue'
import ContactGroupForm from '../../forms/ContactGroupForm.vue'

export default {
    ICON: 'mdi-account-box-multiple-outline',
    TYPE: 'ContactGroup',
    SINGULAR: 'Contact Group',
    PLURAL: 'Contact Groups',
    ROUTE: 'contactgroups/',
    DELETE: 'contactgroups/',
    CREATE: 'contactgroups/create',
    UPDATE: 'contactgroups/',
    FORM: shallowRef(ContactGroupForm),
    HASDETAIL: false,
    TABLEHEADERS: [
        { title: 'Name', key: 'name' },
        { title: 'Multiplier', key: 'rate' },
        { title: '', key: 'controls' },
    ]
}