import { shallowRef, ref, computed } from 'vue'
import ColourForm from '../../forms/ColourForm.vue'
import ColourDetailsPanel from '../../components/panels/ColourDetailsPanel.vue'
import JobTablePanel from '../../components/panels/JobTablePanel.vue'

export default {
    ICON: 'mdi-palette',
    TYPE: 'Colour',
    SINGULAR: 'Colour',
    PLURAL: 'Colours',
    ROUTE: 'colours/',
    DELETE: 'colours/',
    CREATE: 'colours/',
    UPDATE: 'colours/',
    FORM: shallowRef(ColourForm),
    HASDETAIL: true,
    DETAILPANEL: ColourDetailsPanel,
    TABLEHEADERS: [
        { title: '', key: 'rgb' },
        { title: 'Code', key: 'code' },
        { title: 'Name', key: 'name' },
        { title: 'Brand', key: 'brand' },
        { title: 'Range', key: 'range' },
        { title: 'Finish', key: 'finishTypeName' },
        { title: 'Group', key: 'colourGroup.name' },
        { title: '', key: 'controls' }
    ],
    PANELS: [
        { title: 'Jobs', component: JobTablePanel, size: 12, inCard: true },
    ]
}