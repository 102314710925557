<template>
    <div>
        <v-text-field v-model="editContact.name"
                      :rules="[$rules.required]"
                      hint="A business or person's name"
                      persistent-hint
                      @change="updateModel"
                      label="Contact name *"></v-text-field>
        <v-text-field v-model="editContact.accountsEmail"
                      label="Account's email *"
                      @change="updateModel"
                      hint="This is the email address all invoices will be sent"
                      persistent-hint
                      prepend-inner-icon="mdi-email-outline"
                      :rules="[$rules.required, $rules.email]"></v-text-field>
        <v-select label="Group *"
                  v-model="editContact.contactGroup"
                  @update:modelValue="updateModel"
                  :rules="[$rules.required]"
                  :loading="contactGroupsLoading"
                  :items="contactGroups"
                  item-title="name"
                  item-value="id"></v-select>
        <v-text-field v-model="editContact.phone"
                      @change="updateModel"
                      prepend-inner-icon="mdi-phone"
                      label="Phone number"></v-text-field>
        <v-select label="Job File Import"
                  v-model="editContact.importFileImportType"
                  @update:modelValue="updateModel"
                  :items="jobFileImportTypes"
                  hint="If set this will import a customers provided PO and create jobs in the system"
                  item-title="name"
                  item-value="id"></v-select>
        <v-sheet border rounded>
            <v-list>
                <v-list-subheader><v-icon>mdi-account-box-outline</v-icon> Contact People</v-list-subheader>
                <v-list-item v-for="(contactPerson, index) in editContact.contactPeople" :key="contactPerson.id" class="border-top">
                    <v-list-item-title v-if="index == 0">Primary Contact</v-list-item-title>
                    <v-list-item-title v-if="index != 0">
                        Additional Contact
                        <v-btn prepend-icon="mdi-trash-can-outline"
                               color="primary"
                               @click="removeContactPerson(index)"
                               @change="updateModel"
                               size="x-small">DELETE</v-btn>
                    </v-list-item-title>
                    <div class="d-flex">
                        <v-text-field v-model="contactPerson.firstName"
                                      @change="updateModel"
                                      label="First name"></v-text-field>
                        <v-text-field v-model="contactPerson.lastName" class="ml-1"
                                      @change="updateModel"
                                      label="Last name"></v-text-field>
                    </div>
                    <div class="d-flex">
                        <v-text-field v-model="contactPerson.email"
                                      label="Email"
                                      prepend-inner-icon="mdi-email-outline"
                                      @change="updateModel"
                                      :rules="[$rules.email]"></v-text-field>
                        <v-text-field v-model="contactPerson.phone"
                                      prepend-inner-icon="mdi-phone"
                                      @change="updateModel"
                                      class="ml-1"
                                      label="Phone number"></v-text-field>
                    </div>
                    <v-row>
                        <v-col>
                            <v-select label="Role"
                                      v-model="contactPerson.role"
                                      item-title="name"
                                      item-value="value"
                                      @update:modelValue="updateModel"
                                      single-line
                                      :items="roles"></v-select>
                        </v-col>
                        <v-col>
                            <v-select label="Decision Role"
                                      v-model="contactPerson.decisionRole"
                                      item-title="name"
                                      item-value="value"
                                      @update:modelValue="updateModel"
                                      single-line
                                      :items="decisionRoles"></v-select>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                </v-list-item>
            </v-list>
            <v-btn prepend-icon="mdi-account-multiple-plus-outline"
                   color="secondary"
                   @click="addContactPerson"
                   style="margin: 0px 16px 10px 16px;">
                ADD ANOTHER PERSON
            </v-btn>
        </v-sheet>
    </div>
</template>
<style scoped>
</style>
<script>
    import { mapActions, mapState } from "vuex";
    import _cloneDeep from "lodash/cloneDeep";
    function initialState() {
        return {
            saving: false,
            loading: false,
            valid: false,
            errors: null,
            editContact: {
                id: null,
                name: '',
                accountsEmail: '',
                phone: '',
                contactGroup: null,
                importFileImportType: null,
                contactPeople: [
                    {
                        id: 0,
                        firstName: '',
                        lastName: '',
                        email: '',
                        phone: '',
                        role: null,
                        decisionRole: null
                    }
                ]
            },
            refresh: 0,
            roles: [
                '',
                'Admin',
                'Sales',
                'Production',
                'Supervisor',
                'Factory Manager',
                'Project Manager',
                'Operations',
                'Accounts',
                'Delivery',
                'Director',
                'Logistics',
                'Manager',
                'Other'
            ],
            decisionRoles: [
                '',
                'Key Decision Maker',
                'Influencer'
            ],
            jobFileImportTypes: [
                { name: 'Thermosash PO Import', id: 1}
            ]
        };
    }
    export default {
        name: "ContactInput",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.loadContactGroups();
            this.loadContact();
        },
        props: {
            contact: Object
        },
        methods: {
            ...mapActions([
                "showSuccessSnack",
                "showErrorSnack",
                "loadContactGroups",
            ]),
            loadContact() {
                if (this.contact.id) {
                    this.editContact = _cloneDeep(this.contact);
                }
            },
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            addContactPerson() {
                this.editContact.contactPeople.push({
                    id: this.editContact.contactPeople.length + 1,
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    role: null,
                    decisionRole: null
                });
                this.updateModel();
            },
            removeContactPerson(index) {
                this.editContact.contactPeople.splice(index, 1);
                this.updateModel();
            },
            updateModel() {
                this.$emit('update:contact', this.editContact);
            }
        },
        computed: {
            ...mapState({
                contactGroups: (state) => state.contactGroups.contactGroups,
                contactGroupsLoading: (state) => state.contactGroups.contactGroupsLoading
            }),
        },
        watch: {
            contact: {
                handler() {
                    if (!this.editContact.id && this.contact.id) {
                        this.loadContact();
                        this.$forceUpdate();
                    }
                },
                deep: true
            }
        }
    };
</script>