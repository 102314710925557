<template>
    <div class="d-inline-block" v-if="date">
        <v-chip variant="flat" size="x-small" v-if="dayDifference > 0" color="primary" class="ml-1">{{ dayDifference }} days <span v-if="!countdown" class="ml-1"> ago</span></v-chip>
        <v-chip prepend-icon="mdi-alarm" variant="flat" size="x-small" v-if="dayDifference <= 0 && countdown" color="red" class="ml-1">Due</v-chip>
    </div>
</template>
<style scoped>
</style>
<script>
    function initialState() {
        return {
            loading: false
        };
    }
    export default {
        name: "DateDiffChip",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            date: String,
            countdown: Boolean
        },
        methods: {
        },
        computed: {
            dayDifference() {
                if (this.countdown) {
                    return this.$dayjs(this.date).diff(this.$dayjs(), 'day');
                } else {
                    return this.$dayjs().diff(this.$dayjs(this.date), 'day');
                }
            }
        }
    };
</script>