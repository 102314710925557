<template>
    <div>
        TODO
    </div>
</template>
<style scoped>
</style>
<script>
    import { mapActions, mapState, mapGetters } from "vuex";
    function initialState() {
        return {
            saving: false,
            loading: false
        };
    }
    export default {
        name: "ProductTablePanel",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
        },
        computed: {
            ...mapState({
                entityRecord: (state) => state.entities.entityRecord,
                entityRecordLoading: (state) => state.entities.entityRecordLoading
            }),
        }
    };
</script>