import entities from '../../constants/entities';
import api from '../../helpers/api';
export const jobItemTemplates = {
    state: {
        jobItemTemplatesLoading: false,
        jobItemTemplates: []
    },
    mutations: {
        setJobItemTemplatesLoading(state, loading) {
            state.jobItemTemplatesLoading = loading;
        },
        setJobItemTemplates(state, jobItemTemplates) {
            state.jobItemTemplates = jobItemTemplates;
        }
    },
    actions: {
        loadJobItemTemplates({ commit, dispatch }) {
            commit("setJobItemTemplatesLoading", true);
            api.get(entities.JobItemTemplate.ROUTE).then(res => {
                commit("setJobItemTemplatesLoading", false);
                commit("setJobItemTemplates", res.data.items);
            })
                .catch(error => {
                    commit("setJobItemTemplatesLoading", false);
                    dispatch("showErrorSnack", 'Cannot load job item templates');
                });
        },
    },
    getters: {
        filterJobItemTemplates: (state) => (templateIds) => {
            if (templateIds && templateIds.length > 0) {
                return state.jobItemTemplates.filter(item => {
                    return templateIds.includes(item.id);
                })
            }
            return [];
        },
    }
}