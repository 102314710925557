<template>
    <div id="note-input-container">
        <div id="dropping-zone">DROP FILES HERE</div>
        <div class="sc-user-input">
            <div v-if="notesSaving" class="sending-spinner">
                <v-progress-circular indeterminate></v-progress-circular>
            </div>
            <Mentionable :keys="['@']" :items="items" @open="onOpen" @apply="onApply" @close="onClose" insert-space omit-key offset="6">
                <div ref="newContent" @keydown="keyMonitor" role="button" tabindex="0" contenteditable="true" placeholder="Write a message..." class="sc-user-input--text" :style="{ color: 'rgb(86, 88, 103)', 'width':textInputWidth+'px'  }"></div>
            </Mentionable>
        </div>
        <div class="button-area">
            <div class="sc-user-input--buttons">
                <div class="sc-user-input-cluster">
                    <v-tooltip text="Attachment">
                        <template v-slot:activator>
                            <div class="sc-user-input--button" :disabled="notesSaving">
                                <div>
                                    <div type="button" class="sc-user-input--file-icon-wrapper">
                                        <v-icon icon="mdi-paperclip" class="sc-user-input--file-icon"></v-icon>
                                        <input id="file-input" type="file" @input="onFileChange" multiple>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </v-tooltip>
                    <v-tooltip text="Send">
                        <template v-slot:activator>
                            <div class="sc-user-input--button" @click="saveNote" :disabled="notesSaving">
                                <div>
                                    <div type="button" class="sc-user-input--file-icon-wrapper d-inline-block">
                                        <v-icon icon="mdi-send-circle-outline" class="sc-user-input--file-icon"></v-icon>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </v-tooltip>
                </div>
            </div>
        </div>
        <div v-if="fileAttachments.length > 0" class="file-attachment">
            <div v-for="fileAttachment in fileAttachments" class="d-flex justify-content-between file-list-item" :key="fileAttachment.id">
                <small :style="{ 'max-width':textInputWidth+'px'  }" class="attachment-name"><v-icon icon="mdi-paperclip"></v-icon> {{ fileAttachment.name }}</small>
                <v-tooltip text="Delete">
                    <template v-slot:activator>
                        <v-btn class="fileBtn" icon="mdi-cancel" @click="removeFile(fileAttachment)" density="compact" size="x-small"></v-btn>
                    </template>
                </v-tooltip>
            </div>
        </div>
    </div>
</template>
<script>
    import 'floating-vue/dist/style.css'
    import { Mentionable } from 'vue-mention'
    import { mapActions, mapState } from "vuex";
    import $ from "jquery";
    function initialState() {
        return {
            saving: false,
            fileAttachments: [],
            text: '',
            items: [],
            fileDropping: false,
            insertKey: '',
            currentKey: '',
            textInputWidth: '',
        };
    }
    export default {
        name: "NoteInput",
        data: function () {
            return initialState();
        },
        components: {
            Mentionable,
        },
        created() {
            window.addEventListener("resize", this.resizeInput);
        },
        unmounted() {
            window.removeEventListener("resize", this.resizeInput);
        },
        mounted: function () {
            var fileUploadFunction = this.onFileChange;
            $(document).on("dragenter", function (e) {
                if (e.target == this) {
                    return;
                }
                $('#dropping-zone').show();
                e.preventDefault();
            }).on("dragover", function (e) {
                e.preventDefault();
            }).on("dragleave", function (e) {
                if (e.originalEvent.pageX != 0 || e.originalEvent.pageY != 0) {
                    return false;
                }
                $('#dropping-zone').hide();
            }).on("drop", function (e) {
                $('#dropping-zone').hide();
                e.preventDefault();
                e.stopPropagation();
                if (e.target instanceof HTMLElement && e.target == $('#dropping-zone')[0]) {
                    fileUploadFunction(e.originalEvent);
                }
            });
        },
        props: {
        },
        methods: {
            ...mapActions([
                "createClassNote"
            ]),
            onFileChange(e) {
                var files = e.target.files || e.dataTransfer.files;
                if (!files.length)
                    return;
                for (let i = 0; i < files.length; i++){
                    this.fileAttachments.push(files[i]);
                }
                document.getElementById('file-input').value = null;
            },
            removeFile(fileAttachment) {
                const index = this.fileAttachments.indexOf(fileAttachment);
                if (index > -1) {
                    this.fileAttachments.splice(index, 1);
                }
            },
            saveNote() {
                if (this.notesSaving || this.$refs.newContent.innerHTML.length == 0) {
                    return;
                }
                this.createClassNote({ body: this.$refs.newContent.innerHTML, attachments: this.fileAttachments });
                this.$refs.newContent.innerHTML = '';
                this.fileAttachments = [];
            },
            onOpen(key) {
                if (this.currentKey != '' && this.insertKey == '') {
                    this.insertKey = this.currentKey;
                }
                this.currentKey = key;
                switch (key) {
                    case '@':
                        this.items = this.mentionUsers;
                        break;
                }
            },
            onApply(item, key, replacedWith) {
                key = this.insertKey;
                if (!key) {
                    key = this.currentKey;
                }
                var actualValue = `<span class="mention" data-denotation-char="@" data-id="${item.value}">@${item.label} </span>`;
                this.$refs.newContent.innerHTML = this.$refs.newContent.innerHTML.replace(this.escapeHtml(item.value), actualValue);
                var contentDiv = this.$refs.newContent;
                var setCaret = this.setCaret;
                setTimeout(function () {
                    setCaret(contentDiv.textContent.length);
                }, 0);
                this.insertKey = '';
                this.currentKey = '';
            },
            onClose() {
                this.insertKey = this.currentKey;
            },
            escapeHtml(html) {
                var text = document.createTextNode(html);
                var p = document.createElement('p');
                p.appendChild(text);
                return p.innerHTML
            },
            extractContent(html) {
                var span = document.createElement('span');
                span.innerHTML = html;
                return span.textContent || span.innerText;
            },
            keyMonitor(e) {
                e = e || window.event;
                if (e.keyCode == '37' || e.keyCode == '39' || e.keyCode == '8') {
                    var caret = this.getCaretCharacterOffsetWithin(this.$refs.newContent);
                    const mentions = [...this.$refs.newContent.innerHTML.matchAll('(<span class="mention".*?>.*?</span>)')];
                    var contentFunc = this.extractContent;
                    var setCaret = this.setCaret;
                    var plainText = contentFunc(this.$refs.newContent.innerHTML);
                    var contentDiv = this.$refs.newContent;
                    mentions.forEach(function (item) {
                        // need to get real index
                        var plainMention = contentFunc(item[0]);
                        var index = plainText.indexOf(plainMention);
                        if (e.keyCode == '37') {
                            // left arrow
                            if (caret > (index) && caret <= (index + plainMention.length)) {
                                setCaret(index + 1);
                            }
                        } else if (e.keyCode == '39') {
                            // right arrow
                            if (caret >= (index) && caret <= (index + plainMention.length)) {
                                setCaret(index + plainMention.length - 1);
                            }
                        } else if (e.keyCode == '8') {
                            // backspace
                            if (caret > (index) && caret <= (index + plainMention.length)) {
                                // Remove tag
                                contentDiv.innerHTML = contentDiv.innerHTML.replace(item[0], "");
                                setTimeout(function () {
                                    setCaret(contentDiv.textContent.length);
                                }, 0);
                            }
                        }
                    });
                }
            },
            getCaretCharacterOffsetWithin(element) {
                var caretOffset = 0;
                var doc = element.ownerDocument || element.document;
                var win = doc.defaultView || doc.parentWindow;
                var sel;
                if (typeof win.getSelection != "undefined") {
                    sel = win.getSelection();
                    if (sel.rangeCount > 0) {
                        var range = win.getSelection().getRangeAt(0);
                        var preCaretRange = range.cloneRange();
                        preCaretRange.selectNodeContents(element);
                        preCaretRange.setEnd(range.endContainer, range.endOffset);
                        caretOffset = preCaretRange.toString().length;
                    }
                } else if ((sel = doc.selection) && sel.type != "Control") {
                    var textRange = sel.createRange();
                    var preCaretTextRange = doc.body.createTextRange();
                    preCaretTextRange.moveToElementText(element);
                    preCaretTextRange.setEndPoint("EndToEnd", textRange);
                    caretOffset = preCaretTextRange.text.length;
                }
                return caretOffset;
            },
            setCaret(chars) {
                if (chars >= 0) {
                    var selection = window.getSelection();
                    var range = this.createRange(this.$refs.newContent.parentNode, { count: chars });
                    if (range) {
                        range.collapse(false);
                        selection.removeAllRanges();
                        selection.addRange(range);
                    }
                }
            },
            createRange: function (node, chars, range) {
                if (!range) {
                    range = document.createRange()
                    range.selectNode(node);
                    range.setStart(node, 0);
                }
                if (chars.count === 0) {
                    range.setEnd(node, chars.count);
                } else if (node && chars.count > 0) {
                    if (node.nodeType === Node.TEXT_NODE) {
                        if (node.textContent.length < chars.count) {
                            chars.count -= node.textContent.length;
                        } else {
                            range.setEnd(node, chars.count);
                            chars.count = 0;
                        }
                    } else {
                        for (var lp = 0; lp < node.childNodes.length; lp++) {
                            range = this.createRange(node.childNodes[lp], chars, range);
                            if (chars.count === 0) {
                                break;
                            }
                        }
                    }
                }
                return range;
            },
            resizeInput() {
                if ($('#note-input-container').length > 0) {
                    this.textInputWidth = ($('#note-input-container')[0]).offsetWidth;
                }
            }
        },
        computed: {
            ...mapState({
                notesSaving: (state) => state.notes.notesSaving,
                mentionUsers: (state) => state.notes.mentionUsers,
                user: (state) => state.auth.user,
            }),
        },
        watch: {
            mentionUsers() {
                this.$nextTick(() => {
                    this.resizeInput();
                })
            }
        }
    };
</script>
<style scoped>
</style>