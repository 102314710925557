export default {
    types: [
        {
            id: 0,
            icon: 'mdi-email-fast-outline',
            title: 'Email',
        },
        {
            id: 1,
            icon: 'mdi-cellphone-text',
            title: 'Sms'
        },
    ],
    swaps: [
        'Greeting',
        'UserName',
        'ContactName',
        'ContactPersonName',
        'UserSignature',
        'AlertText',
        'AlertHeader',
        'Link',
        'JobCustomerReference',
        'TodaysDate'
    ]
}