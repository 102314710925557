export function totalStaffTime(staffTimes, days) {
    // Get the current date
    const currentDate = new Date();

    // Calculate the past date threshold
    const pastDateThreshold = new Date();
    pastDateThreshold.setDate(currentDate.getDate() - days);

    // Filter the staffTimes array to get entries within the specified number of days
    const filteredStaffTimes = staffTimes.filter(staffTime => {
        const staffDate = new Date(staffTime.date);
        return staffDate >= pastDateThreshold && staffDate <= currentDate;
    });

    // Calculate the sum of durationHours for the filtered staff times
    const totalDuration = filteredStaffTimes.reduce((total, staffTime) => {
        return total + staffTime.durationHours;
    }, 0);

    return totalDuration;
}