import entities from '../../constants/entities';
import api from '../../helpers/api';
export const contactGroups = {
    state: {
        contactGroupsLoading: false,
        contactGroups: [],
    },
    mutations: {
        setContactGroupsLoading(state, loading) {
            state.contactGroupsLoading = loading;
        },
        setContactGroups(state, contactGroups) {
            state.contactGroups = contactGroups;
        }
    },
    actions: {
        loadContactGroups({ commit, dispatch }) {
            commit("setContactGroupsLoading", true);
            api.get(entities.ContactGroup.ROUTE+"?page=1&itemsPerPage=100").then(res => {
                commit("setContactGroupsLoading", false);
                commit("setContactGroups", res.data.items);
            })
            .catch(error => {
                commit("setContactGroupsLoading", false);
                dispatch("showErrorSnack", 'Cannot load contact groups');
            });
        },
    },
    getters: {
    }
}