export default {
    uoms: [
        {
            id: 'Periphery Area',
            icon: 'mdi-circle-multiple-outline',
            title: 'Periphery Area',
            subtitle: `Total SqM periphery is to be entered for the product everytime (Height x Width x Length in millimeters). Good for gates, fences or square panels and objects.`,
        },
        {
            id: 'Periphery Length',
            icon: 'mdi-ruler-square',
            title: 'Periphery Length',
            subtitle: `This is used for variable length fixed periphery products. Great for variable length extruded products.`,
        },
        {
            id: 'Length Only',
            icon: 'mdi-ruler',
            title: 'Length Only',
            subtitle: `This should be used for thin variable length products (less then 150mm). Great for thin flashings or bars.`,
        },
        {
            id: 'Unit Only',
            icon: 'mdi-size-xs',
            title: 'Unit Only',
            subtitle: `This should be used for small widgets (palm sized).`,
        }
    ],
    types: [
        {
            id: 1,
            icon: 'mdi-cube-unfolded',
            title: 'Definition',
            subtitle: `Used to define a basic class of product. No UOM's are needed. Examples - Pole, panel, nuts and bolts.`,
        },
        {
            id: 2,
            icon: 'mdi-cube-scan',
            title: 'Generic',
            subtitle: `Used to define a reoccuring product. UOM's are needed. Product can be used by any customer. Examples - 50x50 right angle, chain link fence, 50mm pole.`,
        },
        {
            id: 3,
            icon: 'mdi-cube-send',
            title: 'Customer',
            subtitle: `Used to define a customer specific product. UOM's are needed. Must be assigned to the customer to be used. Examples - Nu-Wall E200 Board, Moneky Toe Roof Hatch.`,
        }
    ]
}