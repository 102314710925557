<template>
    <div>
        <v-overlay :model-value="loading"
                   class="align-center justify-center">
            <v-progress-circular color="primary"
                                 indeterminate
                                 size="64"></v-progress-circular>
        </v-overlay>
        <v-sheet class="pa-4 text-center mx-auto ma-3"
                 flat
                 max-width="600"
                 rounded="lg"
                 width="100%">
            <v-icon class="mb-5"
                    color="teal"
                    icon="mdi-check-circle"
                    size="112"></v-icon>

            <h2 class="text-h5 mb-6">Job Complete</h2>
        </v-sheet>
    </div>
</template>
<style scoped>
</style>
<script>
    import { mapActions, mapState, mapMutations } from "vuex";
    function initialState() {
        return {
            saving: false,
            loading: false,
        };
    }
    export default {
        name: "JobCompletePanel",
        data: function () {
            return initialState();
        },
        mounted: function () {
            
        },
        props: {
        },
        methods: {
            ...mapMutations([
                "setEntityRecord"
            ]),
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
        },
        computed: {
            ...mapState({
                entityRecord: (state) => state.entities.entityRecord,
                entityRecordLoading: (state) => state.entities.entityRecordLoading
            }),
        },
        watch: {
        }
    };
</script>