<template>
    <v-form v-model="valid" @submit.prevent="submit" :disabled="loading">
        <v-text-field v-model="name"
                      :rules="[$rules.required]"
                      label="Name"></v-text-field>
        <v-text-field v-model="rate"
                      v-if="!editEntity"
                      :rules="[$rules.required]"
                      :hint="$rates.types[2].hint"
                      :prefix="$rates.types[2].prefix"
                      :suffix="$rates.types[2].suffix"
                      persistent-hint
                      type="number"
                      :label="$rates.types[2].title"></v-text-field>
        <div v-if="editEntity">
            <rates-display :type="$rates.RATE_MULTI" :class-id="editEntity.id" class-name="ContactGroup"></rates-display>
        </div>
        <v-alert density="compact"
                 v-if="errors"
                 type="warning"
                 class="my-2"
                 :title="errors.title">
            <ul>
                <li v-for="error in errors.errors" :key="error" v-html="error[0]"></li>
            </ul>
        </v-alert>
        <div class="mt-2 pt-2">
            <v-btn :loading="loading"
                   type="submit"
                   :disabled="!valid"
                   flat
                   color="primary"
                   prepend-icon="mdi-floppy">SAVE</v-btn>
            <v-btn flat class="ml-2" @click="showEntityForm = false">CANCEL</v-btn>
        </div>
    </v-form>
</template>
<style scoped>
</style>
<script>
    import { mapActions, mapState } from "vuex";
    function initialState() {
        return {
            saving: false,
            loading: false,
            name: "",
            rate: null,
            valid: false,
            errors: null,
        };
    }
    export default {
        name: "ContactGroupForm",
        data: function () {
            return initialState();
        },
        mounted: function () {
            if (this.editEntity) {
                this.name = this.editEntity.name;
            }
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showSuccessSnack",
                "showErrorSnack",
                "reloadEntities"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            submit() {
                this.loading = true
                let formData = new FormData();
                let config = {
                    method: 'post',
                    url: this.entity.CREATE,
                    data: new FormData()
                }
                if (this.editEntity != null) {
                    formData.append('id', this.editEntity.id);
                    config = {
                        method: 'put',
                        url: this.entity.UPDATE + this.editEntity.id,
                        data: new FormData()
                    }
                } else {
                    formData.append('defaultRate', this.rate);
                }
                formData.append('name', this.name);
                config.data = formData;
                this.$api(config)
                    .then(res => {
                        this.loading = false;
                        this.showEntityForm = false;
                        this.reloadEntities();
                        this.reset();
                        this.showSuccessSnack("Updated!");
                    }).catch(error => {
                        if (error.response.data && Array.isArray(error.response.data)) {
                            this.errors = error.response.data;
                        } else {
                            this.errors = { title: "Whoops", errors: [["We hit an error. Please contact support."]] };
                        }
                        this.loading = false;
                    });
            },
        },
        computed: {
            ...mapState({
                entity: (state) => state.entities.entity,
                editEntity: (state) => state.entities.editEntity,
            }),
            showEntityForm: {
                get() {
                    return this.$store.state.entities.showEntityForm
                },
                set(value) {
                    this.$store.commit('setShowEntityForm', value)
                }
            }
        }
    };
</script>